const searchOptionDebounceTime = 600;
//time in miliseconds
const toastAutocloseAfter = 5000;
//sets whether error should close after toastAutocloseAfter time
const autoCloseToastError = true;
const minCharsToLoadOptions = 1;
const userLogout = "USER_LOGOUT";

const asyncSelectCustomStyles = {
    control: (provided) => ({
        ...provided,
        minHeight: "30px",
        maxHeight: "35px",
        width: "100%",
    }),
    input: (provided) => ({
        ...provided,
        minHeight: "30px",
        maxHeight: "35px",
        marginTop: "-5px",
        color: "#0B1F36",
        font: "normal normal normal 14px/18px Red Hat Display",
    }),
    singleValue: (provided) => ({
        ...provided,
        color: "#0B1F36",
        font: "normal normal normal 14px/18px Red Hat Display",
    }),
    placeholder: (provided, state) => ({
        ...provided,
        font: "normal normal normal 14px/18px Red Hat Display",
        color: "black",
        opacity: 0.5,
    }),
};

const asyncSelectNewCustomStyles = {
    control: (provided, state) => ({
        ...provided,
        width: "100%",
        borderColor: state.selectProps.className.includes("async-select-error") ? "#d32f2f" : "hsl(0, 0%, 80%)",
        "&:hover": {
            borderColor: state.selectProps.className.includes("async-select-error") ? "" : "black",
        },
        "&:focus-within": {
            borderColor: state.selectProps.className.includes("async-select-error") ? "#d32f2f" : "#1976d2",
            boxShadow: state.selectProps.className.includes("async-select-error") ? "0 0 0 1px #d32f2f" : "0 0 0 1px #1976d2",
            opacity: 1,
        },
    }),
    input: (provided, state) => ({
        ...provided,
        color: state.isDisabled ? "rgba(0, 0, 0, 0.38)" : "#0B1F36",
        font: "normal normal normal 14px/18px Red Hat Display",
    }),

    singleValue: (provided, state) => ({
        ...provided,
        // color: "#0B1F36",
        color: state.isDisabled ? "rgba(0, 0, 0, 0.38)" : "#0B1F36",

        font: "normal normal normal 14px/18px Red Hat Display",
    }),
    placeholder: (provided, state) => ({
        ...provided,
        font: "normal normal normal 14px/18px Red Hat Display",
        color: "black",
        opacity: 0.5,
    }),
};

const maximumDocumentUploadSize = 1 * 1024 * 1024 * 1024;

const ddNewButtonStyles = {
    width: "100%",
    marginLeft: 0,
    marginBottom: 3,
    borderRadius: 4,
    height: 45,
    backgroundColor: "#e8ebef",
    color: "#7c8996",
    fontWeight: 600,
    fontFamily: "Red Hat Display",
    fontSize: 18,
    "&:hover": {
        backgroundColor: "white",
        color: "#7c8996",
    },
    textTransform: "none",
};

const digitalDocumentsModalStyles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
};

const breadCrumbLinkStyles = {
    textDecoration: "none",
    color: "#0b1f36",
    height: "26px",
    cursor: "pointer",
    font: "normal normal 600 20px/26px 'Red Hat Display'",
    "&:hover": {
        textDecoration: "underline",
    },
};

const trashNoticeBoxStyles = {
    p: 2,
    my: 2,
    height: 50,
    bgcolor: "#e8ebef",
    borderRadius: 2,
    display: "flex",
    justifyContent: "space-between",
    fontFamily: "Red Hat Display",
    alignItems: "center",
};

const listItemHoverStyle = {
    "&:hover": {
        backgroundColor: "lightgray",
    },
};

const buttonStyles = {
    fontFamily: "Red Hat Display",
    fontSize: "1rem",
    borderRadius: 2,
    textTransform: "none",
};

const breakpointForSidebar = 900;

const excelSheetMimeType = ["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];

const formAutoCompleteLabelBoxStyles = {
    height: 37.13,
    display: "flex",
    alignItems: "center",
    justifyContent: "right",
};

const maxOptionsSize = 10;

const initialFetchRowsParams = {
    currentPage: 0,
    rowsPerPage: 50,
    order: "",
    orderBy: "",
    searched: "",
};

const auditFileMaxSize = 100 * 1024 * 1024;
const auditFileMaxSizeText = "100 MB";
const allowedAuditFileTypes = ["application/pdf", "application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"];

export {
    searchOptionDebounceTime,
    minCharsToLoadOptions,
    toastAutocloseAfter,
    autoCloseToastError,
    asyncSelectCustomStyles,
    asyncSelectNewCustomStyles,
    userLogout,
    maximumDocumentUploadSize,
    ddNewButtonStyles,
    digitalDocumentsModalStyles,
    breadCrumbLinkStyles,
    trashNoticeBoxStyles,
    listItemHoverStyle,
    buttonStyles,
    breakpointForSidebar,
    excelSheetMimeType,
    formAutoCompleteLabelBoxStyles,
    maxOptionsSize,
    initialFetchRowsParams,
    auditFileMaxSize,
    auditFileMaxSizeText,
    allowedAuditFileTypes,
};

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    userOptionsLoaded: false,
    userOptions: null,
    accountOptionsLoaded: false,
    accountOptions: null,
    moduleOptionsLoaded: false,
    moduleOptions: null,
    assetNameOptions: null,
    assetNameOptionsLoaded: null,
    consumableNameOptions: null,
    consumableOptionsLoaded: null,
    locationOptionsLoaded: false,
    locationOptions: null,
    customerAccountOptionsLoaded: false,
    customerAccountOptions: null,
    vendorAccountOptionsLoaded: false,
    vendorAccountOptions: null,
    stationaryCategoryOptionsLoaded: false,
    stationaryCategoryOptions: null,
    assetCategoryOptionsLoaded: false,
    assetCategoryOptions: null,
    consumableCategoryOptionsLoaded: false,
    consumableCategoryOptions: null,
    groupOptionsLoaded: false,
    groupOptions: null,
    adminOptionsLoaded: false,
    adminOptions: null,
};

export const formSlice = createSlice({
    name: "optionsForAsyncSelect",
    initialState,
    reducers: {
        setUserOptionsLoaded: (state, action) => {
            state.userOptionsLoaded = action.payload;
        },

        setUserOptions: (state, action) => {
            state.userOptions = action.payload;
        },

        setAccountOptionsLoaded: (state, action) => {
            state.accountOptionsLoaded = action.payload;
        },

        setAccountOptions: (state, action) => {
            state.accountOptions = action.payload;
        },

        setModuleOptionsLoaded: (state, action) => {
            state.moduleOptionsLoaded = action.payload;
        },

        setModuleOptions: (state, action) => {
            state.moduleOptions = action.payload;
        },

        setAssetNameOptions: (state, action) => {
            state.assetNameOptions = action.payload;
        },

        setAssetNameOptionsLoaded: (state, action) => {
            state.assetNameOptionsLoaded = action.payload;
        },

        setConsumableNameOptions: (state, action) => {
            state.consumableNameOptions = action.payload;
        },

        setConsumableNameOptionsLoaded: (state, action) => {
            state.consumableOptionsLoaded = action.payload;
        },

        setLocationOptionsLoaded: (state, action) => {
            state.locationOptionsLoaded = action.payload;
        },

        setLocationOptions: (state, action) => {
            state.locationOptions = action.payload;
        },

        setCustomerAccountOptionsLoaded: (state, action) => {
            state.customerAccountOptionsLoaded = action.payload;
        },

        setCustomerAccountOptions: (state, action) => {
            state.customerAccountOptions = action.payload;
        },

        setVendorAccountOptionsLoaded: (state, action) => {
            state.vendorAccountOptionsLoaded = action.payload;
        },

        setVendorAccountOptions: (state, action) => {
            state.vendorAccountOptions = action.payload;
        },

        setStationaryCategoryOptionsLoaded: (state, action) => {
            state.stationaryCategoryOptionsLoaded = action.payload;
        },

        setStationaryCategoryOptions: (state, action) => {
            state.stationaryCategoryOptions = action.payload;
        },

        setAssetCategoryOptionsLoaded: (state, action) => {
            state.assetCategoryOptionsLoaded = action.payload;
        },

        setAssetCategoryOptions: (state, action) => {
            state.assetCategoryOptions = action.payload;
        },

        setConsumableCategoryOptionsLoaded: (state, action) => {
            state.consumableCategoryOptionsLoaded = action.payload;
        },

        setConsumableCategoryOptions: (state, action) => {
            state.consumableCategoryOptions = action.payload;
        },

        setGroupOptionsLoaded: (state, action) => {
            state.groupOptionsLoaded = action.payload;
        },

        setGroupOptions: (state, action) => {
            state.groupOptions = action.payload;
        },

        setAdminOptionsLoaded: (state, action) => {
            state.adminOptionsLoaded = action.payload;
        },

        setAdminOptions: (state, action) => {
            state.adminOptions = action.payload;
        },
    },
});

export const {
    setUserOptionsLoaded,
    setUserOptions,
    setAccountOptionsLoaded,
    setAccountOptions,
    setModuleOptionsLoaded,
    setModuleOptions,
    setAssetNameOptions,
    setAssetNameOptionsLoaded,
    setConsumableNameOptions,
    setConsumableNameOptionsLoaded,
    setLocationOptionsLoaded,
    setLocationOptions,
    setCustomerAccountOptionsLoaded,
    setCustomerAccountOptions,
    setVendorAccountOptionsLoaded,
    setVendorAccountOptions,
    setStationaryCategoryOptionsLoaded,
    setStationaryCategoryOptions,
    setAssetCategoryOptionsLoaded,
    setAssetCategoryOptions,
    setConsumableCategoryOptionsLoaded,
    setConsumableCategoryOptions,
    setGroupOptionsLoaded,
    setGroupOptions,
    setAdminOptionsLoaded,
    setAdminOptions,
} = formSlice.actions;

export default formSlice.reducer;

module.exports = Object.freeze({
    folderCreationSuccess: "Folder successfully created",
    folderCreationFailure: "Failed to create folder",
    folderSizeLimit: "Folder size must be less than 2GB",
    folderUploadInProgress: "Folder upload in progress",
    filesUploadInProgess: "Files upload in progress",
    failedToDownloadFolder: "Failed to download folder",
    failedToDownloadFile: "Failed to download file",
    failedToTrash: "Failed to trash",
    preparingDownload: "Preparing download",
    folderCreationInProgess: "Folder creation in progress",
    errorUploadingFile: "Error uploading file:",
    failedToGetStorageUsed: "Failed to fetch storage used",
    failedToRenameFolder: "Failed to rename folder",
    failedToRenameFile: "Failed to rename file",
    renameFolderSuccess: "Folder renamed successfully",
    renameFileSuccess: "File renamed successfully",
    renamingInProgress: "Renaming in progress",
    failedToFetchDocuments: "Failed to fetch documents",
    restoreSuccess: "restored successfully",
    failedToRestore: "Failed to restore",
    failedToFetchTrashedDocuments: "Failed to fetch trashed documents",
    documentDeleteSuccess: "Document deleted successfully",
    failedToDeleteDocument: "Failed to delete document",
    trashedDocumentsDeleteSuccess: "Trashed documents deleted successfully",
    failedToDeleteTrashedDocuments: "Failed to delete trashed documents",
    failedToFetchDocUsers: "Failed to fetch doc users",
    failedToFetchDocAccounts: "Failed to fetch doc accounts",
    failedToFetchDocUsersCards: "Failed to fetch doc users cards data",
    failedToFetchDocAccountsCards: "Failed to fetch doc accounts cards data",
    failedToFetchDashboardData: "Failed to fetch dashboard data",
    failedToUpload: "Failed to upload",
    failedToUploadAsSizeExceeds: "Failed to upload as the document size exceeds the allowed limit of",
    failedToFetchCirculars: "Failed to fetch circulars",
    failedToDownloadCircular: "Failed to download circular",
    failedToDeleteCircular: "Failed to delete circular",
    circularDeleteSuccess: "Circular deleted successfully",
    uploadCircularFileSuccess: "Circular file uploaded successfully",
    failedToUploadCircularFile: "Failed to upload circular file",
    selectPdfFile: "Invalid file type, please select a PDF file",
    circularFileSizeLimitExceeds: "File size exceeds the limit of",
    circularAddSuccess: "Circular added successfully",
    failedToAddCircular: "Circular addition failed",
    failedToFetchCircularData: "Failed to fetch circular data",
    failedToEditCircular: "Failed to edit circular",
    circularEditSuccess: "Circular edited successfully",
    failedToFetchNotifications: "Failed to fetch notifications",
    shareDocumentSuccess: "Document shared successfully",
    failedToShareDocument: "Failed to share document",
    documentAccessUpdateSuccess: "Document access updated successfully",
    failedToUpdateDocumentAccess: "Failed to update document access",
    failedToFetchUserOptions: "Failed to fetch user options",
    failedToImportUsers: "Failed to import users",
    importUsersSuccess: "Users imported successfully",
    failedToDownloadReport: "Failed to download report",
    failedToFetchModules: "Failed to fetch modules",
    failedToFetchCourseOptions: "Failed to fetch course options",
    failedToFetchModuleOptions: "Failed to fetch module options",
    failedToFetchCustomerAccountOptions: "Failed to fetch customer account options",
    noCourseFoundTypeAgain: "No course found. Type again...",
    noModuleFoundTypeAgain: "No module found. Type again...",
    noOrganizationFoundTypeAgain: "No organization found. Type again...",
    failedToFetchTasks: "Failed to fetch tasks",
    taskDeleteSuccess: "Task deleted successfully",
    failedToDeleteTask: "Failed to delete task",
    failedToFetchTargets: "Failed to fetch targets",
    failedToFetchUsers: "Failed to fetch users",
    failedToFetchAccountOptions: "Failed to fetch account options",
    failedToFetchUserDetails: "Failed to fetch user details",
    userEditSuccess: "User details edited successfully",
    failedToEditUserDetails: "Failed to edit user details",
    userAddedSuccess: "User added successfully",
    failedToAddUser: "Failed to add user",
    userDeleteSuccess: "User deleted successfully",
    failedToDeleteUser: "Failed to delete user",
    failedToLogIn: "Failed to login",
    loginSuccess: "Login Successful",
    failedToFetchLocationOptions: "Failed to fetch location options",
    selectExcelFile: "Invalid file type, please select a excel file",
    failedToImportLocations: "Failed to import locations",
    importLocationsSuccess: "Locations imported successfully",
    locationAddSuccess: "Location added successfully",
    failedToAddLocation: "Failed to add location",
    locationEditSuccess: "Location edited successfully",
    failedToEditLocation: "Failed to edit location",
    failedToFetchLocations: "Failed to fetch locations",
    failedToFetchLocationDetails: "Failed to fetch location details",
    failedToFetchVendorAccounts: "Failed to fetch vendor accounts",
    noDataFoundToExport: "No data found to export",
    failedToFetchGroups: "Failed to fetch groups",
    failedToFetchGroupData: "Failed to fetch group details",
    groupDetailsEditSuccess: "Group details edited successfully",
    failedToEditGroupDetails: "Failed to edit group details",
    createGroupSuccess: "Group created successfully",
    failedToCreateGroup: "Failed to create group",
    groupDeleteSuccess: "Group deleted successfully",
    failedToDeleteGroup: "Failed to delete group",
    groupUsersUpdateSuccess: "Group users updated successfully",
    failedToUpdateGroupUsers: "Failed to update group users",
    failedToFetchGroupUsers: "Failed to fetch group users",
    failedToFetchInventories: "Failed to fetch inventories",
    failedToFetchInventory: "Failed to fetch inventory data",
    inventoryEditSuccess: "Inventory data edited successfully",
    failedToEditInventory: "Failed to edit inventory data",
    addInventorySuccess: "Inventory added successfully",
    failedToAddInventory: "Failed to add inventory",
    failedToFetchStationaries: "Failed to fetch stationaries",
    addStationarySuccess: "Stationary added successfully",
    failedToAddStationary: "Failed to add stationary",
    failedToFetchCategories: "Failed to fetch categories",
    failedToFetchGroupCodes: "Failed to fetch group codes",
    failedToFetchItemCodes: "Failed to fetch item codes",
    failedToFetchItemNames: "Failed to fetch item names",
    failedToFetchStationary: "Failed to fetch stationary data",
    stationaryEditSuccess: "Stationary data edited successfully",
    failedToEditStationary: "Failed to edit stationary data",
    addAssetSuccess: "Asset added successfully",
    failedToAddAsset: "Failed to add asset",
    failedToFetchAssets: "Failed to fetch assets data",
    assetEditSuccess: "Asset data edited successfully",
    failedToEditAsset: "Failed to edit asset data",
    addConsumableSuccess: "Consumable added successfully",
    failedToAddConsumable: "Failed to add consumable",
    failedToFetchConsumables: "Failed to fetch consumables data",
    consumableEditSuccess: "Consumable data edited successfully",
    failedToEditConsumable: "Failed to edit consumable data",
    failedToFetchGroupOptions: "Failed to fetch group options",
    inactiveSuccess: "User made inactive successfully",
    failedToMakeInactive: "Failed to make user inactive",
    fetchStationaryAssignmentsFailure: "Failed to fetch stationary assignments",
    assignStationarySuccess: "Assigned stationary successfully",
    assignStationaryFailure: "Failed to assign stationary",
    editAssignedStationaryFailure: "Failed to edit assigned stationary",
    editAssignedStationarySuccess: "Assigned stationary edited successfully",
    deleteAssignedStationarySuccess: "Assigned stationary deleted successfully",
    deleteAssignedStationaryFailure: "Failed to delete assigned stationary",
    fetchConsumableAssignmentsFailure: "Failed to fetch consumable assignments",
    assignConsumableSuccess: "Assigned consumable successfully",
    assignConsumableFailure: "Failed to assign consumable",
    editAssignedConsumableFailure: "Failed to edit assigned consumable",
    editAssignedConsumableSuccess: "Assigned consumable edited successfully",
    deleteAssignedConsumableSuccess: "Assigned consumable deleted successfully",
    deleteAssignedConsumableFailure: "Failed to delete assigned consumable",
    fetchConsumableMakesFailure: "Failed to fetch consumable makes",
    fetchConsumableModelsFailure: "Failed to fetch consumable models",
    fetchStationaryAssignmentDataFailure: "Failed to fetch stationary assignment data",
    fetchConsumableAssignmentDataFailure: "Failed to fetch consumable assignment data",
    fetchAssetAssignmentsFailure: "Failed to fetch asset assignments",
    assignAssetSuccess: "Assigned asset successfully",
    assignAssetFailure: "Failed to assign asset",
    editAssignedAssetFailure: "Failed to edit assigned asset",
    editAssignedAssetSuccess: "Assigned asset edited successfully",
    deleteAssignedAssetSuccess: "Assigned asset deleted successfully",
    deleteAssignedAssetFailure: "Failed to delete assigned asset",
    fetchAssetMakesFailure: "Failed to fetch asset makes",
    fetchAssetModelsFailure: "Failed to fetch asset models",
    fetchAssetAssignmentDataFailure: "Failed to fetch asset assignment data",
    fetchReportDetailsFailure: "Failed to fetch report details",
    fetchAssetsFailure: "Failed to fetch assets",
    fetchConsumablesFailure: "Failed to fetch consumables",
    getReportsFailure: "Failed to get reports",
    pageRefreshFailure: "Failed to refresh page",
    userNotLoggedIn: "User is not logged in",
    accessProhibited: "Access prohibited",
    profileEditSuccess: "Profile edited successfully",
    profileEditFailure: "Failed to edit profile",
    fetchCardsCountFailure: "Failed to fetch card counts",
    fetchLowQuantityAssetsFailure: "Failed to fetch low quantity assets",
    fetchAssetsOnMaitenanceFailure: "Failed to fetch assets on maintenance",
    fetchLowQuantityConsumablesFailure: "Failed to fetch low quantity consumables",
    fetchLowQuantityStationaryFailure: "Failed to fetch low quantity stationary",
    fetchDashboardDataFailure: "Failed to fetch dashboard data",
    fetchRequisitionsFailure: "Failed to fetch requisitions",
    fetchRequestsailure: "Failed to fetch requests",
    newRequestSuccess: "Request raised successfully",
    newRequestFailure: "Failed to raise request",
    editRequestSuccess: "Request edited successfully",
    editRequestFailure: "Failed to edit request",
    fetchRequestDataFailure: "Failed to fetch request details",
    deleteRequestSuccess: "Request deleted successfully",
    deleteRequestFailure: "Failed to delete request",
});

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    myDocsFolderPath: "",
    recentlyUploaded: false,
    recentlyCreatedFolder: false,
    recentlyRenamedDocument: false,
    recentlyTrashedDocument: false,
    storageUsed: null,
    storagePercentage: 0
};

export const formSlice = createSlice({
    name: "myDocs",
    initialState,
    reducers: {
        setMyDocsFolderPath: (state, action) => {
            state.myDocsFolderPath = action.payload;
        },

        setRecentlyUploaded: (state, action) => {
            state.recentlyUploaded = action.payload;
        },
        setRecentlyCreatedFolder: (state, action) => {
            state.recentlyCreatedFolder = action.payload;
        },
        setRecentlyRenamedDocument: (state, action) => {
            state.recentlyRenamedDocument = action.payload;
        },
        setRecentlyTrashedDocument: (state, action) => {
            state.recentlyTrashedDocument = action.payload;
        },
        setStorageUsed: (state, action) => {
            state.storageUsed = action.payload;
        },
        setStoragePercentage: (state, action) => {
            state.storagePercentage = action.payload;
        },
    },
});

export const { setMyDocsFolderPath, setRecentlyUploaded, setRecentlyCreatedFolder, setRecentlyRenamedDocument, setRecentlyTrashedDocument, setStorageUsed, setStoragePercentage } = formSlice.actions;

export default formSlice.reducer;

import { configureStore, combineReducers } from "@reduxjs/toolkit";
import dealFormReducer from "../features/BD/dealFormSlice";
import accountReducer from "../features/BD/accountSlice";
import contactFormReducer from "../features/BD/contactForm";
import taskFormReducer from "../features/BD/taskFormSlice";
import optionsForAsyncSelectReducer from "../features/optionSlice";
import moduleFormReducer from "../features/EL/moduleFormSlice";
import courseFormReducer from "../features/EL/courseFormSlice";
import courseDetailsFormReducer from "../features/EL/courseDetailsForm";
import assetFormReducer from "../features/AM/assetForm";
import courseDetailsReducer from "../features/EL/courseDetailsSlice";
import courseQuestionsFormReducer from "../features/EL/courseQuestionsFormSlice";
import targetFormReducer from "../features/BD/targetFormSlice";
import myCoursesReducer from "../features/EL/myCoursesSlice";
import ticketReducer from "../features/TS/ticketSlice";
import ticketDetailsReducer from "../features/TS/ticketDetailsSlice";
import consumableFormReducer from "../features/AM/consumableForm";
import passwordChangeFormReducer from "../features/passwordChangeFormSlice";
import myDocsReducer from "../features/DD/myDocsSlice";
import trashReducer from "../features/DD/trashSlice";
import sharedWithMeReducer from "../features/DD/sharedWithMeSlice";
import { userLogout } from "../constants/Common";
import circularReducer from "../features/DD/circularSlice";
import notificationReducer from "../features/notification";
import userFormReducer from "../features/Settings/userFormSlice";
import importUsersFormReducer from "../features/Settings/importUsersFormSlice";
import importLocationsFormReducer from "../features/Settings/importLocationsFormSlice";
import locationFormReducer from "../features/Settings/locationFormSlice";
import groupFormReducer from "../features/Settings/groupFormSlice";
import inventoryFormReducer from "../features/AM/inventoryFormSlice";
import stationaryFormReducer from "../features/AM/stationaryFormSlice";
import assignStationaryFormReducer from "../features/AM/assignStationaryFormSlice";
import assignConsumableFormReducer from "../features/AM/assignConsumableFormSlice";
import assignAssetFormReducer from "../features/AM/assignAssetFormSlice";
import userDetailsReducer from "../features/userSlice";
import requisitionFormReducer from "../features/AM/requisitionFormSlice";
import masterFormReducer from "../features/CM/masterFormSlice";
import auditFormReducer from "../features/CM/auditFormSlice";
import auditAreaFormReducer from "../features/CM/auditAreaFormSlice";
import complyAreaFormReducer from "../features/CM/complyAreaFormSlice";

const rootReducer = combineReducers({
    account: accountReducer,
    dealForm: dealFormReducer,
    contactForm: contactFormReducer,
    taskForm: taskFormReducer,
    optionsForAsyncSelect: optionsForAsyncSelectReducer,
    moduleForm: moduleFormReducer,
    courseForm: courseFormReducer,
    courseDetailsForm: courseDetailsFormReducer,
    assetForm: assetFormReducer,
    courseDetails: courseDetailsReducer,
    courseQuestionsForm: courseQuestionsFormReducer,
    targetForm: targetFormReducer,
    myCourses: myCoursesReducer,
    ticket: ticketReducer,
    ticketDetails: ticketDetailsReducer,
    consumableForm: consumableFormReducer,
    locationForm: locationFormReducer,
    passwordChangeForm: passwordChangeFormReducer,
    myDocs: myDocsReducer,
    trash: trashReducer,
    sharedWithMe: sharedWithMeReducer,
    circular: circularReducer,
    notification: notificationReducer,
    userForm: userFormReducer,
    importUsersForm: importUsersFormReducer,
    importLocationsForm: importLocationsFormReducer,
    groupForm: groupFormReducer,
    inventoryForm: inventoryFormReducer,
    stationaryForm: stationaryFormReducer,
    assignStationaryForm: assignStationaryFormReducer,
    assignConsumableForm: assignConsumableFormReducer,
    assignAssetForm: assignAssetFormReducer,
    userDetails: userDetailsReducer,
    requisitionForm: requisitionFormReducer,
    masterForm: masterFormReducer,
    auditForm: auditFormReducer,
    auditAreaForm: auditAreaFormReducer,
    complyAreaForm: complyAreaFormReducer,
});

const rootReducerWithLogout = (state, action) => {
    // If the action type is USER_LOGOUT, reset the states
    if (action.type === userLogout) {
        state = undefined;
    }

    return rootReducer(state, action);
};

export const store = configureStore({
    reducer: rootReducerWithLogout,
});

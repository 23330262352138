import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./app/store";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { BrowserRouter } from "react-router-dom";

const theme = createTheme({
    typography: {
        fontFamily: "Red Hat Display, Arial, sans-serif", // Font family for typography
    },
    palette: {
        primary: {
            main: "#1a50ad",
        },
    },
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    "&.Mui-disabled": {
                        "& .MuiInputBase-input": {
                            color: "black", // Disabled text color
                            zIndex: 1,
                        },
                        "& fieldset": {
                            borderColor: "lightgray", // Disabled border color
                            backgroundColor: "#f0f0f0", // Disabled field background color
                        },
                    },
                },
            },
        },

        MuiButton: {
            styleOverrides: {
                outlined: {
                    borderColor: "rgba(0, 0, 0, 0.23)", // Default TextField border color
                    color: "rgba(0, 0, 0, 0.87)", // Default TextField text color
                    height: 40, // Set default height
                    textTransform: "none", // Remove uppercase transformation
                    "&:hover": {
                        borderColor: "rgba(0, 0, 0, 0.87)", // Border color on hover
                        backgroundColor: "#1a50ad",
                        color: "#fff",
                    },
                },
            },
        },

        MuiMenu: {
            styleOverrides: {
                paper: {
                    "&.action-menu": {
                        elevation: 0,
                        width: 320,
                        maxWidth: "100%",
                        overflow: "visible",
                        border: "none",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        marginTop: 8,
                        "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            marginLeft: -0.5,
                            marginRight: 1,
                        },
                        "&::before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            backgroundColor: "white",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                        },
                    },
                },
            },
        },
    },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <ThemeProvider theme={theme}>
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
    </ThemeProvider>
);

import React, { useEffect, useState } from "react";
import Drawer from "@mui/material/Drawer";
import { Autocomplete, Box, CircularProgress, FormControl, InputLabel, TextField, debounce } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { createInstance, errorHandler } from "../../utils/Request/ReqUtils";
import { toast } from "react-toastify";
import ToastMessages from "../../constants/ToastMessages";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { stationarySchema } from "../../validations/AmForms/Stationary";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { createSearchParams } from "react-router-dom";
import {
    setStationaryCategoryOptions,
    setStationaryCategoryOptionsLoaded,
    setVendorAccountOptions,
    setVendorAccountOptionsLoaded,
} from "../../features/optionSlice";
import { formAutoCompleteLabelBoxStyles, searchOptionDebounceTime } from "../../constants/Common";
import {
    fetchCustomerAndVendorAccountOptions,
    fetchInventoryCategories,
    fetchStationaryGroupCodes,
    fetchStationaryItemCodes,
    fetchItemNames,
} from "../../utils/Common";
import { stationaryApi } from "../../constants/apis/AM/Stationary";
import { setStationaryAdded, setStationaryEdited, setStationaryId } from "../../features/AM/stationaryFormSlice";
import { inventoryTypes } from "../../enums/AM/Inventory";

const StationaryForm = ({ open, onClose }) => {
    const dispatch = useDispatch();
    const axiosInstance = createInstance(true);
    const stationaryId = useSelector((state) => state.stationaryForm.stationaryId);

    const initialStationaryData = {
        category: null,
        item_code: null,
        group_code: null,
        name: null,
        challan_number: "",
        date_of_purchase: null,
        purchased_quantity: "",
        vendor: null,
        description: "",
    };

    // used for not calling the apis of autocomplete option when initialising the data in form while edit
    const initialInitialize = {
        initialize_category: true,
        initialize_group_code: true,
        initialize_item_code: true,
        initialize_name: true,
        initialize_vendor: true,
    };

    const initialAutocompleteFieldsStatus = {
        category: false,
        group_code: false,
        item_code: false,
        name: false,
        vendor: false,
        ...(stationaryId && { ...initialInitialize }),
    };

    const fieldNameMappings = {
        category: "category",
        group_code: "group_code",
        item_code: "item_code",
        name: "name",
        vendor: "vendor",
    };

    const [stationaryData, setStationaryData] = useState(initialStationaryData);
    const [stationaryFormErrors, setStationaryFormErrors] = useState({});
    const [fetchingStationaryData, setFetchingStationaryData] = useState(false);
    const [autocompleteloading, setAutocompleteLoading] = useState(false);
    const [autocompleteFieldsStatus, setAutocompleteFieldsStatus] = useState(initialAutocompleteFieldsStatus);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [groupCodeOptions, setGroupCodeOptions] = useState([]);
    const [itemCodeOptions, setItemCodeOptions] = useState([]);
    const [itemNameOptions, setItemNameOptions] = useState([]);
    const [vendorOptions, setVendorOptions] = useState([]);

    const stationaryCategoryOptions = useSelector((state) => state.optionsForAsyncSelect.stationaryCategoryOptions);
    const stationaryCategoryOptionsLoaded = useSelector((state) => state.optionsForAsyncSelect.stationaryCategoryOptionsLoaded);
    const vendorAccountOptions = useSelector((state) => state.optionsForAsyncSelect.vendorAccountOptions);
    const vendorAccountOptionsLoaded = useSelector((state) => state.optionsForAsyncSelect.vendorAccountOptionsLoaded);

    const fetchStationaryData = async (id) => {
        setFetchingStationaryData(true);
        try {
            const result = await axiosInstance.get(stationaryApi + "/" + id);
            if (result.status === 200) {
                const fetchedStationaryData = result?.data?.data[0];
                for (let key in initialStationaryData) {
                    fetchedStationaryData[key] = fetchedStationaryData[key] || "";
                    if (key === "date_of_purchase") {
                        if (fetchedStationaryData[key]) {
                            const formattedDate = dayjs(fetchedStationaryData[key]);
                            fetchedStationaryData[key] = formattedDate;
                        } else {
                            fetchedStationaryData[key] = null;
                        }
                    } else if (["category", "group_code", "item_code"].includes(key)) {
                        fetchedStationaryData[key] = { label: fetchedStationaryData[key], value: fetchedStationaryData[key] };
                    } else if (key === "name") {
                        fetchedStationaryData[key] = {
                            label: fetchedStationaryData[key],
                            value: fetchedStationaryData[key],
                            inventory_id: fetchedStationaryData.inventory_id,
                        };
                    } else if (key === "vendor") {
                        fetchedStationaryData[key] = {
                            value: fetchedStationaryData?.vendor,
                            label: fetchedStationaryData?.vendor_name,
                            additionalData: {},
                        };
                    }
                }
                setStationaryData(fetchedStationaryData);
                setAutocompleteFieldsStatus(initialAutocompleteFieldsStatus);
            } else {
                toast.error(ToastMessages.failedToFetchStationary);
            }
        } catch (err) {
            errorHandler(err, ToastMessages.failedToFetchStationary);
        }
        setFetchingStationaryData(false);
    };

    const formatDataToCreateValidationPayload = () => {
        const stationaryValidationPayload = JSON.parse(JSON.stringify(stationaryData));
        const fieldsToProcess = ["category", "group_code", "item_code", "name", "vendor"];

        fieldsToProcess.forEach((field) => {
            stationaryValidationPayload[field] = stationaryValidationPayload?.[field]?.value || null;
        });
        stationaryValidationPayload.date_of_purchase = stationaryData?.date_of_purchase?.format("YYYY-MM-DD") || null;
        delete stationaryValidationPayload?.vendor_name;
        delete stationaryValidationPayload?.inventory_id;
        return stationaryValidationPayload;
    };

    const formatDataToCreatePayload = (data) => {
        data.inventory_id = stationaryData?.name?.inventory_id;
        const fieldsToDelete = ["category", "group_code", "item_code", "name"];

        fieldsToDelete.forEach((field) => delete data?.[field]);
        return data;
    };

    const handleStationaryEdit = async (id) => {
        const stationaryValidationPayload = formatDataToCreateValidationPayload();
        const validation = stationarySchema.validate(stationaryValidationPayload, { abortEarly: false });
        if (validation.error) {
            setStationaryFormErrors({});
            const errorDetails = validation.error.details;
            let currentErrors = {};
            errorDetails.forEach((error) => {
                currentErrors[error.path[0]] = error.message;
            });
            setStationaryFormErrors(currentErrors);
        } else {
            try {
                const stationaryPayload = formatDataToCreatePayload(stationaryValidationPayload);
                const result = await axiosInstance.put(stationaryApi + "/" + id, stationaryPayload);
                if (result.status === 200) {
                    toast.success(ToastMessages.stationaryEditSuccess);
                    handleStationaryFormClose();
                    dispatch(setStationaryEdited(true));
                } else {
                    toast.error(ToastMessages.failedToEditStationary);
                }
            } catch (err) {
                errorHandler(err, ToastMessages.failedToEditStationary);
            }
        }
    };

    const handleStationarySubmit = async () => {
        const stationaryValidationPayload = formatDataToCreateValidationPayload();
        const validation = stationarySchema.validate(stationaryValidationPayload, { abortEarly: false });
        if (validation.error) {
            setStationaryFormErrors({});
            const errorDetails = validation.error.details;
            let currentErrors = {};
            errorDetails.forEach((error) => {
                currentErrors[error.path[0]] = error.message;
            });
            setStationaryFormErrors(currentErrors);
        } else {
            try {
                const stationaryPayload = formatDataToCreatePayload(stationaryValidationPayload);
                const result = await axiosInstance.post(stationaryApi, stationaryPayload);
                if (result.status === 200) {
                    toast.success(ToastMessages.addStationarySuccess);
                    handleStationaryFormClose();
                    dispatch(setStationaryAdded(true));
                } else {
                    toast.error(ToastMessages.failedToAddStationary);
                }
            } catch (err) {
                errorHandler(err, ToastMessages.failedToAddStationary);
            }
        }
    };

    const handleStationaryForm = (e) => {
        setStationaryFormErrors({ ...stationaryFormErrors, [e.target.name]: "" });
        setStationaryData({ ...stationaryData, [e.target.name]: e.target.value });
    };

    const handleDates = (date, fieldName) => {
        setStationaryFormErrors({ ...stationaryFormErrors, [fieldName]: "" });
        setStationaryData({ ...stationaryData, [fieldName]: date });
    };

    const handleStationaryFormClose = () => {
        onClose();
        setStationaryFormErrors({});
        setStationaryData(initialStationaryData);
        dispatch(setStationaryId(null));
    };

    // functions to fetch data

    // debounced functions to fetch data
    const debouncedFetchStationaryCategories = debounce(async (newInputValue) => {
        setAutocompleteLoading(true);

        try {
            const params = createSearchParams({
                search: newInputValue || "",
                source: inventoryTypes.Stationary.toLowerCase(),
            });
            const fetchedOptions = await fetchInventoryCategories(params);
            if (!stationaryCategoryOptionsLoaded && fetchedOptions) {
                dispatch(setStationaryCategoryOptionsLoaded(true));
                dispatch(setStationaryCategoryOptions(fetchedOptions));
            }

            // fetched option might not contain the selected options so adding that to avoid warnings of option value not exists
            const mergedOptions = [
                ...(fetchedOptions || []),
                ...categoryOptions.filter((selectedOption) => !fetchedOptions.some((option) => option?.value === selectedOption?.value)),
            ];

            setCategoryOptions(mergedOptions);
        } catch (error) {}
        setAutocompleteLoading(false);
    }, searchOptionDebounceTime);

    const debouncedFetchStationaryGroupCodes = debounce(async (newInputValue) => {
        setAutocompleteLoading(true);

        try {
            const params = createSearchParams({
                category: stationaryData?.category?.value,
                search: newInputValue || "",
                source: inventoryTypes.Stationary.toLowerCase(),
            });
            const fetchedOptions = await fetchStationaryGroupCodes(params);

            // fetched option might not contain the selected options so adding that to avoid warnings of option value not exists
            const mergedOptions = [
                ...(fetchedOptions || []),
                ...groupCodeOptions.filter((selectedOption) => !fetchedOptions.some((option) => option?.value === selectedOption?.value)),
            ];

            setGroupCodeOptions(mergedOptions);
        } catch (error) {}
        setAutocompleteLoading(false);
    }, searchOptionDebounceTime);

    const debouncedFetchStationaryItemCodes = debounce(async (newInputValue) => {
        setAutocompleteLoading(true);

        try {
            const params = createSearchParams({
                category: stationaryData?.category?.value,
                group_code: stationaryData?.group_code?.value,
                search: newInputValue || "",
                source: inventoryTypes.Stationary.toLowerCase(),
            });
            const fetchedOptions = await fetchStationaryItemCodes(params);

            // fetched option might not contain the selected options so adding that to avoid warnings of option value not exists
            const mergedOptions = [
                ...(fetchedOptions || []),
                ...itemCodeOptions.filter((selectedOption) => !fetchedOptions.some((option) => option?.value === selectedOption?.value)),
            ];

            setItemCodeOptions(mergedOptions);
        } catch (error) {}
        setAutocompleteLoading(false);
    }, searchOptionDebounceTime);

    const debouncedFetchItemNames = debounce(async (newInputValue) => {
        setAutocompleteLoading(true);

        try {
            const params = createSearchParams({
                category: stationaryData?.category?.value,
                group_code: stationaryData?.group_code?.value,
                item_code: stationaryData?.item_code?.value,
                search: newInputValue || "",
                source: inventoryTypes.Stationary.toLowerCase(),
            });
            const fetchedOptions = await fetchItemNames(params);

            // fetched option might not contain the selected options so adding that to avoid warnings of option value not exists
            const mergedOptions = [
                ...(fetchedOptions || []),
                ...itemNameOptions.filter((selectedOption) => !fetchedOptions.some((option) => option?.value === selectedOption?.value)),
            ];

            setItemNameOptions(mergedOptions);
        } catch (error) {}
        setAutocompleteLoading(false);
    }, searchOptionDebounceTime);

    const debouncedFetchVendors = debounce(async (newInputValue) => {
        setAutocompleteLoading(true);

        try {
            const params = createSearchParams({
                search: newInputValue || "",
                vendorsRequired: true,
                customersRequired: false,
                selfRequired: false,
            });
            const fetchedOptions = await fetchCustomerAndVendorAccountOptions(params);
            if (!vendorAccountOptionsLoaded && fetchedOptions) {
                dispatch(setVendorAccountOptionsLoaded(true));
                dispatch(setVendorAccountOptions(fetchedOptions));
            }

            // fetched option might not contain the selected options so adding that to avoid warnings of option value not exists
            const mergedOptions = [
                ...(fetchedOptions || []),
                ...vendorOptions.filter((selectedOption) => !fetchedOptions.some((option) => option?.value === selectedOption?.value)),
            ];

            setVendorOptions(mergedOptions);
        } catch (error) {}
        setAutocompleteLoading(false);
    }, searchOptionDebounceTime);

    // functions for autocomplete field open to fetch data
    const handleCategoryFieldOpen = async () => {
        setAutocompleteFieldsStatus({ ...autocompleteFieldsStatus, category: true, initialize_category: false });
        if (!stationaryCategoryOptionsLoaded) {
            debouncedFetchStationaryCategories("");
        } else {
            // options might not contain the selected options so adding that to avoid warnings of option value not exists
            const mergedOptions = [
                ...(stationaryCategoryOptions || []),
                ...categoryOptions.filter((selectedOption) => !stationaryCategoryOptions.some((option) => option?.value === selectedOption?.value)),
            ];
            setCategoryOptions(mergedOptions);
        }
    };

    const handleGroupCodeFieldOpen = async () => {
        setAutocompleteFieldsStatus({ ...autocompleteFieldsStatus, group_code: true, initialize_group_code: false });
        if (stationaryData.category && !groupCodeOptions.length) {
            debouncedFetchStationaryGroupCodes("");
        }
    };

    const handleItemCodeFieldOpen = async () => {
        setAutocompleteFieldsStatus({ ...autocompleteFieldsStatus, item_code: true, initialize_item_code: false });
        if (stationaryData.category && stationaryData.group_code && !itemCodeOptions.length) {
            debouncedFetchStationaryItemCodes("");
        }
    };

    const handleItemNameFieldOpen = async () => {
        setAutocompleteFieldsStatus({ ...autocompleteFieldsStatus, name: true, initialize_name: false });
        if (stationaryData.category && stationaryData.group_code && stationaryData.item_code && !itemNameOptions.length) {
            debouncedFetchItemNames("");
        }
    };

    const handleVendorFieldOpen = async () => {
        setAutocompleteFieldsStatus({ ...autocompleteFieldsStatus, vendor: true, initialize_vendor: false });
        if (!vendorAccountOptionsLoaded) {
            debouncedFetchVendors("");
        } else {
            // options might not contain the selected options so adding that to avoid warnings of option value not exists

            const mergedOptions = [
                ...(vendorAccountOptions || []),
                ...vendorOptions.filter((selectedOption) => !vendorAccountOptions.some((option) => option?.value === selectedOption?.value)),
            ];
            setVendorOptions(mergedOptions);
        }
    };

    // functions to handle the search in autocomplete
    const handleCategoryInputChange = async (event, value) => {
        const inputExistsInOptions = categoryOptions.some((option) => option?.label.toLowerCase() === value.toLowerCase());
        if (!inputExistsInOptions && value && !autocompleteFieldsStatus.initialize_category) {
            debouncedFetchStationaryCategories(value);
        }
    };

    const handleGroupCodeInputChange = async (event, value) => {
        const inputExistsInOptions = groupCodeOptions.some((option) => option?.label.toLowerCase() === value.toLowerCase());
        if (!inputExistsInOptions && stationaryData.category && value && !autocompleteFieldsStatus.initialize_group_code) {
            debouncedFetchStationaryGroupCodes(value);
        }
    };

    const handleItemCodeInputChange = async (event, value) => {
        const inputExistsInOptions = itemCodeOptions.some((option) => option?.label.toLowerCase() === value.toLowerCase());
        if (
            !inputExistsInOptions &&
            stationaryData.category &&
            stationaryData.group_code &&
            value &&
            !autocompleteFieldsStatus.initialize_item_code
        ) {
            debouncedFetchStationaryItemCodes(value);
        }
    };

    const handleItemNameInputChange = async (event, value) => {
        const inputExistsInOptions = itemNameOptions.some((option) => option?.label.toLowerCase() === value.toLowerCase());
        if (
            !inputExistsInOptions &&
            stationaryData.category &&
            stationaryData.group_code &&
            stationaryData.item_code &&
            value &&
            !autocompleteFieldsStatus.initialize_name
        ) {
            debouncedFetchItemNames(value);
        }
    };

    const handleVendorInputChange = async (event, value) => {
        const inputExistsInOptions = vendorOptions.some((option) => option?.label.toLowerCase() === value.toLowerCase());
        if (!inputExistsInOptions && value && !autocompleteFieldsStatus.initialize_vendor) {
            debouncedFetchVendors(value);
        }
    };

    // on autocomplete option change
    const handleSelectedOptionsChange = (e, option, fieldName) => {
        if (option) {
            if (fieldName === fieldNameMappings?.category) {
                setStationaryData({ ...stationaryData, category: option || null, group_code: null, item_code: null, name: null });
                setGroupCodeOptions([]);
                setItemCodeOptions([]);
                setItemNameOptions([]);
            } else if (fieldName === fieldNameMappings?.group_code) {
                setStationaryData({ ...stationaryData, group_code: option || null, item_code: null, name: null });
                setItemCodeOptions([]);
                setItemNameOptions([]);
            } else if (fieldName === fieldNameMappings?.item_code) {
                setStationaryData({ ...stationaryData, item_code: option || null, name: null });
                setItemNameOptions([]);
            } else setStationaryData({ ...stationaryData, [fieldName]: option || null });
            setStationaryFormErrors({ ...stationaryFormErrors, [fieldName]: "" });
        } else {
            if (fieldName === fieldNameMappings?.category) {
                setStationaryData({ ...stationaryData, category: null, group_code: null, item_code: null, name: null });
                setGroupCodeOptions([]);
                setItemCodeOptions([]);
                setItemNameOptions([]);
            } else if (fieldName === fieldNameMappings?.group_code) {
                setStationaryData({ ...stationaryData, group_code: null, item_code: null, name: null });
                setItemCodeOptions([]);
                setItemNameOptions([]);
            } else if (fieldName === fieldNameMappings?.item_code) {
                setStationaryData({ ...stationaryData, item_code: null, name: null });
                setItemNameOptions([]);
            } else if (fieldName === fieldNameMappings?.name) {
                setStationaryData({ ...stationaryData, name: null });
            }
        }
    };

    // close autocomplete
    const closeAutocomplete = (fieldName) => {
        setAutocompleteFieldsStatus({ ...autocompleteFieldsStatus, [fieldName]: false });
    };

    useEffect(() => {
        if (stationaryId) {
            fetchStationaryData(stationaryId);
        }
    }, []);

    return (
        <div>
            <Drawer
                sx={{
                    "& .MuiDrawer-paper": {
                        width: {
                            xs: "90%",
                            lg: 1000,
                        },
                    },
                }}
                anchor={"right"}
                open={open}
                onClose={handleStationaryFormClose}
            >
                <div className="add-form-base">
                    <div className="add-form-header">
                        <h5 className="add-form-title">{stationaryId ? "Edit" : "New"} Stationary</h5>
                        <button type="button" className="add-form-close" onClick={handleStationaryFormClose}></button>
                    </div>
                    <div className="content-separator"></div>
                    {fetchingStationaryData ? (
                        <LoadingSpinner />
                    ) : (
                        <div className="add-form-scroll-container">
                            <div className="add-form-content">
                                <div className="row-type-2">
                                    <div className="group-1" style={{ alignItems: "normal" }}>
                                        <Box className="row-type-2-label" sx={formAutoCompleteLabelBoxStyles}>
                                            <InputLabel>
                                                Category <span className="required-field-asterisk"> *</span>
                                            </InputLabel>
                                        </Box>
                                        <FormControl className="form-control-for-field">
                                            <Autocomplete
                                                size="small"
                                                value={stationaryData.category}
                                                open={autocompleteFieldsStatus?.category}
                                                onOpen={handleCategoryFieldOpen}
                                                onClose={() => {
                                                    closeAutocomplete(fieldNameMappings?.category);
                                                }}
                                                onChange={(e, option) => {
                                                    handleSelectedOptionsChange(e, option, fieldNameMappings?.category);
                                                }}
                                                onInputChange={handleCategoryInputChange}
                                                loading={autocompleteloading}
                                                options={categoryOptions}
                                                isOptionEqualToValue={(option) => option.label === stationaryData?.category?.label}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        placeholder="Select Category"
                                                        size="small"
                                                        className="input-value"
                                                        error={Boolean(stationaryFormErrors?.category)}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {autocompleteloading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                            <p className="form-error">{stationaryFormErrors?.category || ""}</p>
                                        </FormControl>
                                    </div>
                                    <div className="group-2" style={{ alignItems: "normal" }}>
                                        <Box className="row-type-2-label" sx={formAutoCompleteLabelBoxStyles}>
                                            <InputLabel>
                                                Group Code <span className="required-field-asterisk"> *</span>
                                            </InputLabel>
                                        </Box>
                                        <FormControl className="form-control-for-field">
                                            <Autocomplete
                                                size="small"
                                                value={stationaryData.group_code}
                                                open={autocompleteFieldsStatus?.group_code}
                                                onOpen={handleGroupCodeFieldOpen}
                                                onClose={() => {
                                                    closeAutocomplete(fieldNameMappings?.group_code);
                                                }}
                                                onChange={(e, option) => {
                                                    handleSelectedOptionsChange(e, option, fieldNameMappings?.group_code);
                                                }}
                                                onInputChange={handleGroupCodeInputChange}
                                                loading={autocompleteloading}
                                                options={groupCodeOptions}
                                                isOptionEqualToValue={(option) => option.label === stationaryData?.group_code?.label}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        placeholder="Select Group Code"
                                                        size="small"
                                                        className="input-value"
                                                        error={Boolean(stationaryFormErrors?.group_code)}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {autocompleteloading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                            <p className="form-error">{stationaryFormErrors?.group_code || ""}</p>
                                        </FormControl>
                                    </div>
                                </div>

                                <div className="row-type-2">
                                    <div className="group-1" style={{ alignItems: "normal" }}>
                                        <Box className="row-type-2-label" sx={formAutoCompleteLabelBoxStyles}>
                                            <InputLabel>
                                                Item Code <span className="required-field-asterisk"> *</span>
                                            </InputLabel>
                                        </Box>
                                        <FormControl className="form-control-for-field">
                                            <Autocomplete
                                                size="small"
                                                value={stationaryData.item_code}
                                                open={autocompleteFieldsStatus?.item_code}
                                                onOpen={handleItemCodeFieldOpen}
                                                onClose={() => {
                                                    closeAutocomplete(fieldNameMappings?.item_code);
                                                }}
                                                onChange={(e, option) => {
                                                    handleSelectedOptionsChange(e, option, fieldNameMappings?.item_code);
                                                }}
                                                onInputChange={handleItemCodeInputChange}
                                                loading={autocompleteloading}
                                                options={itemCodeOptions}
                                                isOptionEqualToValue={(option) => option.label === stationaryData?.item_code?.label}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        placeholder="Select Item Code"
                                                        size="small"
                                                        className="input-value"
                                                        error={Boolean(stationaryFormErrors?.item_code)}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {autocompleteloading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                            <p className="form-error">{stationaryFormErrors?.item_code || ""}</p>
                                        </FormControl>
                                    </div>
                                    <div className="group-2" style={{ alignItems: "normal" }}>
                                        <Box className="row-type-2-label" sx={formAutoCompleteLabelBoxStyles}>
                                            <InputLabel>
                                                Item Name<span className="required-field-asterisk"> *</span>
                                            </InputLabel>
                                        </Box>
                                        <FormControl className="form-control-for-field">
                                            <Autocomplete
                                                size="small"
                                                value={stationaryData.name}
                                                open={autocompleteFieldsStatus?.name}
                                                onOpen={handleItemNameFieldOpen}
                                                onClose={() => {
                                                    closeAutocomplete(fieldNameMappings?.name);
                                                }}
                                                onChange={(e, option) => {
                                                    handleSelectedOptionsChange(e, option, fieldNameMappings?.name);
                                                }}
                                                onInputChange={handleItemNameInputChange}
                                                loading={autocompleteloading}
                                                options={itemNameOptions}
                                                isOptionEqualToValue={(option) => option.label === stationaryData?.name?.label}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        placeholder="Select Item Name"
                                                        size="small"
                                                        className="input-value"
                                                        error={Boolean(stationaryFormErrors?.name)}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {autocompleteloading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                            <p className="form-error">{stationaryFormErrors?.name || ""}</p>
                                        </FormControl>
                                    </div>
                                </div>

                                <div className="row-type-2">
                                    <div className="group-1">
                                        <InputLabel className="row-type-2-label">
                                            Challan Number <span className="required-field-asterisk"> *</span>
                                        </InputLabel>
                                        <FormControl className="form-control-for-field">
                                            <TextField
                                                type="text"
                                                name="challan_number"
                                                placeholder="Challan Number"
                                                inputProps={{ minLength: 4, maxLength: 4 }}
                                                value={stationaryData?.challan_number}
                                                onChange={handleStationaryForm}
                                                className="input-value"
                                                size="small"
                                                error={Boolean(stationaryFormErrors?.challan_number)}
                                            />
                                            <p className="form-error">{stationaryFormErrors?.challan_number || ""}</p>
                                        </FormControl>
                                    </div>
                                    <div className="group-2">
                                        <InputLabel className="row-type-2-label">
                                            Date of Purchase
                                            <span className="required-field-asterisk"> *</span>
                                        </InputLabel>
                                        <FormControl className="form-control-for-field">
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    name="date_of_purchase"
                                                    format="DD/MM/YYYY"
                                                    disableFuture={true}
                                                    value={stationaryData?.date_of_purchase || null}
                                                    onChange={(date) => handleDates(date, "date_of_purchase")}
                                                    className={`input-value ${stationaryData?.date_of_purchase === null ? "not-selected" : ""}`}
                                                    size="small"
                                                    emptyLabel="DD/MM/YYYY"
                                                    slotProps={{ textField: { error: Boolean(stationaryFormErrors?.date_of_purchase) } }}
                                                />
                                            </LocalizationProvider>
                                            <p className="form-error">{stationaryFormErrors?.date_of_purchase || ""}</p>
                                        </FormControl>
                                    </div>
                                </div>

                                <div className="row-type-2">
                                    <div className="group-1">
                                        <InputLabel className="row-type-2-label">
                                            Purchased Quantity <span className="required-field-asterisk"> *</span>
                                        </InputLabel>
                                        <FormControl className="form-control-for-field">
                                            <TextField
                                                type="number"
                                                name="purchased_quantity"
                                                placeholder="Purchased Quantity"
                                                value={stationaryData?.purchased_quantity}
                                                onChange={handleStationaryForm}
                                                className="input-value"
                                                size="small"
                                                error={Boolean(stationaryFormErrors?.purchased_quantity)}
                                            />
                                            <p className="form-error">{stationaryFormErrors?.purchased_quantity || ""}</p>
                                        </FormControl>
                                    </div>
                                    <div className="group-2" style={{ alignItems: "normal" }}>
                                        <Box className="row-type-2-label" sx={formAutoCompleteLabelBoxStyles}>
                                            <InputLabel>
                                                Vendor <span className="required-field-asterisk"> *</span>
                                            </InputLabel>
                                        </Box>

                                        <FormControl className="form-control-for-field">
                                            <Autocomplete
                                                size="small"
                                                value={stationaryData.vendor}
                                                open={autocompleteFieldsStatus?.vendor}
                                                onOpen={handleVendorFieldOpen}
                                                onClose={() => {
                                                    closeAutocomplete(fieldNameMappings?.vendor);
                                                }}
                                                onChange={(e, option) => {
                                                    handleSelectedOptionsChange(e, option, fieldNameMappings?.vendor);
                                                }}
                                                onInputChange={handleVendorInputChange}
                                                loading={autocompleteloading}
                                                options={vendorOptions}
                                                isOptionEqualToValue={(option) => option.label === stationaryData?.vendor?.label}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        placeholder="Select Vendor"
                                                        size="small"
                                                        className="input-value"
                                                        error={Boolean(stationaryFormErrors?.vendor)}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {autocompleteloading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                            <p className="form-error">{stationaryFormErrors?.vendor || ""}</p>
                                        </FormControl>
                                    </div>
                                </div>

                                <div className="row-type-1">
                                    <InputLabel className="row-type-1-label">Description</InputLabel>
                                    <FormControl className="form-control-for-field">
                                        <TextField
                                            name="description"
                                            onChange={handleStationaryForm}
                                            placeholder="Description"
                                            value={stationaryData.description}
                                            error={Boolean(stationaryFormErrors?.description)}
                                            className="input-value"
                                            size="small"
                                            multiline
                                        />
                                        <p className="form-error">{stationaryFormErrors?.description || ""}</p>
                                    </FormControl>
                                </div>

                                <div className="row-type-1">
                                    <InputLabel className="row-type-1-label"></InputLabel>
                                    <div className="form-control-for-field mandatory-field-row">
                                        <span className="required-field-asterisk">* </span>
                                        <span className="mandatory-field-text">Mandatory field</span>
                                    </div>
                                </div>

                                <div className="bottom-container">
                                    <div className="content-separator"></div>
                                    <div className="action-buttons">
                                        <button
                                            className="save-button"
                                            onClick={() => {
                                                if (stationaryId) handleStationaryEdit(stationaryId);
                                                else handleStationarySubmit();
                                            }}
                                        >
                                            Save
                                        </button>

                                        <button className="cancel-button" onClick={handleStationaryFormClose}>
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </Drawer>
        </div>
    );
};

export default React.memo(StationaryForm);

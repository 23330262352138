const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    courseEdit: false,
    courseId: null,
    courseAddModuleSpecific: false,
    courseModuleId: null,
    courseModuleName: "",
    courseEditOpenToggle: false, 
    courseAddOpenToggle: false, 
    courseAdded: false,
    courseEdited: false,
}

export const formSlice = createSlice({
    name: "courseForm",
    initialState,
    reducers: {
        setCourseEdit: (state, action) => {
            state.courseEdit = action.payload;
        },

        setCourseAdded: (state, action) => {
            state.courseAdded = action.payload;
        },

        setCourseEdited: (state, action) => {
            state.courseEdited = action.payload;
        },

        setCourseId: (state, action) => {
            state.courseId = action.payload;
        },

        setCourseEditOpenToggle: (state, action) => {
            state.courseEditOpenToggle = !state.courseEditOpenToggle;
        },

        setCourseAddOpenToggle: (state, action) => {
            state.courseAddOpenToggle = !state.courseAddOpenToggle;
        },

        setCourseAddModuleSpecific: (state, action) => {
            state.courseAddModuleSpecific = action.payload;
        },

        setCourseModuleId: (state, action) => {
            state.courseModuleId = action.payload;
        },

        setCourseModuleName: (state, action) => {
            state.courseModuleName = action.payload;
        }
    }
});

export const {setCourseEdit, setCourseAdded, setCourseEdited, setCourseId, setCourseEditOpenToggle, setCourseAddOpenToggle, setCourseAddModuleSpecific, setCourseModuleId, setCourseModuleName} = formSlice.actions;

export default formSlice.reducer;
const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    groupFormOpen: false,
    groupId: null,
    groupAdded: false,
    groupEdited: false,
    groupDeleted: false,
};

export const formSlice = createSlice({
    name: "groupForm",
    initialState,
    reducers: {
        setGroupFormOpen: (state, action) => {
            state.groupFormOpen = action.payload;
        },

        setGroupAdded: (state, action) => {
            state.groupAdded = action.payload;
        },

        setGroupEdited: (state, action) => {
            state.groupEdited = action.payload;
        },

        setGroupDeleted: (state, action) => {
            state.groupDeleted = action.payload;
        },

        setGroupId: (state, action) => {
            state.groupId = action.payload;
        },
    },
});

export const { setGroupFormOpen, setGroupAdded, setGroupEdited, setGroupDeleted, setGroupId } = formSlice.actions;

export default formSlice.reducer;

import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";

import { useSelector, useDispatch } from "react-redux";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { setMasterId } from "../../features/CM/masterFormSlice";
import { FormControl, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { allowedAuditFileTypes, auditFileMaxSize, auditFileMaxSizeText } from "../../constants/Common";
import { riskRatings } from "../../enums/CM/AuditArea";
import { DropzoneArea } from "mui-file-dropzone";
import { CloudUploadOutlined } from "@mui/icons-material";

const AuditAreaForm = ({ open, onClose }) => {
    const dispatch = useDispatch();
    const initialData = {
        audit_area: "",
        risk_rating: "",
        observation: "",
        recommendation: "",
        original_file_name: "",
        file_name: "",
        size: "",
    };

    const [data, setData] = useState(initialData);
    const [formErrors, setFormErrors] = useState({});
    const [fetchingData, setFetchingData] = useState(false);

    const masterId = useSelector((state) => state.masterForm.masterId);

    const handleFormClose = () => {
        onClose();
        setFormErrors({});
        setData(initialData);
        dispatch(setMasterId(null));
    };

    const handleInputChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
        setFormErrors({ ...formErrors, [e.target.name]: "" });
    };

    return (
        <div>
            <Drawer
                sx={{
                    "& .MuiDrawer-paper": {
                        width: {
                            xs: "90%",
                            lg: 1000,
                        },
                    },
                }}
                anchor={"right"}
                open={open}
                onClose={handleFormClose}
            >
                <div className="add-form-base">
                    <div className="add-form-header">
                        <h5 className="add-form-title">{masterId ? "Edit" : "New"} Audit Area</h5>
                        <button type="button" className="add-form-close" onClick={onClose}></button>
                    </div>
                    <div className="content-separator"></div>
                    {fetchingData ? (
                        <LoadingSpinner />
                    ) : (
                        <div className="add-form-scroll-container">
                            <div className="add-form-content">
                                <div className="row-type-1">
                                    <InputLabel className="row-type-1-label">
                                        Audit Area<span className="required-field-asterisk"> *</span>
                                    </InputLabel>

                                    <FormControl className="form-control-for-field">
                                        <TextField
                                            value={data.audit_area}
                                            name="audit_area"
                                            // onChange={(e) => handleTaskForm(e)}
                                            placeholder="Audit Area"
                                            error={Boolean(formErrors?.audit_area)}
                                            className="input-value"
                                            size="small"
                                        />
                                        <p className="form-error">{formErrors?.audit_area || ""}</p>
                                    </FormControl>
                                </div>

                                <div className="row-type-1">
                                    <InputLabel className="row-type-1-label">
                                        Risk Rating
                                        <span className="required-field-asterisk"> *</span>
                                    </InputLabel>
                                    <FormControl className="form-control-for-field" error={Boolean(formErrors?.risk_rating)}>
                                        <Select
                                            name="risk_rating"
                                            onChange={handleInputChange}
                                            value={data?.risk_rating}
                                            className={`select-value ${data?.risk_rating === "" ? "not-selected" : ""}`}
                                            size="small"
                                            displayEmpty
                                            inputProps={{ "aria-label": "Without label" }}
                                            MenuProps={{
                                                disablePortal: true,
                                            }}
                                        >
                                            <MenuItem className="select-menu-item" value="">
                                                Select Risk Rating
                                            </MenuItem>
                                            {Object.keys(riskRatings).map((key) => (
                                                <MenuItem className="select-menu-item" key={key} value={riskRatings[key]}>
                                                    {riskRatings[key]}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <p className="form-error">{formErrors?.risk_rating || ""}</p>
                                    </FormControl>
                                </div>

                                <div className="row-type-1">
                                    <InputLabel className="row-type-1-label">
                                        Observation <span className="required-field-asterisk"> *</span>
                                    </InputLabel>

                                    <FormControl className="form-control-for-field">
                                        <TextField
                                            name="observation"
                                            // onChange={(e) => handleTaskForm(e)}
                                            placeholder="Observation"
                                            value={data.observation}
                                            error={Boolean(formErrors?.observation)}
                                            className="input-value"
                                            size="small"
                                            multiline
                                        />
                                        <p className="form-error">{formErrors?.observation || ""}</p>
                                    </FormControl>
                                </div>

                                <div className="row-type-1">
                                    <InputLabel className="row-type-1-label">
                                        Recommendation <span className="required-field-asterisk"> *</span>
                                    </InputLabel>

                                    <FormControl className="form-control-for-field">
                                        <TextField
                                            name="recommendation"
                                            // onChange={(e) => handleTaskForm(e)}
                                            placeholder="Recommendation"
                                            value={data.recommendation}
                                            error={Boolean(formErrors?.recommendation)}
                                            className="input-value"
                                            size="small"
                                            multiline
                                        />
                                        <p className="form-error">{formErrors?.recommendation || ""}</p>
                                    </FormControl>
                                </div>

                                <div className="row-type-1">
                                    <InputLabel className="row-type-1-label">Audit Report</InputLabel>
                                    <FormControl className="form-control-for-field">
                                        <DropzoneArea
                                            classes={{
                                                root: "drop-container",
                                                text: "drop-text",
                                            }}
                                            filesLimit={1}
                                            acceptedFiles={allowedAuditFileTypes}
                                            maxFileSize={auditFileMaxSize}
                                            // onDrop={onCircularFileDrop}
                                            Icon={CloudUploadOutlined}
                                            showPreviewsInDropzone={false}
                                            showAlerts={false}
                                            previewGridProps={{
                                                container: { spacing: 1, direction: "row", minHeight: "165px" },
                                            }}
                                            previewText="Selected files"
                                            dropzoneText="Drag and drop PDF file or excel sheet here, or click to browse"
                                            dropzoneClass="custom-drop-zone-container"
                                            dropzoneParagraphClass="custom-drop-zone-container-text"
                                            // onDropRejected={handleDropRejected}
                                        />

                                        <Typography variant="caption" mt={1}>
                                            {data?.original_file_name && (
                                                <p>
                                                    {data?.original_file_name} - {data?.size}
                                                </p>
                                            )}
                                            PDF file or excel sheet upto {auditFileMaxSizeText} is allowed
                                        </Typography>
                                        <p className="form-error">{formErrors?.file_name || ""}</p>
                                    </FormControl>
                                </div>

                                <div className="row-type-1">
                                    <InputLabel className="row-type-1-label"></InputLabel>
                                    <div className="form-control-for-field mandatory-field-row">
                                        <span className="required-field-asterisk">* </span>
                                        <span className="mandatory-field-text">Mandatory field</span>
                                    </div>
                                </div>

                                <div className="bottom-container">
                                    <div className="content-separator"></div>
                                    <div className="action-buttons">
                                        <button className="save-button">Save</button>

                                        <button className="cancel-button" onClick={handleFormClose}>
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </Drawer>
        </div>
    );
};

export default React.memo(AuditAreaForm);

// global naviagate settor
let navigateRef;

export const setNavigate = (navigateFunction) => {
    navigateRef = navigateFunction;
};

export const navigateTo = (path) => {
    if (navigateRef) {
        navigateRef(path);
    }
};

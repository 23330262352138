const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    recentlyRestored: false,
    recentlyDeleted: false,
};

export const formSlice = createSlice({
    name: "trash",
    initialState,
    reducers: {
        setRecentlyRestored: (state, action) => {
            state.recentlyRestored = action.payload;
        },
        setRecentlyDeleted: (state, action) => {
            state.recentlyDeleted = action.payload;
        },
    },
});

export const { setRecentlyRestored, setRecentlyDeleted } = formSlice.actions;

export default formSlice.reducer;

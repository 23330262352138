import axios from "axios";
import { toast } from "react-toastify";
import { autoCloseToastError, userLogout } from "../../constants/Common";
import { navigateTo } from "../Common/navigate";
import { store } from "../../app/store";

let isSessionExpiredHandled = false; // Global flag to handle session expiration once

function createInstance() {
    let token = localStorage.getItem("jwt_token");
    if (token === null) token = "";
    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_BACKEND_BASEURL,
        headers: { Authorization: "Bearer " + token },
    });
    return axiosInstance;
}

const handleLogout = (errorMessage) => {
    if (isSessionExpiredHandled) return;
    isSessionExpiredHandled = true;

    // clear local storage
    localStorage.clear();
    toast.error(errorMessage || "Session expired. Please log in again.", { autoClose: autoCloseToastError });

    // Redirect to login using the global navigate function
    navigateTo("/login");

    // initialise the redux states
    store.dispatch({ type: userLogout });
};

function errorHandler(error, customMessage) {
    if (error.response !== null && error.response !== undefined) {
        if (error.response.status === 401) {
            handleLogout(error?.response?.data?.message);
        } else {
            toast.error(error.response?.data, { autoClose: autoCloseToastError });
        }
    } else {
        toast.error(customMessage, { autoClose: autoCloseToastError });
    }
}

// Call this function when the user logs in successfully
const resetSessionExpiredFlag = () => {
    isSessionExpiredHandled = false;
};

export { createInstance, errorHandler, resetSessionExpiredFlag };

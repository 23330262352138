const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    assignAssetFormOpen: false,
    assignAssetId: null,
    assignAssetAdded: false,
    assignAssetEdited: false,
    assignAssetDeleted: false,
};

export const formSlice = createSlice({
    name: "assignAssetForm",
    initialState,
    reducers: {
        setAssignAssetFormOpen: (state, action) => {
            state.assignAssetFormOpen = action.payload;
        },

        setAssignAssetAdded: (state, action) => {
            state.assignAssetAdded = action.payload;
        },

        setAssignAssetEdited: (state, action) => {
            state.assignAssetEdited = action.payload;
        },

        setAssignAssetDeleted: (state, action) => {
            state.assignAssetDeleted = action.payload;
        },

        setAssignAssetId: (state, action) => {
            state.assignAssetId = action.payload;
        },
    },
});

export const { setAssignAssetFormOpen, setAssignAssetAdded, setAssignAssetEdited, setAssignAssetDeleted, setAssignAssetId } = formSlice.actions;

export default formSlice.reducer;

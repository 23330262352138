const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    auditAreaFormOpen: false,
    auditAreaId: null,
    auditAreaAdded: false,
    auditAreaEdited: false,
};

export const formSlice = createSlice({
    name: "auditAreaForm",
    initialState,
    reducers: {
        setAuditAreaFormOpen: (state, action) => {
            state.auditAreaFormOpen = action.payload;
        },

        setAuditAreaId: (state, action) => {
            state.auditAreaId = action.payload;
        },

        setAuditAreaAdded: (state, action) => {
            state.auditAreaAdded = action.payload;
        },
        setAuditAreaEdited: (state, action) => {
            state.auditAreaEdited = action.payload;
        },
    },
});
export const { setAuditAreaFormOpen, setAuditAreaId, setAuditAreaAdded, setAuditAreaEdited } = formSlice.actions;
export default formSlice.reducer;

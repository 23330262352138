const validationMessages = {
    titleDefault: "Title cannot be empty",
    nameDefault: "Name cannot be empty",
    maxDefault: "Maximum 255 characters are allowed",
    minDefault: "Minimum 3 characters are required",
    descriptionDefault: "Enter description",
    selectFileDefault: "Please select a file",
    onlyPastDateDefault: "Must be today or earlier",
    requiredDefault: "This is required field",
    dateDefault: "Select proper date",
    quantityDefault: "Enter valid quantity",
    vendorDefault: "Select proper vendor account",
    challanNumberDefault: "Challan Number must",
    selectProperOptionDefault: "Select proper option",
    serviceProviderDefault: "Select proper service provider",
    pfNumberMax: "PF Number must be maximum of 6 digits",
    locationDefault: "Select proper location",
    userDefault: "Select proper user",
    adminDefault: "Select proper admin",
};

export { validationMessages };

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    ticketIdForDetails: null,
    ticketDetailsOpen: false
}

export const ticketDetailsSlice = createSlice({
    name: "ticketDetails",
    initialState,
    reducers: {
        setTicketIdForDetails: (state, action) => {
            state.ticketIdForDetails = action.payload;
        },

        setTicketDetailsOpen: (state, action) => {
            state.ticketDetailsOpen = action.payload;
        }
    }
});

export const { setTicketIdForDetails, setTicketDetailsOpen } = ticketDetailsSlice.actions;

export default ticketDetailsSlice.reducer;
import { criticality, sensitivity, maintenanceFrequencies } from "../../enums/AM/Asset";
import { validationMessages } from "../../constants/validationMessages/ValidationMessages";

const Joi = require("joi");

const assetSchema = Joi.object().keys({
    category: Joi.string().max(255).messages({
        "string.max": validationMessages.maxDefault,
        "string.empty": validationMessages.requiredDefault,
        "any.required": validationMessages.requiredDefault,
        "string.base": validationMessages.requiredDefault,
    }),

    asset_name: Joi.number().integer().min(1).required().messages({
        "number.min": validationMessages.selectProperOptionDefault,
        "number.base": validationMessages.requiredDefault,
        "any.only": validationMessages.selectProperOptionDefault,
    }),

    make: Joi.string().max(255).required().messages({
        "string.max": validationMessages.maxDefault,
        "string.empty": validationMessages.requiredDefault,
    }),

    model: Joi.string().max(255).required().messages({
        "string.max": validationMessages.maxDefault,
        "string.empty": validationMessages.requiredDefault,
    }),

    criticality: Joi.valid(...Object.values(criticality))
        .required()
        .messages({
            "any.only": validationMessages.requiredDefault,
        }),

    sensitivity: Joi.valid(...Object.values(sensitivity))
        .required()
        .messages({
            "any.only": validationMessages.requiredDefault,
        }),

    date_of_purchase: Joi.date().required().messages({
        "date.base": validationMessages.dateDefault,
        "any.only": validationMessages.dateDefault,
    }),

    purchased_quantity: Joi.number().integer().min(1).required().messages({
        "number.min": validationMessages.quantityDefault,
        "number.base": validationMessages.quantityDefault,
    }),

    maintenance_frequency: Joi.valid(...Object.values(maintenanceFrequencies))
        .required()
        .messages({
            "any.only": validationMessages.requiredDefault,
        }),

    vendor_id: Joi.number().integer().min(1).required().messages({
        "number.min": validationMessages.vendorDefault,
        "number.base": validationMessages.vendorDefault,
        "any.only": validationMessages.vendorDefault,
    }),

    service_provider_id: Joi.number().integer().min(1).required().messages({
        "number.min": validationMessages.serviceProviderDefault,
        "number.base": validationMessages.serviceProviderDefault,
        "any.only": validationMessages.serviceProviderDefault,
    }),

    warranty_start_date: Joi.date().required().messages({
        "date.base": validationMessages.dateDefault,
        "any.only": validationMessages.dateDefault,
    }),
    warranty_end_date: Joi.date().required().messages({
        "date.base": validationMessages.dateDefault,
        "any.only": validationMessages.dateDefault,
    }),

    description: Joi.string().allow("").required().messages({
        "any.required": validationMessages.descriptionDefault,
    }),
});

export { assetSchema };

import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import ToastMessages from "../constants/ToastMessages";
import { toast } from "react-toastify";

const ProtectRoute = ({ allowedRoles = [] }) => {
    const navigate = useNavigate();
    const sessionRole = useSelector((state) => state.userDetails.role_id);
    const jwtToken = localStorage.getItem("jwt_token") || "";

    useEffect(() => {
        if (!jwtToken) {
            navigate("/login");
        } else if (sessionRole && !allowedRoles.includes(sessionRole)) {
            toast.error(ToastMessages.accessProhibited);
            navigate(-1);
        }
    }, [jwtToken, sessionRole, allowedRoles, navigate]);

    if (jwtToken && allowedRoles.includes(sessionRole)) {
        return <Outlet />;
    }
    return null;
};

export default React.memo(ProtectRoute);

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAssetAdded, setAssetEdited, setAssetId } from "../../features/AM/assetForm";
import { assetApi } from "../../constants/apis/AM/Assets";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { toast } from "react-toastify";
import { searchOptionDebounceTime, autoCloseToastError, formAutoCompleteLabelBoxStyles } from "../../constants/Common";
import {
    setAssetCategoryOptions,
    setAssetCategoryOptionsLoaded,
    setVendorAccountOptions,
    setVendorAccountOptionsLoaded,
} from "../../features/optionSlice";
import { criticality, sensitivity, maintenanceFrequencies } from "../../enums/AM/Asset";
import { assetSchema } from "../../validations/AmForms/Asset";
import { createInstance, errorHandler } from "../../utils/Request/ReqUtils";
import { Autocomplete, Box, CircularProgress, debounce, Drawer, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ToastMessages from "../../constants/ToastMessages";
import { getItemNamesApi } from "../../constants/apis/AM/Inventory";
import { createSearchParams } from "react-router-dom";
import { fetchInventoryCategories, fetchCustomerAndVendorAccountOptions } from "../../utils/Common";
import { inventoryTypes } from "../../enums/AM/Inventory";

const AssetForm = ({ open, onClose }) => {
    const dispatch = useDispatch();
    const axiosInstance = createInstance(true);
    const assetId = useSelector((state) => state.assetForm.assetId);
    const assetCategoryOptions = useSelector((state) => state.optionsForAsyncSelect.assetCategoryOptions);
    const assetCategoryOptionsLoaded = useSelector((state) => state.optionsForAsyncSelect.assetCategoryOptionsLoaded);
    const vendorAccountOptions = useSelector((state) => state.optionsForAsyncSelect.vendorAccountOptions);
    const vendorAccountOptionsLoaded = useSelector((state) => state.optionsForAsyncSelect.vendorAccountOptionsLoaded);

    const initialAssetData = {
        category: null,
        asset_name: null,
        make: "",
        model: "",
        criticality: "",
        sensitivity: "",
        date_of_purchase: null,
        purchased_quantity: "",
        maintenance_frequency: "",
        vendor_id: null,
        service_provider_id: null,
        warranty_start_date: null,
        warranty_end_date: null,
        description: "",
    };

    // used for not calling the apis of autocomplete option when initialising the data in form while edit
    const initialInitialize = {
        initialize_category: true,
        initialize_asset_name: true,
        initialize_vendor_id: true,
        initialize_service_provider_id: true,
    };

    const initialAutocompleteFieldsStatus = {
        category: false,
        asset_name: false,
        vendor_id: false,
        service_provider_id: false,
        ...(assetId && { ...initialInitialize }),
    };

    const fieldNameMappings = {
        category: "category",
        asset_name: "asset_name",
        vendor_id: "vendor_id",
        service_provider_id: "service_provider_id",
    };

    const [assetData, setAssetData] = useState(initialAssetData);
    const [assetFormErrors, setAssetFormErrors] = useState({});

    const [fetchingAssetData, setFetchingAssetData] = useState(false);
    const [autocompleteFieldsStatus, setAutocompleteFieldsStatus] = useState(initialAutocompleteFieldsStatus);
    const [autocompleteloading, setAutocompleteLoading] = useState(false);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [itemNameOptions, setItemNameOptions] = useState([]);
    const [vendorOptions, setVendorOptions] = useState([]);

    const handleAssetForm = (e) => {
        setAssetFormErrors({ ...assetFormErrors, [e.target.name]: "" });
        setAssetData({ ...assetData, [e.target.name]: e.target.value });
    };

    const handleDates = (date, fieldName) => {
        setAssetFormErrors({ ...assetFormErrors, [fieldName]: "" });
        setAssetData({ ...assetData, [fieldName]: date });
    };

    const formatDataToCreateValidationPayload = () => {
        const assetValidationPayload = JSON.parse(JSON.stringify(assetData));
        const fieldsToProcess = ["category", "asset_name", "vendor_id", "service_provider_id"];

        fieldsToProcess.forEach((field) => {
            assetValidationPayload[field] = assetValidationPayload?.[field]?.value || null;
        });

        const datesToProcess = ["date_of_purchase", "warranty_start_date", "warranty_end_date"];
        datesToProcess.forEach((field) => {
            assetValidationPayload[field] = assetData[field]?.format("YYYY-MM-DD") || null;
        });

        const fieldsToDelete = ["inventory_id", "available_quantity", "vendor_name", "service_provider_name"];

        fieldsToDelete.forEach((field) => delete assetValidationPayload?.[field]);

        return assetValidationPayload;
    };

    const formatDataToCreatePayload = (data) => {
        data.inventory_id = data?.asset_name;
        const fieldsToDelete = ["category", "asset_name"];

        fieldsToDelete.forEach((field) => delete data?.[field]);
        return data;
    };

    const handleAssetSubmit = async (e) => {
        e.preventDefault();
        const assetValidationPayload = formatDataToCreateValidationPayload();
        const validation = assetSchema.validate(assetValidationPayload, { abortEarly: false });
        if (validation.error) {
            setAssetFormErrors({});
            const errorDetails = validation.error.details;
            let currentErrors = {};
            errorDetails.forEach((error) => {
                currentErrors[error.path[0]] = error.message;
            });
            setAssetFormErrors(currentErrors);
        } else {
            try {
                const payload = formatDataToCreatePayload(assetValidationPayload);
                const axiosInstance = createInstance(true);
                let response = await axiosInstance.post(assetApi, payload);
                if (response?.status === 200) {
                    toast.success(ToastMessages.addAssetSuccess);
                    handleAssetFormClose();
                    dispatch(setAssetAdded(true));
                } else {
                    toast.error(ToastMessages.failedToAddAsset, { autoClose: autoCloseToastError });
                }
            } catch (err) {
                errorHandler(err, ToastMessages.failedToAddAsset);
            }
        }
    };

    const handleAssetEdit = async (id) => {
        const assetValidationPayload = formatDataToCreateValidationPayload();
        const validation = assetSchema.validate(assetValidationPayload, { abortEarly: false });
        if (validation.error) {
            setAssetFormErrors({});
            const errorDetails = validation.error.details;
            let currentErrors = {};
            errorDetails.forEach((error) => {
                currentErrors[error.path[0]] = error.message;
            });
            setAssetFormErrors(currentErrors);
        } else {
            try {
                const paylaod = formatDataToCreatePayload(assetValidationPayload);
                const axiosInstance = createInstance(true);
                let response = await axiosInstance.put(assetApi.concat(id), paylaod);
                if (response?.status === 200) {
                    toast.success(ToastMessages.assetEditSuccess);
                    handleAssetFormClose();
                    dispatch(setAssetEdited(true));
                } else {
                    toast.error(ToastMessages.failedToEditAsset, { autoClose: autoCloseToastError });
                }
            } catch (err) {
                errorHandler(err, ToastMessages.failedToEditAsset);
            }
        }
    };

    const handleAssetFormClose = () => {
        onClose();
        setAssetFormErrors({});
        setAssetData(initialAssetData);
        dispatch(setAssetId(null));
    };

    // functions to fetch data
    const fetchAssetItemNames = async (params) => {
        try {
            const response = await axiosInstance.get(`${getItemNamesApi}?${params}`);
            if (response?.status === 200) {
                const formattedOptions = response?.data?.data.map((item) => ({ value: item?.id, label: item?.name }));
                return formattedOptions;
            } else {
                toast.error(ToastMessages.failedToFetchItemNames);
            }
        } catch (err) {
            errorHandler(err, ToastMessages.failedToFetchItemNames);
        }
    };

    // debounced functions to fetch data
    const debouncedFetchAssetCategories = debounce(async (newInputValue) => {
        setAutocompleteLoading(true);

        try {
            const params = createSearchParams({
                search: newInputValue || "",
                source: inventoryTypes.Asset.toLowerCase(),
            });
            const fetchedOptions = await fetchInventoryCategories(params);
            if (!assetCategoryOptionsLoaded && fetchedOptions) {
                dispatch(setAssetCategoryOptionsLoaded(true));
                dispatch(setAssetCategoryOptions(fetchedOptions));
            }

            // fetched option might not contain the selected options so adding that to avoid warnings of option value not exists
            const mergedOptions = [
                ...(fetchedOptions || []),
                ...categoryOptions.filter((selectedOption) => !fetchedOptions.some((option) => option?.value === selectedOption?.value)),
            ];

            setCategoryOptions(mergedOptions);
        } catch (error) {}
        setAutocompleteLoading(false);
    }, searchOptionDebounceTime);

    const debouncedFetchAssetItemNames = debounce(async (newInputValue) => {
        setAutocompleteLoading(true);

        try {
            const params = createSearchParams({
                category: assetData?.category?.value,
                search: newInputValue || "",
                source: inventoryTypes.Asset.toLowerCase(),
            });
            const fetchedOptions = await fetchAssetItemNames(params);

            // fetched option might not contain the selected options so adding that to avoid warnings of option value not exists
            const mergedOptions = [
                ...(fetchedOptions || []),
                ...itemNameOptions.filter((selectedOption) => !fetchedOptions.some((option) => option?.value === selectedOption?.value)),
            ];

            setItemNameOptions(mergedOptions);
        } catch (error) {}
        setAutocompleteLoading(false);
    }, searchOptionDebounceTime);

    const debouncedFetchVendors = debounce(async (newInputValue) => {
        setAutocompleteLoading(true);

        try {
            const params = createSearchParams({
                search: newInputValue || "",
                vendorsRequired: true,
                customersRequired: false,
                selfRequired: false,
            });
            const fetchedOptions = await fetchCustomerAndVendorAccountOptions(params);
            if (!vendorAccountOptionsLoaded && fetchedOptions) {
                dispatch(setVendorAccountOptionsLoaded(true));
                dispatch(setVendorAccountOptions(fetchedOptions));
            }

            // fetched option might not contain the selected options so adding that to avoid warnings of option value not exists
            const mergedOptions = [
                ...(fetchedOptions || []),
                ...vendorOptions.filter((selectedOption) => !fetchedOptions.some((option) => option?.value === selectedOption?.value)),
            ];

            setVendorOptions(mergedOptions);
        } catch (error) {}
        setAutocompleteLoading(false);
    }, searchOptionDebounceTime);

    // on autocomplete option change
    const handleSelectedOptionsChange = (e, option, fieldName) => {
        if (option) {
            if (fieldName === fieldNameMappings?.category) {
                setAssetData({ ...assetData, [fieldName]: option || null, asset_name: null });
                setItemNameOptions([]);
            } else setAssetData({ ...assetData, [fieldName]: option || null });
            setAssetFormErrors({ ...assetFormErrors, [fieldName]: "" });
        } else {
            if (fieldName === fieldNameMappings?.category) {
                setAssetData({ ...assetData, category: null, asset_name: null });
                setItemNameOptions([]);
            } else if (fieldName === fieldNameMappings?.asset_name) {
                setAssetData({ ...assetData, asset_name: null });
            } else if (fieldName === fieldNameMappings?.vendor_id) {
                setAssetData({ ...assetData, vendor_id: null });
            } else if (fieldName === fieldNameMappings?.service_provider_id) {
                setAssetData({ ...assetData, service_provider_id: null });
            }
        }
    };

    // functions for autocomplete field open to fetch data
    const handleCategoryFieldOpen = async () => {
        setAutocompleteFieldsStatus({ ...autocompleteFieldsStatus, category: true, initialize_category: false });
        if (!assetCategoryOptionsLoaded) {
            debouncedFetchAssetCategories("");
        } else {
            // options might not contain the selected options so adding that to avoid warnings of option value not exists
            const mergedOptions = [
                ...(assetCategoryOptions || []),
                ...categoryOptions.filter((selectedOption) => !assetCategoryOptions.some((option) => option?.value === selectedOption?.value)),
            ];
            setCategoryOptions(mergedOptions);
        }
    };

    const handleAssetItemNameFieldOpen = async () => {
        setAutocompleteFieldsStatus({ ...autocompleteFieldsStatus, asset_name: true, initialize_asset_name: false });
        if (assetData.category && !itemNameOptions.length) {
            debouncedFetchAssetItemNames("");
        }
    };

    const handleVendorFieldOpen = async () => {
        setAutocompleteFieldsStatus({ ...autocompleteFieldsStatus, vendor_id: true, initialize_vendor_id: false });
        if (!vendorAccountOptionsLoaded) {
            debouncedFetchVendors("");
        } else {
            // options might not contain the selected options so adding that to avoid warnings of option value not exists

            const mergedOptions = [
                ...(vendorAccountOptions || []),
                ...vendorOptions.filter((selectedOption) => !vendorAccountOptions.some((option) => option?.value === selectedOption?.value)),
            ];
            setVendorOptions(mergedOptions);
        }
    };

    const handleServideProviderFieldOpen = async () => {
        setAutocompleteFieldsStatus({ ...autocompleteFieldsStatus, service_provider_id: true, initialize_service_provider_id: false });
        if (!vendorAccountOptionsLoaded) {
            debouncedFetchVendors("");
        } else {
            // options might not contain the selected options so adding that to avoid warnings of option value not exists

            const mergedOptions = [
                ...(vendorAccountOptions || []),
                ...vendorOptions.filter((selectedOption) => !vendorAccountOptions.some((option) => option?.value === selectedOption?.value)),
            ];
            setVendorOptions(mergedOptions);
        }
    };

    // functions to handle the search in autocomplete
    const handleCategoryInputChange = async (event, value) => {
        const inputExistsInOptions = categoryOptions.some((option) => option?.label.toLowerCase() === value.toLowerCase());
        if (!inputExistsInOptions && value && !autocompleteFieldsStatus.initialize_category) {
            debouncedFetchAssetCategories(value);
        }
    };

    const handleAssetItemNameInputChange = async (event, value) => {
        const inputExistsInOptions = itemNameOptions.some((option) => option?.label.toLowerCase() === value.toLowerCase());
        if (!inputExistsInOptions && assetData.category && value && !autocompleteFieldsStatus.initialize_asset_name) {
            debouncedFetchAssetItemNames(value);
        }
    };

    const handleVendorInputChange = async (event, value) => {
        const inputExistsInOptions = vendorOptions.some((option) => option?.label.toLowerCase() === value.toLowerCase());
        if (!inputExistsInOptions && value && !autocompleteFieldsStatus.initialize_vendor_id) {
            debouncedFetchVendors(value);
        }
    };

    const handleServiceProviderInputChange = async (event, value) => {
        const inputExistsInOptions = vendorOptions.some((option) => option?.label.toLowerCase() === value.toLowerCase());
        if (!inputExistsInOptions && value && !autocompleteFieldsStatus.initialize_service_provider_id) {
            debouncedFetchVendors(value);
        }
    };

    // close autocomplete
    const closeAutocomplete = (fieldName) => {
        setAutocompleteFieldsStatus({ ...autocompleteFieldsStatus, [fieldName]: false });
    };

    const fetchAssetData = async (id) => {
        setFetchingAssetData(true);
        try {
            const result = await axiosInstance.get(assetApi + "/" + id);
            if (result.status === 200) {
                const fetchedAssetData = result?.data?.data[0];
                for (let key in initialAssetData) {
                    fetchedAssetData[key] = fetchedAssetData[key] || "";
                    if (["date_of_purchase", "warranty_start_date", "warranty_end_date"].includes(key)) {
                        if (fetchedAssetData[key]) {
                            const formattedDate = dayjs(fetchedAssetData[key]);
                            fetchedAssetData[key] = formattedDate;
                        } else {
                            fetchedAssetData[key] = null;
                        }
                    } else if (key === "category") {
                        fetchedAssetData[key] = { label: fetchedAssetData[key], value: fetchedAssetData[key] };
                    } else if (key === "asset_name") {
                        fetchedAssetData[key] = { label: fetchedAssetData[key], value: fetchedAssetData["inventory_id"] };
                    } else if (key === "vendor_id") {
                        fetchedAssetData[key] = {
                            value: fetchedAssetData?.vendor_id,
                            label: fetchedAssetData?.vendor_name,
                            additionalData: {},
                        };
                    } else if (key === "service_provider_id") {
                        fetchedAssetData[key] = {
                            value: fetchedAssetData?.service_provider_id,
                            label: fetchedAssetData?.service_provider_name,
                            additionalData: {},
                        };
                    }
                }
                setAssetData(fetchedAssetData);
                setAutocompleteFieldsStatus(initialAutocompleteFieldsStatus);
            } else {
                toast.error(ToastMessages.failedToFetchAssets);
            }
        } catch (err) {
            errorHandler(err, ToastMessages.failedToFetchAssets);
        }
        setFetchingAssetData(false);
    };

    useEffect(() => {
        if (assetId) {
            fetchAssetData(assetId);
        }
    }, []);

    return (
        <div>
            <Drawer
                sx={{
                    "& .MuiDrawer-paper": {
                        width: {
                            xs: "90%",
                            lg: 1000,
                        },
                    },
                }}
                anchor={"right"}
                open={open}
                onClose={handleAssetFormClose}
            >
                <div className="add-form-base">
                    <div className="add-form-header">
                        <h5 className="add-form-title">
                            {assetId ? "Edit " : "New "}
                            Asset
                        </h5>
                        <button type="button" className="add-form-close" onClick={handleAssetFormClose}></button>
                    </div>
                    <div className="content-separator"></div>
                    {fetchingAssetData ? (
                        <LoadingSpinner />
                    ) : (
                        <div className="add-form-scroll-container">
                            <div className="add-form-content">
                                <div className="row-type-2">
                                    <div className="group-1" style={{ alignItems: "normal" }}>
                                        <Box className="row-type-2-label" sx={formAutoCompleteLabelBoxStyles}>
                                            <InputLabel>
                                                Category <span className="required-field-asterisk"> *</span>
                                            </InputLabel>
                                        </Box>
                                        <FormControl className="form-control-for-field">
                                            <Autocomplete
                                                size="small"
                                                value={assetData.category}
                                                open={autocompleteFieldsStatus?.category}
                                                onOpen={handleCategoryFieldOpen}
                                                onClose={() => {
                                                    closeAutocomplete(fieldNameMappings?.category);
                                                }}
                                                onChange={(e, option) => {
                                                    handleSelectedOptionsChange(e, option, fieldNameMappings?.category);
                                                }}
                                                onInputChange={handleCategoryInputChange}
                                                loading={autocompleteloading}
                                                options={categoryOptions}
                                                isOptionEqualToValue={(option) => option.label === assetData?.category?.label}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        placeholder="Select Category"
                                                        size="small"
                                                        className="input-value"
                                                        error={Boolean(assetFormErrors?.category)}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {autocompleteloading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                            <p className="form-error">{assetFormErrors?.category || ""}</p>
                                        </FormControl>
                                    </div>
                                    <div className="group-2" style={{ alignItems: "normal" }}>
                                        <Box className="row-type-2-label" sx={formAutoCompleteLabelBoxStyles}>
                                            <InputLabel>
                                                Asset Name<span className="required-field-asterisk"> *</span>
                                            </InputLabel>
                                        </Box>
                                        <FormControl className="form-control-for-field">
                                            <Autocomplete
                                                size="small"
                                                value={assetData.asset_name}
                                                open={autocompleteFieldsStatus?.asset_name}
                                                onOpen={handleAssetItemNameFieldOpen}
                                                onClose={() => {
                                                    closeAutocomplete(fieldNameMappings?.asset_name);
                                                }}
                                                onChange={(e, option) => {
                                                    handleSelectedOptionsChange(e, option, fieldNameMappings?.asset_name);
                                                }}
                                                onInputChange={handleAssetItemNameInputChange}
                                                loading={autocompleteloading}
                                                options={itemNameOptions}
                                                isOptionEqualToValue={(option) => option.label === assetData?.asset_name?.label}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        placeholder="Select Asset Name"
                                                        size="small"
                                                        className="input-value"
                                                        error={Boolean(assetFormErrors?.asset_name)}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {autocompleteloading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                            <p className="form-error">{assetFormErrors?.asset_name || ""}</p>
                                        </FormControl>
                                    </div>
                                </div>

                                <div className="row-type-2">
                                    <div className="group-1">
                                        <InputLabel className="row-type-2-label">
                                            Make <span className="required-field-asterisk"> *</span>
                                        </InputLabel>
                                        <FormControl className="form-control-for-field">
                                            <TextField
                                                type="text"
                                                name="make"
                                                placeholder="Make"
                                                value={assetData?.make}
                                                onChange={handleAssetForm}
                                                className="input-value"
                                                size="small"
                                                error={Boolean(assetFormErrors?.make)}
                                            />
                                            <p className="form-error">{assetFormErrors?.make || ""}</p>
                                        </FormControl>
                                    </div>
                                    <div className="group-1">
                                        <InputLabel className="row-type-2-label">
                                            Model <span className="required-field-asterisk"> *</span>
                                        </InputLabel>
                                        <FormControl className="form-control-for-field">
                                            <TextField
                                                type="text"
                                                name="model"
                                                placeholder="Model"
                                                value={assetData?.model}
                                                onChange={handleAssetForm}
                                                className="input-value"
                                                size="small"
                                                error={Boolean(assetFormErrors?.model)}
                                            />
                                            <p className="form-error">{assetFormErrors?.model || ""}</p>
                                        </FormControl>
                                    </div>
                                </div>

                                <div className="row-type-2">
                                    <div className="group-1">
                                        <InputLabel className="row-type-2-label">
                                            Criticality
                                            <span className="required-field-asterisk"> *</span>
                                        </InputLabel>
                                        <FormControl className="form-control-for-field" error={Boolean(assetFormErrors?.criticality)}>
                                            <Select
                                                name="criticality"
                                                onChange={handleAssetForm}
                                                value={assetData?.criticality}
                                                className={`select-value ${assetData?.criticality === "" ? "not-selected" : ""}`}
                                                size="small"
                                                displayEmpty
                                                inputProps={{ "aria-label": "Without label" }}
                                                MenuProps={{
                                                    disablePortal: true,
                                                }}
                                            >
                                                <MenuItem className="select-menu-item" value="">
                                                    Select Criticality
                                                </MenuItem>
                                                {Object.keys(criticality).map((key) => (
                                                    <MenuItem className="select-menu-item" key={key} value={criticality[key]}>
                                                        {criticality[key]}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <p className="form-error">{assetFormErrors?.criticality || ""}</p>
                                        </FormControl>
                                    </div>
                                    <div className="group-2">
                                        <InputLabel className="row-type-2-label">
                                            Sensitivity
                                            <span className="required-field-asterisk"> *</span>
                                        </InputLabel>
                                        <FormControl className="form-control-for-field" error={Boolean(assetFormErrors?.sensitivity)}>
                                            <Select
                                                name="sensitivity"
                                                onChange={handleAssetForm}
                                                value={assetData?.sensitivity}
                                                className={`select-value ${assetData?.sensitivity === "" ? "not-selected" : ""}`}
                                                size="small"
                                                displayEmpty
                                                inputProps={{ "aria-label": "Without label" }}
                                                MenuProps={{
                                                    disablePortal: true,
                                                }}
                                            >
                                                <MenuItem className="select-menu-item" value="">
                                                    Select Sensitivity
                                                </MenuItem>
                                                {Object.keys(sensitivity).map((key) => (
                                                    <MenuItem className="select-menu-item" key={key} value={sensitivity[key]}>
                                                        {sensitivity[key]}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <p className="form-error">{assetFormErrors?.sensitivity || ""}</p>
                                        </FormControl>
                                    </div>
                                </div>

                                <div className="row-type-2">
                                    <div className="group-1">
                                        <InputLabel className="row-type-2-label">
                                            Date of Purchase
                                            <span className="required-field-asterisk"> *</span>
                                        </InputLabel>
                                        <FormControl className="form-control-for-field">
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    name="date_of_purchase"
                                                    format="DD/MM/YYYY"
                                                    disableFuture={true}
                                                    value={assetData?.date_of_purchase || null}
                                                    onChange={(date) => handleDates(date, "date_of_purchase")}
                                                    className={`input-value ${assetData?.date_of_purchase === null ? "not-selected" : ""}`}
                                                    size="small"
                                                    emptyLabel="DD/MM/YYYY"
                                                    slotProps={{ textField: { error: Boolean(assetFormErrors?.date_of_purchase) } }}
                                                />
                                            </LocalizationProvider>
                                            <p className="form-error">{assetFormErrors?.date_of_purchase || ""}</p>
                                        </FormControl>
                                    </div>
                                    <div className="group-2">
                                        <InputLabel className="row-type-2-label">
                                            Purchased Quantity <span className="required-field-asterisk"> *</span>
                                        </InputLabel>
                                        <FormControl className="form-control-for-field">
                                            <TextField
                                                type="number"
                                                name="purchased_quantity"
                                                placeholder="Purchased Quantity"
                                                value={assetData?.purchased_quantity}
                                                onChange={handleAssetForm}
                                                className="input-value"
                                                size="small"
                                                error={Boolean(assetFormErrors?.purchased_quantity)}
                                            />
                                            <p className="form-error">{assetFormErrors?.purchased_quantity || ""}</p>
                                        </FormControl>
                                    </div>
                                </div>

                                <div className="row-type-2">
                                    <div className="group-1">
                                        <InputLabel className="row-type-2-label">
                                            Maintenance Freq
                                            <span className="required-field-asterisk"> *</span>
                                        </InputLabel>
                                        <FormControl className="form-control-for-field" error={Boolean(assetFormErrors?.maintenance_frequency)}>
                                            <Select
                                                name="maintenance_frequency"
                                                onChange={handleAssetForm}
                                                value={assetData?.maintenance_frequency}
                                                className={`select-value ${assetData?.maintenance_frequency === "" ? "not-selected" : ""}`}
                                                size="small"
                                                displayEmpty
                                                inputProps={{ "aria-label": "Without label" }}
                                                MenuProps={{
                                                    disablePortal: true,
                                                }}
                                            >
                                                <MenuItem className="select-menu-item" value="">
                                                    Select Maintenance Freq
                                                </MenuItem>
                                                {Object.keys(maintenanceFrequencies).map((key) => (
                                                    <MenuItem className="select-menu-item" key={key} value={maintenanceFrequencies[key]}>
                                                        {maintenanceFrequencies[key]}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <p className="form-error">{assetFormErrors?.maintenance_frequency || ""}</p>
                                        </FormControl>
                                    </div>
                                    <div className="group-2">
                                        <InputLabel className="row-type-2-label">
                                            Available Quantity <span className="required-field-asterisk"> *</span>
                                        </InputLabel>
                                        <FormControl className="form-control-for-field">
                                            <TextField
                                                type="number"
                                                name="available_quantity"
                                                placeholder="Available Quantity"
                                                value={assetId ? assetData?.available_quantity : assetData?.purchased_quantity}
                                                onChange={handleAssetForm}
                                                className="input-value"
                                                size="small"
                                                disabled={true}
                                            />
                                            <p className="form-error"></p>
                                        </FormControl>
                                    </div>
                                </div>

                                <div className="row-type-2">
                                    <div className="group-1" style={{ alignItems: "normal" }}>
                                        <Box className="row-type-2-label" sx={formAutoCompleteLabelBoxStyles}>
                                            <InputLabel>
                                                Vendor <span className="required-field-asterisk"> *</span>
                                            </InputLabel>
                                        </Box>

                                        <FormControl className="form-control-for-field">
                                            <Autocomplete
                                                size="small"
                                                value={assetData.vendor_id}
                                                open={autocompleteFieldsStatus?.vendor_id}
                                                onOpen={handleVendorFieldOpen}
                                                onClose={() => {
                                                    closeAutocomplete(fieldNameMappings?.vendor_id);
                                                }}
                                                onChange={(e, option) => {
                                                    handleSelectedOptionsChange(e, option, fieldNameMappings?.vendor_id);
                                                }}
                                                onInputChange={handleVendorInputChange}
                                                loading={autocompleteloading}
                                                options={vendorOptions}
                                                isOptionEqualToValue={(option) => option.label === assetData?.vendor_id?.label}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        placeholder="Select Vendor"
                                                        size="small"
                                                        className="input-value"
                                                        error={Boolean(assetFormErrors?.vendor_id)}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {autocompleteloading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                            <p className="form-error">{assetFormErrors?.vendor_id || ""}</p>
                                        </FormControl>
                                    </div>
                                    <div className="group-2" style={{ alignItems: "normal" }}>
                                        <Box className="row-type-2-label" sx={formAutoCompleteLabelBoxStyles}>
                                            <InputLabel>
                                                Service Provider <span className="required-field-asterisk"> *</span>
                                            </InputLabel>
                                        </Box>

                                        <FormControl className="form-control-for-field">
                                            <Autocomplete
                                                size="small"
                                                value={assetData.service_provider_id}
                                                open={autocompleteFieldsStatus?.service_provider_id}
                                                onOpen={handleServideProviderFieldOpen}
                                                onClose={() => {
                                                    closeAutocomplete(fieldNameMappings?.service_provider_id);
                                                }}
                                                onChange={(e, option) => {
                                                    handleSelectedOptionsChange(e, option, fieldNameMappings?.service_provider_id);
                                                }}
                                                onInputChange={handleServiceProviderInputChange}
                                                loading={autocompleteloading}
                                                options={vendorOptions}
                                                isOptionEqualToValue={(option) => option.label === assetData?.service_provider_id?.label}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        placeholder="Select Service Provider"
                                                        size="small"
                                                        className="input-value"
                                                        error={Boolean(assetFormErrors?.service_provider_id)}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {autocompleteloading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                            <p className="form-error">{assetFormErrors?.service_provider_id || ""}</p>
                                        </FormControl>
                                    </div>
                                </div>

                                <div className="row-type-2">
                                    <div className="group-1">
                                        <InputLabel className="row-type-2-label">
                                            Warranty Start Date
                                            <span className="required-field-asterisk"> *</span>
                                        </InputLabel>
                                        <FormControl className="form-control-for-field">
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    name="warranty_start_date"
                                                    format="DD/MM/YYYY"
                                                    disableFuture={true}
                                                    value={assetData?.warranty_start_date || null}
                                                    onChange={(date) => handleDates(date, "warranty_start_date")}
                                                    className={`input-value ${assetData?.warranty_start_date === null ? "not-selected" : ""}`}
                                                    size="small"
                                                    emptyLabel="DD/MM/YYYY"
                                                    slotProps={{ textField: { error: Boolean(assetFormErrors?.warranty_start_date) } }}
                                                />
                                            </LocalizationProvider>
                                            <p className="form-error">{assetFormErrors?.warranty_start_date || ""}</p>
                                        </FormControl>
                                    </div>
                                    <div className="group-2">
                                        <InputLabel className="row-type-2-label">
                                            Warranty End Date
                                            <span className="required-field-asterisk"> *</span>
                                        </InputLabel>
                                        <FormControl className="form-control-for-field">
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    name="warranty_end_date"
                                                    format="DD/MM/YYYY"
                                                    value={assetData?.warranty_end_date || null}
                                                    onChange={(date) => handleDates(date, "warranty_end_date")}
                                                    className={`input-value ${assetData?.warranty_end_date === null ? "not-selected" : ""}`}
                                                    size="small"
                                                    emptyLabel="DD/MM/YYYY"
                                                    slotProps={{ textField: { error: Boolean(assetFormErrors?.warranty_end_date) } }}
                                                />
                                            </LocalizationProvider>
                                            <p className="form-error">{assetFormErrors?.warranty_end_date || ""}</p>
                                        </FormControl>
                                    </div>
                                </div>

                                <div className="row-type-1">
                                    <InputLabel className="row-type-1-label">Description</InputLabel>
                                    <FormControl className="form-control-for-field">
                                        <TextField
                                            name="description"
                                            onChange={handleAssetForm}
                                            placeholder="Description"
                                            value={assetData?.description}
                                            error={Boolean(assetFormErrors?.description)}
                                            className="input-value"
                                            size="small"
                                            multiline
                                        />
                                        <p className="form-error">{assetFormErrors?.description || ""}</p>
                                    </FormControl>
                                </div>

                                <div className="row-type-1">
                                    <InputLabel className="row-type-1-label"></InputLabel>
                                    <div className="form-control-for-field mandatory-field-row">
                                        <span className="required-field-asterisk">* </span>
                                        <span className="mandatory-field-text">Mandatory field</span>
                                    </div>
                                </div>

                                <div className="bottom-container">
                                    <div className="content-separator"></div>
                                    <div className="action-buttons">
                                        <button
                                            className="save-button"
                                            onClick={(e) => {
                                                if (assetId) {
                                                    handleAssetEdit(assetId);
                                                } else handleAssetSubmit(e);
                                            }}
                                        >
                                            Save
                                        </button>

                                        <button className="cancel-button" onClick={handleAssetFormClose}>
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </Drawer>
        </div>
    );
};

export default React.memo(AssetForm);

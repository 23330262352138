const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    userDeleted: false,
    userId: null,
    userAddOpenToggle: false,
    userAdded: false,
    userAddedForAccountId: null,
    userEdit: false,
    userEditOpenToggle: false,
    userEdited: false,
};

export const formSlice = createSlice({
    name: "userForm",
    initialState,
    reducers: {
        setUserDeleted: (state, action) => {
            state.userDeleted = action.payload;
        },
        setUserAdded: (state, action) => {
            state.userAdded = action.payload;
        },
        setUserAddedForAccountId: (state, action) => {
            state.userAddedForAccountId = action.payload;
        },
        setUserId: (state, action) => {
            state.userId = action.payload;
        },
        setUserAddOpenToggle: (state, action) => {
            state.userAddOpenToggle = !state.userAddOpenToggle;
        },
        setUserEdit: (state, action) => {
            state.userEdit = action.payload;
        },
        setUserEditOpenToggle: (state, action) => {
            state.userEditOpenToggle = !state.userEditOpenToggle;
        },
        setUserEdited: (state, action) => {
            state.userEdited = action.payload;
        },
    },
});

export const {
    setUserDeleted,
    setUserAdded,
    setUserAddedForAccountId,
    setUserId,
    setUserAddOpenToggle,
    setUserEdit,
    setUserEditOpenToggle,
    setUserEdited,
} = formSlice.actions;

export default formSlice.reducer;

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    importLocationsAddOpenToggle: false,
    locationsImported: false,
    locationsImportedForAccountId: null,
};

export const formSlice = createSlice({
    name: "importLocationsForm",
    initialState,
    reducers: {
        setImportLocationsAddOpenToggle: (state, action) => {
            state.importLocationsAddOpenToggle = !state.importLocationsAddOpenToggle;
        },

        setLocationsImported: (state, action) => {
            state.locationsImported = action.payload;
        },

        setLocationsImportedForAccountId: (state, action) => {
            state.locationsImportedForAccountId = action.payload;
        },
    },
});

export const { setImportLocationsAddOpenToggle, setLocationsImported, setLocationsImportedForAccountId } = formSlice.actions;

export default formSlice.reducer;

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    consumableFormOpen: false,
    consumableId: null,
    consumableAdded: false,
    consumableEdited: false,
};

export const formSlice = createSlice({
    name: "consumableForm",
    initialState,
    reducers: {
        setConsumableFormOpen: (state, action) => {
            state.consumableFormOpen = action.payload;
        },

        setConsumableId: (state, action) => {
            state.consumableId = action.payload;
        },

        setConsumableAdded: (state, action) => {
            state.consumableAdded = action.payload;
        },
        setConsumableEdited: (state, action) => {
            state.consumableEdited = action.payload;
        },
    },
});
export const { setConsumableFormOpen, setConsumableId, setConsumableAdded, setConsumableEdited } = formSlice.actions;
export default formSlice.reducer;

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    assetFormOpen: false,
    assetId: null,
    assetAdded: false,
    assetEdited: false,
};

export const formSlice = createSlice({
    name: "assetForm",
    initialState,
    reducers: {
        setAssetFormOpen: (state, action) => {
            state.assetFormOpen = action.payload;
        },

        setAssetId: (state, action) => {
            state.assetId = action.payload;
        },

        setAssetAdded: (state, action) => {
            state.assetAdded = action.payload;
        },
        setAssetEdited: (state, action) => {
            state.assetEdited = action.payload;
        },
    },
});
export const { setAssetFormOpen, setAssetId, setAssetAdded, setAssetEdited } = formSlice.actions;
export default formSlice.reducer;

import "./App.scss";
import { Routes, Route, useNavigate } from "react-router-dom";
import "../src/styles/pageStyles.scss";
import "../src/styles/BD/commonForms.scss";
import { toast, ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { toastAutocloseAfter } from "./constants/Common";
import { useDispatch, useSelector } from "react-redux";
import { lazy, Suspense, useCallback, useEffect } from "react";
import { setInventoryFormOpen } from "./features/AM/inventoryFormSlice";
import { setStationaryFormOpen } from "./features/AM/stationaryFormSlice";
import StationaryForm from "./components/AmForms/StationaryForm";
import InventoryForm from "./components/AmForms/InventoryForm";
import { setAssetFormOpen } from "./features/AM/assetForm";
import AssetForm from "./components/AmForms/AssetForm";
import ConsumableForm from "./components/AmForms/ConsumableForm";
import { setConsumableFormOpen } from "./features/AM/consumableForm";
import { Box, LinearProgress } from "@mui/material";
import { createInstance, errorHandler } from "./utils/Request/ReqUtils";
import { refreshJwtTokenApi } from "./constants/apis/Login";
import { setUserDetails } from "./features/userSlice";
import ToastMessages from "./constants/ToastMessages";
import ProtectRoute from "./components/ProtectRoute";
import { userRoles } from "./enums/Auth";
import MasterForm from "./components/CM/MasterForm";
import { setMasterFormOpen } from "./features/CM/masterFormSlice";
import AuditForm from "./components/CM/AuditForm";
import { setAuditFormOpen } from "./features/CM/auditFormSlice";
import { setAuditAreaFormOpen } from "./features/CM/auditAreaFormSlice";
import AuditAreaForm from "./components/CM/AuditAreaForm";
import { setComplyAreaFormOpen } from "./features/CM/complyAreaFormSlice";
import ComplyAreaForm from "./components/CM/ComplyAreaForm";
import { setNavigate } from "./utils/Common/navigate";

// Lazy load pages
const AllApps = lazy(() => import("./pages/AllApps/AllApps"));
const BdDashboard = lazy(() => import("./pages/BD/BdDashboard"));
const BdAccountSpecific = lazy(() => import("./pages/BD/BdAccountSpecific/BdAccountSpecific"));
const Login = lazy(() => import("./pages/Login/Login"));
const Account = lazy(() => import("./pages/BD/Accounts"));
const Reports = lazy(() => import("./pages/BD/Reports"));
const Deals = lazy(() => import("./pages/BD/Deals"));
const Contacts = lazy(() => import("./pages/BD/Contacts"));
const Targets = lazy(() => import("./pages/BD/Targets"));
const Tasks = lazy(() => import("./pages/BD/Tasks"));
const ElDashboard = lazy(() => import("./pages/EL/ElDashboard"));
const TsDashboard = lazy(() => import("./pages/TS/TsDashboard/TsDashboard"));
const Tickets = lazy(() => import("./pages/TS/Tickets/Tickets"));
const Course = lazy(() => import("./pages/EL/Course"));
const Assets = lazy(() => import("./pages/AM/Assets"));
const ReportDetails = lazy(() => import("./pages/BD/ReportDetails"));
const CourseAssignment = lazy(() => import("./pages/EL/CourseAssignment/CourseAssignment"));
const ElModuleSpecific = lazy(() => import("./pages/EL/ElModuleSpecific/ElModuleSpecific"));
const MyCourses = lazy(() => import("./pages/EL/MyCourses/MyCourses"));
const MyCertificates = lazy(() => import("./pages/EL/MyCertificates/MyCertificates"));
const CourseLearning = lazy(() => import("./pages/EL/CourseLearning/CourseLearning"));
const Results = lazy(() => import("./pages/EL/Results"));
const Consumables = lazy(() => import("./pages/AM/Consumables"));
const AmDashboard = lazy(() => import("./pages/AM/AmDashboard"));
const TicketReports = lazy(() => import("./pages/TS/TicketReports/TicketReports"));
const AssetReports = lazy(() => import("./pages/AM/AmReports"));
const AmReportDetails = lazy(() => import("./pages/AM/AmReportDetails"));
const PageNotFound = lazy(() => import("./pages/PageNotFound/PageNotFound"));
const ScrollToTop = lazy(() => import("./components/ScrollToTop/ScrollToTop"));
const AssignCourses = lazy(() => import("./pages/EL/AssignCourses"));
const SharedWithMe = lazy(() => import("./pages/DD/SharedWithMe"));
const MyDocs = lazy(() => import("./pages/DD/MyDocs"));
const Trash = lazy(() => import("./pages/DD/Trash"));
const DdDashboard = lazy(() => import("./pages/DD/DdDashboard"));
const Circular = lazy(() => import("./pages/DD/Circular"));
const Users = lazy(() => import("./pages/Settings/Users"));
const Locations = lazy(() => import("./pages/Settings/Locations"));
const Vendors = lazy(() => import("./pages/Settings/Vendors"));
const Groups = lazy(() => import("./pages/Settings/Groups"));
const ManageGroupUsers = lazy(() => import("./pages/Settings/ManageGroupUsers"));
const Inventory = lazy(() => import("./pages/AM/Inventory"));
const Stationary = lazy(() => import("./pages/AM/Stationary"));
const Assignments = lazy(() => import("./pages/AM/Assignments"));
const Requisitions = lazy(() => import("./pages/AM/Requisitions"));
const Master = lazy(() => import("./pages/CM/Master"));
const Audit = lazy(() => import("./pages/CM/Audit"));
const AuditAreas = lazy(() => import("./pages/CM/AuditAreas"));
const Comply = lazy(() => import("./pages/CM/Comply"));
const ComplyAreas = lazy(() => import("./pages/CM/ComplyAreas"));

//routes are defined below
function App() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const inventoryFormOpen = useSelector((state) => state.inventoryForm.inventoryFormOpen);
    const stationaryFormOpen = useSelector((state) => state.stationaryForm.stationaryFormOpen);
    const assetFormOpen = useSelector((state) => state.assetForm.assetFormOpen);
    const consumableFormOpen = useSelector((state) => state.consumableForm.consumableFormOpen);
    const masterFormOpen = useSelector((state) => state.masterForm.masterFormOpen);
    const auditFormOpen = useSelector((state) => state.auditForm.auditFormOpen);
    const auditAreaFormOpen = useSelector((state) => state.auditAreaForm.auditAreaFormOpen);
    const complyAreaFormOpen = useSelector((state) => state.complyAreaForm.complyAreaFormOpen);
    const user_id = useSelector((state) => state.userDetails.user_id);
    const jwtToken = localStorage.getItem("jwt_token");

    const inventoryFormClose = useCallback(() => {
        dispatch(setInventoryFormOpen(false));
    }, [dispatch]);

    const stationaryFormClose = useCallback(() => {
        dispatch(setStationaryFormOpen(false));
    }, [dispatch]);

    const assetFormClose = useCallback(() => {
        dispatch(setAssetFormOpen(false));
    }, [dispatch]);

    const consumableFormClose = useCallback(() => {
        dispatch(setConsumableFormOpen(false));
    }, [dispatch]);

    const masterFormClose = useCallback(() => {
        dispatch(setMasterFormOpen(false));
    }, [dispatch]);

    const auditFormClose = useCallback(() => {
        dispatch(setAuditFormOpen(false));
    }, [dispatch]);

    const auditAreaFormClose = useCallback(() => {
        dispatch(setAuditAreaFormOpen(false));
    }, [dispatch]);

    const complyAreaFormClose = useCallback(() => {
        dispatch(setComplyAreaFormOpen(false));
    }, [dispatch]);

    const refreshJwtToken = async () => {
        try {
            const axiosInstance = createInstance(true);
            const response = await axiosInstance.post(refreshJwtTokenApi);
            if (response.status === 200) {
                const userData = response?.data?.data;
                localStorage.setItem("jwt_token", userData.token);

                const userDataCopy = JSON.parse(JSON.stringify(userData));
                delete userDataCopy?.token;
                dispatch(setUserDetails(userDataCopy));
            } else {
                toast.error(ToastMessages.pageRefreshFailure);
            }
        } catch (err) {
            errorHandler(err, ToastMessages.pageRefreshFailure);
        }
    };

    // if reloaded the pages then refresh the token and get details
    useEffect(() => {
        if (!user_id && jwtToken) {
            refreshJwtToken();
        }
    }, [user_id]);

    useEffect(() => {
        setNavigate(navigate);
    }, [navigate]);
    return (
        <div className="App">
            <Suspense
                fallback={
                    <Box display={"flex"} justifyContent={"center"} alignItems={"center"} height="100vh" width={"100vw"}>
                        <Box width={"20%"}>
                            <LinearProgress />
                        </Box>
                    </Box>
                }
            >
                <ScrollToTop />
                <ToastContainer
                    position="top-center"
                    transition={Zoom}
                    autoClose={toastAutocloseAfter}
                    hideProgressBar={true}
                    theme="colored"
                    draggable={false}
                />
                <Routes>
                    {/* login page accessible to everyone */}
                    <Route path="">
                        <Route exact path="" element={<Login />} />
                        <Route exact path="login" element={<Login />} />
                        <Route />
                    </Route>

                    {/*  all apps, business development, e learning, asset management, digital documents, setting routes */}
                    <Route element={<ProtectRoute allowedRoles={[userRoles.SuperAdmin, userRoles.Admin, userRoles.User]} />}>
                        <Route path="">
                            <Route path="allapps" element={<AllApps />} />
                        </Route>
                        {/* Business development routes */}
                        <Route path="bd">
                            <Route path="" element={<BdDashboard />} />
                            <Route path="dashboard" element={<BdDashboard />} />
                            <Route path="accounts" element={<Account />} />
                            <Route path="account/:accountId" element={<BdAccountSpecific />} />
                            <Route path="reports" element={<Reports />} />
                            <Route path="report-details/:reportId" element={<ReportDetails />} />
                            <Route path="deals" element={<Deals />} />
                            <Route path="contacts" element={<Contacts />} />
                            <Route path="tasks" element={<Tasks />} />
                            <Route path="targets" element={<Targets />} />
                        </Route>

                        {/* E Leaning routes */}
                        <Route path="el">
                            {/* admin and super admin allowed routes */}
                            <Route element={<ProtectRoute allowedRoles={[userRoles.SuperAdmin, userRoles.Admin]} />}>
                                <Route path="" element={<ElDashboard />} />
                                <Route path="dashboard" element={<ElDashboard />} />
                                <Route path="course" element={<Course />} />
                                <Route path="results" element={<Results />} />

                                <Route path="module/:moduleId" element={<ElModuleSpecific />} />
                            </Route>

                            {/* only super admin allowed routes*/}
                            <Route element={<ProtectRoute allowedRoles={[userRoles.SuperAdmin]} />}>
                                <Route path="course-assignment/:courseId" element={<CourseAssignment />} />

                                <Route path="assign-courses" element={<AssignCourses />} />
                            </Route>

                            <Route path="courselearning/:courseId" element={<CourseLearning />} />

                            <Route path="my-courses" element={<MyCourses />} />
                            <Route path="my-certificates" element={<MyCertificates />} />
                        </Route>

                        {/* Asset Management routes */}
                        <Route path="am">
                            {/* admin and super admin allowed routes */}
                            <Route element={<ProtectRoute allowedRoles={[userRoles.SuperAdmin, userRoles.Admin]} />}>
                                <Route path="" element={<AmDashboard />} />
                                <Route path="dashboard" element={<AmDashboard />} />
                                <Route path="assets" element={<Assets />} />
                                <Route path="consumables" element={<Consumables />} />
                                <Route path="inventory" element={<Inventory />} />
                                <Route path="stationary" element={<Stationary />} />
                                <Route path="reports" element={<AssetReports />} />
                                <Route path="report-details/:reportId" element={<AmReportDetails />} />
                                <Route path="assignments" element={<Assignments />} />
                                <Route path="requisitions" element={<Requisitions />} />
                            </Route>

                            {/* only user allowed routes */}
                            <Route element={<ProtectRoute allowedRoles={[userRoles.User]} />}>
                                <Route path="my-assets" element={<Assignments />} />
                                <Route path="my-consumables" element={<Assignments />} />
                                <Route path="my-stationary" element={<Assignments />} />
                                <Route path="my-requests" element={<Requisitions />} />
                            </Route>
                        </Route>

                        {/* Digital Documents Routes */}
                        <Route path="dd">
                            <Route element={<ProtectRoute allowedRoles={[userRoles.SuperAdmin, userRoles.Admin]} />}>
                                <Route path="dashboard/:accountId?" element={<DdDashboard />} />
                            </Route>
                            <Route path="" element={<MyDocs />} />
                            <Route path="my-docs" element={<MyDocs />} />
                            <Route path="shared-with-me" element={<SharedWithMe />} />
                            <Route path="trash" element={<Trash />} />

                            <Route path="circulars" element={<Circular />} />
                        </Route>

                        {/* setting routes*/}
                        {/* admin and super admin allowed routes */}
                        <Route element={<ProtectRoute allowedRoles={[userRoles.SuperAdmin, userRoles.Admin]} />}>
                            <Route path="settings">
                                <Route path="" element={<Users />} />
                                <Route path="users" element={<Users />} />
                                <Route path="locations" element={<Locations />} />
                                <Route path="vendors" element={<Vendors />} />
                                <Route path="groups" element={<Groups />} />
                                <Route path="group/:groupId" element={<ManageGroupUsers />} />
                            </Route>
                        </Route>
                    </Route>

                    {/* ticketing system routes are allowed for all roles */}
                    <Route
                        element={
                            <ProtectRoute
                                allowedRoles={[userRoles.SuperAdmin, userRoles.Admin, userRoles.User, userRoles.Vendor, userRoles.ServiceProvider]}
                            />
                        }
                    >
                        <Route path="ts">
                            <Route path="" element={<TsDashboard />} />
                            <Route path="dashboard" element={<TsDashboard />} />
                            <Route path="tickets" element={<Tickets />} />
                            <Route path="reports" element={<TicketReports />} />
                        </Route>

                        {/* Compliance Management routes */}
                        <Route path="cm">
                            <Route path="master" element={<Master />} />
                            <Route path="audit" element={<Audit />} />
                            <Route path="audit/:auditId" element={<AuditAreas />} />
                            <Route path="comply" element={<Comply />} />
                            <Route path="comply/:complyId" element={<ComplyAreas />} />
                        </Route>
                    </Route>
                    <Route path="*" element={<PageNotFound />} />
                </Routes>
            </Suspense>
            {inventoryFormOpen && <InventoryForm open={inventoryFormOpen} onClose={inventoryFormClose} />}
            {stationaryFormOpen && <StationaryForm open={stationaryFormOpen} onClose={stationaryFormClose} />}
            {assetFormOpen && <AssetForm open={assetFormOpen} onClose={assetFormClose} />}
            {consumableFormOpen && <ConsumableForm open={consumableFormOpen} onClose={consumableFormClose} />}
            {masterFormOpen && <MasterForm open={masterFormOpen} onClose={masterFormClose} />}
            {auditFormOpen && <AuditForm open={auditFormOpen} onClose={auditFormClose} />}
            {auditAreaFormOpen && <AuditAreaForm open={auditAreaFormOpen} onClose={auditAreaFormClose} />}
            {complyAreaFormOpen && <ComplyAreaForm open={complyAreaFormOpen} onClose={complyAreaFormClose} />}
        </div>
    );
}

export default App;

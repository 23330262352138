const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    masterFormOpen: false,
    masterId: null,
    masterAdded: false,
    masterEdited: false,
};

export const formSlice = createSlice({
    name: "masterForm",
    initialState,
    reducers: {
        setMasterFormOpen: (state, action) => {
            state.masterFormOpen = action.payload;
        },

        setMasterId: (state, action) => {
            state.masterId = action.payload;
        },

        setMasterAdded: (state, action) => {
            state.masterAdded = action.payload;
        },
        setMasterEdited: (state, action) => {
            state.masterEdited = action.payload;
        },
    },
});
export const { setMasterFormOpen, setMasterId, setMasterAdded, setMasterEdited } = formSlice.actions;
export default formSlice.reducer;

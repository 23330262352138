const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    notifications: [],
    notificationsFetched: false,
};

export const formSlice = createSlice({
    name: "notification",
    initialState,
    reducers: {
        setNotifications: (state, action) => {
            state.notifications = action.payload;
        },
        setNotificationsFetched: (state, action) => {
            state.notificationsFetched = action.payload;
        },
    },
});

export const { setNotifications, setNotificationsFetched } = formSlice.actions;

export default formSlice.reducer;

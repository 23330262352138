import React, { useEffect, useState } from "react";
import Drawer from "@mui/material/Drawer";
import {
    Autocomplete,
    Box,
    CircularProgress,
    debounce,
    FormControl,
    InputLabel,
    MenuItem,
    Paper,
    Popper,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { createInstance, errorHandler } from "../../utils/Request/ReqUtils";
import { toast } from "react-toastify";
import ToastMessages from "../../constants/ToastMessages";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { inventoryTypes } from "../../enums/AM/Inventory";
import { inventorySchema } from "../../validations/AmForms/Inventory";
import { setInventoryAdded, setInventoryEdited, setInventoryId } from "../../features/AM/inventoryFormSlice";
import { inventoryApi } from "../../constants/apis/AM/Inventory";
import {
    setAssetCategoryOptions,
    setAssetCategoryOptionsLoaded,
    setConsumableCategoryOptions,
    setConsumableCategoryOptionsLoaded,
    setStationaryCategoryOptions,
    setStationaryCategoryOptionsLoaded,
} from "../../features/optionSlice";
import { createSearchParams } from "react-router-dom";
import { fetchInventoryCategories, fetchItemNames } from "../../utils/Common";
import { formAutoCompleteLabelBoxStyles, searchOptionDebounceTime } from "../../constants/Common";

const InventoryForm = ({ open, onClose }) => {
    const dispatch = useDispatch();
    const axiosInstance = createInstance(true);
    const initialInventoryData = {
        type: "",
        category: "",
        group_code: "",
        item_code: "",
        name: "",
    };

    const [inventoryData, setInventoryData] = useState(initialInventoryData);
    const [inventoryFormErrors, setInventoryFormErrors] = useState({});
    const [fetchingInventoryData, setFetchingInventoryData] = useState(false);
    const [fetchedInventoryCategory, setFetchedInventoryCategory] = useState("");

    const inventoryId = useSelector((state) => state.inventoryForm.inventoryId);
    const stationaryCategoryOptions = useSelector((state) => state.optionsForAsyncSelect.stationaryCategoryOptions);
    const stationaryCategoryOptionsLoaded = useSelector((state) => state.optionsForAsyncSelect.stationaryCategoryOptionsLoaded);
    const assetCategoryOptions = useSelector((state) => state.optionsForAsyncSelect.assetCategoryOptions);
    const assetCategoryOptionsLoaded = useSelector((state) => state.optionsForAsyncSelect.assetCategoryOptionsLoaded);
    const consumableCategoryOptions = useSelector((state) => state.optionsForAsyncSelect.consumableCategoryOptions);
    const consumableCategoryOptionsLoaded = useSelector((state) => state.optionsForAsyncSelect.consumableCategoryOptionsLoaded);

    // used for not calling the apis of autocomplete option when initialising the data in form while edit
    const initialInitialize = {
        initialize_category: true,
        initialize_name: true,
    };

    const initialAutocompleteFieldsStatus = {
        category: false,
        name: false,
        ...(inventoryId && { ...initialInitialize }),
    };

    const fieldNameMappings = {
        category: "category",
        name: "name",
    };

    const [autocompleteFieldsStatus, setAutocompleteFieldsStatus] = useState(initialAutocompleteFieldsStatus);
    const [autocompleteloading, setAutocompleteLoading] = useState(false);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [nameOptions, setNameOptions] = useState([]);

    const debouncedFetchCategories = debounce(async (newInputValue) => {
        setAutocompleteLoading(true);

        try {
            setInventoryFormErrors({ ...inventoryFormErrors, category: "" });

            const params = createSearchParams({
                search: newInputValue || "",
                source: inventoryData.type.toLowerCase(),
            });
            const fetchedOptions = await fetchInventoryCategories(params);

            switch (inventoryData.type) {
                case inventoryTypes.Asset:
                    if (!assetCategoryOptionsLoaded && fetchedOptions && !newInputValue) {
                        dispatch(setAssetCategoryOptionsLoaded(true));
                        dispatch(setAssetCategoryOptions(fetchedOptions));
                    }
                    break;

                case inventoryTypes.Consumable:
                    if (!consumableCategoryOptionsLoaded && fetchedOptions && !newInputValue) {
                        dispatch(setConsumableCategoryOptionsLoaded(true));
                        dispatch(setConsumableCategoryOptions(fetchedOptions));
                    }
                    break;

                case inventoryTypes.Stationary:
                    if (!stationaryCategoryOptionsLoaded && fetchedOptions && !newInputValue) {
                        dispatch(setStationaryCategoryOptionsLoaded(true));
                        dispatch(setStationaryCategoryOptions(fetchedOptions));
                    }
                    break;

                default:
                    break;
            }

            // fetched option might not contain the selected options so adding that to avoid warnings of option value not exists
            const mergedOptions = [
                ...(fetchedOptions || []),
                ...categoryOptions.filter((selectedOption) => !fetchedOptions.some((option) => option?.value === selectedOption?.value)),
            ];

            // if the options not contains it then add it as new category and remove all the item name options if already fetched
            const option = categoryOptions.find((option) => option?.value.toLowerCase() === newInputValue.toLowerCase());

            if (!option) {
                setInventoryData({
                    ...inventoryData,
                    newCategory: { value: newInputValue, label: newInputValue },
                    category: null,
                    name: null,
                });
            } else {
                setInventoryData({
                    ...inventoryData,
                    category: { value: option?.value, label: option?.value },
                    newCategory: null,
                    name: null,
                });
            }

            setCategoryOptions(mergedOptions);
            return fetchedOptions;
        } catch (error) {
        } finally {
            setAutocompleteLoading(false);
        }
    }, searchOptionDebounceTime);

    const debouncedFetchItemNames = debounce(async (newInputValue) => {
        setAutocompleteLoading(true);

        try {
            setInventoryFormErrors({ ...inventoryFormErrors, name: "" });
            let params = {
                search: newInputValue || "",
                source: inventoryData.type.toLowerCase(),
                category: inventoryData?.category?.value,
            };
            if (inventoryData.type === inventoryTypes.Stationary) {
                params.group_code = inventoryData.group_code;
                params.item_code = inventoryData.item_code;
            }

            if (inventoryData.type === inventoryTypes.Stationary && !inventoryData.group_code && !inventoryData.item_code) {
                return;
            }
            params = createSearchParams(params);
            const fetchedOptions = await fetchItemNames(params);

            // fetched option might not contain the selected options so adding that to avoid warnings of option value not exists
            const mergedOptions = [
                ...(fetchedOptions || []),
                ...nameOptions.filter((selectedOption) => !fetchedOptions.some((option) => option?.value === selectedOption?.value)),
            ];

            setInventoryData({ ...inventoryData, newName: { value: newInputValue, label: newInputValue }, name: null });
            setInventoryFormErrors({ ...inventoryFormErrors, name: "" });
            setNameOptions(mergedOptions);

            return fetchedOptions;
        } catch (error) {
        } finally {
            setAutocompleteLoading(false);
        }
    }, searchOptionDebounceTime);

    const isCategoryNotLoaded = () => {
        return (
            (inventoryData.type === inventoryTypes.Asset && !assetCategoryOptionsLoaded) ||
            (inventoryData.type === inventoryTypes.Consumable && !consumableCategoryOptionsLoaded) ||
            (inventoryData.type === inventoryTypes.Stationary && !stationaryCategoryOptionsLoaded)
        );
    };

    // functions for autocomplete field open to fetch data
    const handleCategoryFieldOpen = async (e) => {
        const value = e.target.value;
        setAutocompleteFieldsStatus({ ...autocompleteFieldsStatus, category: true, initialize_category: false });
        if (!inventoryData.type) {
            return;
        } else if (isCategoryNotLoaded()) {
            debouncedFetchCategories(value);
        } else {
            // options category might not contain the selected options so adding that to avoid warnings of option value not exists
            let mergedOptions;
            switch (inventoryData.type) {
                case inventoryTypes.Asset:
                    mergedOptions = [
                        ...(assetCategoryOptions || []),
                        ...categoryOptions.filter(
                            (selectedOption) => !assetCategoryOptions.some((option) => option?.value === selectedOption?.value)
                        ),
                    ];
                    break;

                case inventoryTypes.Consumable:
                    mergedOptions = [
                        ...(consumableCategoryOptions || []),
                        ...categoryOptions.filter(
                            (selectedOption) => !consumableCategoryOptions.some((option) => option?.value === selectedOption?.value)
                        ),
                    ];
                    break;

                case inventoryTypes.Stationary:
                    mergedOptions = [
                        ...(stationaryCategoryOptions || []),
                        ...categoryOptions.filter(
                            (selectedOption) => !stationaryCategoryOptions.some((option) => option?.value === selectedOption?.value)
                        ),
                    ];
                    break;
                default:
                    break;
            }

            setCategoryOptions(mergedOptions);
        }
    };

    const handleNameFieldOpen = async (e) => {
        const value = e.target.value;
        setAutocompleteFieldsStatus({ ...autocompleteFieldsStatus, name: true, initialize_name: false });
        if (!inventoryData?.newCategory && inventoryData.category && !nameOptions.length) {
            debouncedFetchItemNames(value);
        }
    };

    // close autocomplete
    const closeAutocomplete = (fieldName) => {
        setAutocompleteFieldsStatus({ ...autocompleteFieldsStatus, [fieldName]: false });
    };

    // functions to handle the search in autocomplete
    const handleCategoryInputChange = async (e, value) => {
        if (inventoryData.type && value && !autocompleteFieldsStatus.initialize_category) {
            debouncedFetchCategories(value);
        } else if (!value) {
            setInventoryData({ ...inventoryData, newCategory: null, category: null, name: null });
        }
    };

    const handleNameInputChange = async (e, value) => {
        if (inventoryData.type && !inventoryData?.newCategory && inventoryData?.category && value && !autocompleteFieldsStatus.initialize_name) {
            await debouncedFetchItemNames(value);
        }
    };

    const handleSelectedOptionsChange = (e, option, fieldName) => {
        if (option) {
            if (fieldName === fieldNameMappings?.category) {
                setInventoryData({ ...inventoryData, category: option || null, newCategory: null, group_code: null, item_code: null, name: null });
            } else if (fieldName === fieldNameMappings?.name) setInventoryData({ ...inventoryData, [fieldName]: option || null, newName: null });
            setInventoryFormErrors({ ...inventoryFormErrors, [fieldName]: "" });
        } else {
            if (fieldName === fieldNameMappings?.category) {
                setInventoryData({ ...inventoryData, category: null, newCategory: null, group_code: null, item_code: null, name: null });
                setNameOptions([]);
            } else if (fieldName === fieldNameMappings?.name) {
                setInventoryData({ ...inventoryData, name: null, newName: null });
            }
        }
    };

    const fetchInventoryData = async (id) => {
        setFetchingInventoryData(true);
        try {
            const result = await axiosInstance.get(inventoryApi + "/" + id);
            if (result.status === 200) {
                const fetchedInventoryData = result?.data?.data[0];
                for (let key in initialInventoryData) {
                    if (key === "category" || key === "name") {
                        if (key === "name") {
                            fetchedInventoryData.newName = { label: fetchedInventoryData[key], value: fetchedInventoryData[key] };
                        }
                        fetchedInventoryData[key] = { label: fetchedInventoryData[key], value: fetchedInventoryData[key] };
                    } else fetchedInventoryData[key] = fetchedInventoryData[key] || "";
                }
                setFetchedInventoryCategory(fetchedInventoryData?.category);
                setInventoryData(fetchedInventoryData);
            } else {
                toast.error(ToastMessages.failedToFetchInventory);
            }
        } catch (err) {
            errorHandler(err, ToastMessages.failedToFetchInventory);
        }
        setFetchingInventoryData(false);
    };

    const createValidationPayload = () => {
        const validationPayload = JSON.parse(JSON.stringify(inventoryData));
        if (validationPayload?.newCategory) {
            validationPayload.category = validationPayload?.newCategory?.value;
        } else validationPayload.category = validationPayload.category?.value;

        validationPayload.name = validationPayload?.newName?.value;

        delete validationPayload?.newCategory;
        delete validationPayload?.newName;

        return validationPayload;
    };

    const handleInventoryEdit = async (id) => {
        const validationPayload = createValidationPayload();
        const validation = inventorySchema.validate(validationPayload, { abortEarly: false });
        if (validation.error) {
            setInventoryFormErrors({});
            const errorDetails = validation.error.details;
            let currentErrors = {};
            errorDetails.forEach((error) => {
                currentErrors[error.path[0]] = error.message;
            });
            setInventoryFormErrors(currentErrors);
        } else {
            try {
                const result = await axiosInstance.put(inventoryApi + "/" + id, validationPayload);
                if (result.status === 200) {
                    toast.success(ToastMessages.inventoryEditSuccess);
                    handleInventoryFormClose();
                    dispatch(setInventoryEdited(true));
                    if (fetchedInventoryCategory && fetchedInventoryCategory !== validationPayload.category) {
                        updateRespectiveCategoryOptions(fetchedInventoryCategory, validationPayload?.type, validationPayload?.category);
                    }
                } else {
                    toast.error(ToastMessages.failedToEditInventory);
                }
            } catch (err) {
                errorHandler(err, ToastMessages.failedToEditInventory);
            }
        }
    };

    const isInventoryNameExists = () => {
        const index = nameOptions.findIndex((option) => option?.value.toLowerCase() === inventoryData?.newName?.value.toLowerCase());
        if (index !== -1) {
            setInventoryFormErrors({ ...inventoryFormErrors, name: "Inventory name already exists" });
            return true;
        }
        return false;
    };

    const handleInventorySubmit = async () => {
        const validationPayload = createValidationPayload();
        const validation = inventorySchema.validate(validationPayload, { abortEarly: false });
        if (validation.error) {
            setInventoryFormErrors({});
            const errorDetails = validation.error.details;
            let currentErrors = {};
            errorDetails.forEach((error) => {
                currentErrors[error.path[0]] = error.message;
            });
            setInventoryFormErrors(currentErrors);
        } else if (!isInventoryNameExists()) {
            try {
                const result = await axiosInstance.post(inventoryApi, validationPayload);
                if (result.status === 200) {
                    toast.success(ToastMessages.addInventorySuccess);
                    addRespectiveCategoryOptions(validationPayload?.type, validationPayload?.category);
                    handleInventoryFormClose();
                    dispatch(setInventoryAdded(true));
                } else {
                    toast.error(ToastMessages.failedToAddInventory);
                }
            } catch (err) {
                errorHandler(err, ToastMessages.failedToAddInventory);
            }
        }
    };

    // to add in the autocomplete options
    const addRespectiveCategoryOptions = (type, category) => {
        switch (type) {
            case inventoryTypes.Asset:
                if (assetCategoryOptionsLoaded) {
                    const newAssetCategoryOptions = JSON.parse(JSON.stringify(assetCategoryOptions));
                    const index = newAssetCategoryOptions.findIndex((assetCategory) => assetCategory?.value === category);
                    if (index === -1) {
                        newAssetCategoryOptions.push({ value: category, label: category });
                        dispatch(setAssetCategoryOptions(newAssetCategoryOptions));
                    }
                }
                break;
            case inventoryTypes.Consumable:
                if (consumableCategoryOptionsLoaded) {
                    const newConsumableCategoryOptions = JSON.parse(JSON.stringify(consumableCategoryOptions));
                    const index = newConsumableCategoryOptions.findIndex((consumableCategory) => consumableCategory?.value === category);
                    if (index === -1) {
                        newConsumableCategoryOptions.push({ value: category, label: category });
                        dispatch(setConsumableCategoryOptions(newConsumableCategoryOptions));
                    }
                }
                break;
            case inventoryTypes.Stationary:
                if (stationaryCategoryOptionsLoaded) {
                    const newStationaryCategoryOptions = JSON.parse(JSON.stringify(stationaryCategoryOptions));
                    const index = newStationaryCategoryOptions.findIndex((stationaryCategory) => stationaryCategory?.value === category);
                    if (index === -1) {
                        newStationaryCategoryOptions.push({ value: category, label: category });
                        dispatch(setStationaryCategoryOptions(newStationaryCategoryOptions));
                    }
                }
                break;

            default:
                break;
        }
    };

    // to update the auto complete options
    const updateRespectiveCategoryOptions = (prevCategory, type, currCategory) => {
        switch (type) {
            case inventoryTypes.Asset:
                if (assetCategoryOptionsLoaded) {
                    const updatedAssetCategoryOptions = JSON.parse(JSON.stringify(assetCategoryOptions));
                    const assetCategory = updatedAssetCategoryOptions.find((assetCategory) => assetCategory?.value === prevCategory);
                    if (assetCategory) {
                        assetCategory.label = currCategory;
                        assetCategory.value = currCategory;
                    }
                    dispatch(setAssetCategoryOptions(updatedAssetCategoryOptions));
                }
                break;
            case inventoryTypes.Consumable:
                if (consumableCategoryOptionsLoaded) {
                    const updatedConsumableCategoryOptions = JSON.parse(JSON.stringify(consumableCategoryOptions));
                    const consumableCategory = updatedConsumableCategoryOptions.find(
                        (consumableCategory) => consumableCategory?.value === prevCategory
                    );
                    if (consumableCategory) {
                        consumableCategory.label = currCategory;
                        consumableCategory.value = currCategory;
                    }
                    dispatch(setConsumableCategoryOptions(updatedConsumableCategoryOptions));
                }
                break;
            case inventoryTypes.Stationary:
                if (stationaryCategoryOptionsLoaded) {
                    const updatedStationaryCategoryOptions = JSON.parse(JSON.stringify(stationaryCategoryOptions));
                    const stationaryCategory = updatedStationaryCategoryOptions.find(
                        (stationaryCategory) => stationaryCategory?.value === prevCategory
                    );
                    if (stationaryCategory) {
                        stationaryCategory.label = currCategory;
                        stationaryCategory.value = currCategory;
                    }
                    dispatch(setStationaryCategoryOptions(updatedStationaryCategoryOptions));
                }
                break;

            default:
                break;
        }
    };

    const handleInventoryForm = (e) => {
        setInventoryFormErrors({ ...inventoryFormErrors, [e.target.name]: "" });
        setInventoryData({ ...inventoryData, [e.target.name]: e.target.value });
    };

    const handleInventoryFormClose = () => {
        onClose();
        setInventoryFormErrors({});
        setInventoryData(initialInventoryData);
        setFetchedInventoryCategory("");
        dispatch(setInventoryId(null));
    };

    useEffect(() => {
        if (inventoryId) {
            fetchInventoryData(inventoryId);
        }
    }, []);

    useEffect(() => {
        setCategoryOptions([]);
        setNameOptions([]);
        setInventoryFormErrors({});
        if (!inventoryId) {
            setInventoryData({ ...initialInventoryData, type: inventoryData.type });
        }
    }, [inventoryData.type]);

    useEffect(() => {
        setNameOptions([]);
    }, [inventoryData.category, inventoryData.group_code, inventoryData.item_code]);

    return (
        <div>
            <Drawer
                sx={{
                    "& .MuiDrawer-paper": {
                        width: {
                            xs: "90%",
                            lg: 1000,
                        },
                    },
                }}
                anchor={"right"}
                open={open}
                onClose={handleInventoryFormClose}
            >
                <div className="add-form-base">
                    <div className="add-form-header">
                        <h5 className="add-form-title">{inventoryId ? "Edit" : "New"} Inventory</h5>
                        <button type="button" className="add-form-close" onClick={onClose}></button>
                    </div>
                    <div className="content-separator"></div>
                    {fetchingInventoryData ? (
                        <LoadingSpinner />
                    ) : (
                        <div className="add-form-scroll-container">
                            <div className="add-form-content">
                                <div className="row-type-2">
                                    <div className="group-1">
                                        <InputLabel className="row-type-2-label">
                                            Inventory Type
                                            <span className="required-field-asterisk"> *</span>
                                        </InputLabel>
                                        <FormControl className="form-control-for-field" error={Boolean(inventoryFormErrors?.type)}>
                                            <Select
                                                disabled={inventoryId !== null}
                                                name="type"
                                                onChange={handleInventoryForm}
                                                value={inventoryData?.type}
                                                className={`select-value ${inventoryData?.type === "" ? "not-selected" : ""}`}
                                                size="small"
                                                displayEmpty
                                                inputProps={{ "aria-label": "Without label" }}
                                                MenuProps={{
                                                    disablePortal: true,
                                                }}
                                            >
                                                <MenuItem className="select-menu-item" value="">
                                                    Select Type
                                                </MenuItem>
                                                {Object.keys(inventoryTypes).map((key) => (
                                                    <MenuItem className="select-menu-item" key={key} value={inventoryTypes[key]}>
                                                        {inventoryTypes[key]}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <p className="form-error">{inventoryFormErrors?.type || ""}</p>
                                        </FormControl>
                                    </div>
                                    <div className="group-2" style={{ alignItems: "normal" }}>
                                        <Box className="row-type-2-label" sx={formAutoCompleteLabelBoxStyles}>
                                            <InputLabel>
                                                Category <span className="required-field-asterisk"> *</span>
                                            </InputLabel>
                                        </Box>

                                        <FormControl className="form-control-for-field">
                                            <Autocomplete
                                                freeSolo
                                                size="small"
                                                value={inventoryData.category}
                                                open={autocompleteFieldsStatus?.category}
                                                onOpen={handleCategoryFieldOpen}
                                                onClose={() => {
                                                    closeAutocomplete(fieldNameMappings?.category);
                                                }}
                                                onChange={(e, option) => {
                                                    handleSelectedOptionsChange(e, option, fieldNameMappings?.category);
                                                }}
                                                onInputChange={handleCategoryInputChange}
                                                loading={autocompleteloading}
                                                options={categoryOptions}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        placeholder="Select or add new category"
                                                        size="small"
                                                        className="input-value"
                                                        error={Boolean(inventoryFormErrors?.category)}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {autocompleteloading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                            <p className="form-error">{inventoryFormErrors?.category || ""}</p>
                                        </FormControl>
                                    </div>
                                </div>

                                {inventoryData?.type === inventoryTypes.Stationary && (
                                    <div className="row-type-2">
                                        <div className="group-1">
                                            <InputLabel className="row-type-2-label">
                                                Group Code <span className="required-field-asterisk"> *</span>
                                            </InputLabel>
                                            <FormControl className="form-control-for-field">
                                                <TextField
                                                    type="text"
                                                    name="group_code"
                                                    placeholder="Group Code"
                                                    value={inventoryData?.group_code}
                                                    onChange={handleInventoryForm}
                                                    className="input-value"
                                                    size="small"
                                                    error={Boolean(inventoryFormErrors?.group_code)}
                                                />
                                                <p className="form-error">{inventoryFormErrors?.group_code || ""}</p>
                                            </FormControl>
                                        </div>
                                        <div className="group-2">
                                            <InputLabel className="row-type-2-label">
                                                Item Code <span className="required-field-asterisk"> *</span>
                                            </InputLabel>
                                            <FormControl className="form-control-for-field">
                                                <TextField
                                                    type="text"
                                                    name="item_code"
                                                    placeholder="Item Code"
                                                    value={inventoryData?.item_code}
                                                    onChange={handleInventoryForm}
                                                    className="input-value"
                                                    size="small"
                                                    error={Boolean(inventoryFormErrors?.item_code)}
                                                />
                                                <p className="form-error">{inventoryFormErrors?.item_code || ""}</p>
                                            </FormControl>
                                        </div>
                                    </div>
                                )}
                                <div className="row-type-1">
                                    <InputLabel className="row-type-1-label">
                                        Inventory Name <span className="required-field-asterisk"> *</span>
                                    </InputLabel>

                                    <FormControl className="form-control-for-field">
                                        <Autocomplete
                                            freeSolo
                                            size="small"
                                            value={inventoryData.name}
                                            open={autocompleteFieldsStatus?.name}
                                            onOpen={handleNameFieldOpen}
                                            onClose={() => {
                                                closeAutocomplete(fieldNameMappings?.name);
                                            }}
                                            onChange={(e, option) => {
                                                handleSelectedOptionsChange(e, option, fieldNameMappings?.name);
                                            }}
                                            onInputChange={handleNameInputChange}
                                            loading={autocompleteloading}
                                            options={nameOptions}
                                            getOptionDisabled={() => true}
                                            PopperComponent={(props) => (
                                                <Popper {...props} placement="bottom-start">
                                                    <Paper {...props.PopperProps}>
                                                        <Box
                                                            sx={{
                                                                padding: "8px",
                                                                backgroundColor: "#f5f5f5",
                                                            }}
                                                        >
                                                            <Typography variant="body2" color="textSecondary">
                                                                Already existing inventory names with selected category
                                                            </Typography>
                                                        </Box>
                                                        {props.children}
                                                    </Paper>
                                                </Popper>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    placeholder="Inventory name"
                                                    size="small"
                                                    className="input-value"
                                                    error={Boolean(inventoryFormErrors?.name)}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <React.Fragment>
                                                                {autocompleteloading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                {params.InputProps.endAdornment}
                                                            </React.Fragment>
                                                        ),
                                                    }}
                                                />
                                            )}
                                        />
                                        <p className="form-error">{inventoryFormErrors?.name || ""}</p>
                                    </FormControl>
                                </div>

                                <div className="row-type-1">
                                    <InputLabel className="row-type-1-label"></InputLabel>
                                    <div className="form-control-for-field mandatory-field-row">
                                        <span className="required-field-asterisk">* </span>
                                        <span className="mandatory-field-text">Mandatory field</span>
                                    </div>
                                </div>

                                <div className="bottom-container">
                                    <div className="content-separator"></div>
                                    <div className="action-buttons">
                                        <button
                                            className="save-button"
                                            onClick={() => {
                                                if (inventoryId) handleInventoryEdit(inventoryId);
                                                else handleInventorySubmit();
                                            }}
                                        >
                                            Save
                                        </button>

                                        <button className="cancel-button" onClick={handleInventoryFormClose}>
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </Drawer>
        </div>
    );
};

export default React.memo(InventoryForm);

const userRoles = Object.freeze({
    SuperAdmin: 1,
    Admin: 2,
    User: 3,
    Vendor: 4,
    ServiceProvider: 5,
});

const userRoleIdAndRoleMapping = Object.freeze({
    1: "Super Admin",
    2: "Admin",
    3: "User",
    4: "Vendor",
    5: "Service Provider",
});

const activeStatus = Object.freeze({
    true: "Active",
    false: "Inactive",
});

export { userRoles, userRoleIdAndRoleMapping, activeStatus };

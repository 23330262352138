const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    courseDetailsEdit: false,
    courseDetailsEditId: null,
    courseDetailsEdited: false,
    courseDetailsEditOpenToggle: false,
}

export const formSlice = createSlice({
    name: "courseDetailsForm",
    initialState,
    reducers: {
        setCourseDetailsEdit: (state, action) => {
            state.courseDetailsEdit = action.payload;
        },

        setCourseDetailsEditId: (state, action) => {
            state.courseDetailsEditId = action.payload;
        },

        setCourseDetailsEdited: (state, action) => {
            state.courseDetailsEdited = action.payload;
        },

        setCourseDetailsEditOpenToggle: (state, action) => {
            state.courseDetailsEditOpenToggle = !state.courseDetailsEditOpenToggle;
        }
    }
});

export const {setCourseDetailsEdit, setCourseDetailsEditId, setCourseDetailsEdited, setCourseDetailsEditOpenToggle} = formSlice.actions;

export default formSlice.reducer;
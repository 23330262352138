const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    auditFormOpen: false,
    auditId: null,
    auditAdded: false,
    auditEdited: false,
};

export const formSlice = createSlice({
    name: "auditForm",
    initialState,
    reducers: {
        setAuditFormOpen: (state, action) => {
            state.auditFormOpen = action.payload;
        },

        setAuditId: (state, action) => {
            state.auditId = action.payload;
        },

        setAuditAdded: (state, action) => {
            state.auditAdded = action.payload;
        },
        setAuditEdited: (state, action) => {
            state.auditEdited = action.payload;
        },
    },
});
export const { setAuditFormOpen, setAuditId, setAuditAdded, setAuditEdited } = formSlice.actions;
export default formSlice.reducer;

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    requisitionFormOpen: false,
    requisitionId: null,
    requisitionAdded: false,
    requisitionEdited: false,
    requisitionDeleted: false,
};

export const formSlice = createSlice({
    name: "requisitionForm",
    initialState,
    reducers: {
        setRequisitionFormOpen: (state, action) => {
            state.requisitionFormOpen = action.payload;
        },

        setRequisitionAdded: (state, action) => {
            state.requisitionAdded = action.payload;
        },

        setRequisitionEdited: (state, action) => {
            state.requisitionEdited = action.payload;
        },

        setRequisitionDeleted: (state, action) => {
            state.requisitionDeleted = action.payload;
        },

        setRequisitionId: (state, action) => {
            state.requisitionId = action.payload;
        },
    },
});

export const { setRequisitionFormOpen, setRequisitionAdded, setRequisitionEdited, setRequisitionDeleted, setRequisitionId } = formSlice.actions;

export default formSlice.reducer;

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    modulesAssigned: false
}

export const formSlice = createSlice({
    name: "myCourses",
    initialState,
    reducers: {
        
        setModulesAssigned: (state, action) => {
            state.modulesAssigned = action.payload;
        },
    }
});

export const {setModulesAssigned} = formSlice.actions;

export default formSlice.reducer;
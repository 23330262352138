const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    importUsersAddOpenToggle: false,
    usersImported: false,
    usersImportedForAccountId: null,
};

export const formSlice = createSlice({
    name: "importUsersForm",
    initialState,
    reducers: {
        setImportUsersAddOpenToggle: (state, action) => {
            state.importUsersAddOpenToggle = !state.importUsersAddOpenToggle;
        },

        setUsersImported: (state, action) => {
            state.usersImported = action.payload;
        },

        setUsersImportedForAccountId: (state, action) => {
            state.usersImportedForAccountId = action.payload;
        },
    },
});

export const { setImportUsersAddOpenToggle, setUsersImported, setUsersImportedForAccountId } = formSlice.actions;

export default formSlice.reducer;

const categories = Object.freeze({
    Prospect: "Prospect",
    Customer: "Customer",
    Vendor: "Vendor",
});

const occupations = Object.freeze({
    SelfEmployed: "Self Employed",
    Salaried: "Salaried",
});

const statuses = Object.freeze({
    Active: "Active",
    Inactive: "Inactive",
});

const industries = Object.freeze({
    BFSI: "BFSI",
    Government: "Government",
    Education: "Education",
    Manufacturing: "Manufacturing",
});

const noOfEmployees = Object.freeze({
    "0-50": "0-50",
    "51-100": "51-100",
    "101-500": "101-500",
    "501-1000": "501-1000",
    "1000+": "1000+",
});

const accountTypes = Object.freeze({
    Organization: "Organization",
    Individual: "Individual",
});

module.exports = { categories, occupations, statuses, industries, noOfEmployees, accountTypes };

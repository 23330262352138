const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    passwordChangeFormToggle: false,
};

export const formSlice = createSlice({
    name: "passwordChangeForm",
    initialState,
    reducers: {
        setPasswordChangeFormToggle: (state, action) => {
            state.passwordChangeFormToggle = !state.passwordChangeFormToggle;
        },
    },
});

export const { setPasswordChangeFormToggle } = formSlice.actions;

export default formSlice.reducer;

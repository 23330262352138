const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    assignConsumableFormOpen: false,
    assignConsumableId: null,
    assignConsumableAdded: false,
    assignConsumableEdited: false,
    assignConsumableDeleted: false,
};

export const formSlice = createSlice({
    name: "assignConsumableForm",
    initialState,
    reducers: {
        setAssignConsumableFormOpen: (state, action) => {
            state.assignConsumableFormOpen = action.payload;
        },

        setAssignConsumableAdded: (state, action) => {
            state.assignConsumableAdded = action.payload;
        },

        setAssignConsumableEdited: (state, action) => {
            state.assignConsumableEdited = action.payload;
        },

        setAssignConsumableDeleted: (state, action) => {
            state.assignConsumableDeleted = action.payload;
        },

        setAssignConsumableId: (state, action) => {
            state.assignConsumableId = action.payload;
        },
    },
});

export const { setAssignConsumableFormOpen, setAssignConsumableAdded, setAssignConsumableEdited, setAssignConsumableDeleted, setAssignConsumableId } =
    formSlice.actions;

export default formSlice.reducer;

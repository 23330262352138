import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setConsumableAdded, setConsumableEdited, setConsumableId } from "../../features/AM/consumableForm";
import { consumableApi } from "../../constants/apis/AM/Consumables";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { toast } from "react-toastify";
import { searchOptionDebounceTime, autoCloseToastError, formAutoCompleteLabelBoxStyles } from "../../constants/Common";
import {
    setConsumableCategoryOptions,
    setConsumableCategoryOptionsLoaded,
    setVendorAccountOptions,
    setVendorAccountOptionsLoaded,
} from "../../features/optionSlice";
import { consumableSchema } from "../../validations/AmForms/Consumable";
import { createInstance, errorHandler } from "../../utils/Request/ReqUtils";
import { Autocomplete, Box, CircularProgress, debounce, Drawer, FormControl, InputLabel, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import ToastMessages from "../../constants/ToastMessages";
import { createSearchParams } from "react-router-dom";
import { fetchInventoryCategories, fetchItemNames, fetchCustomerAndVendorAccountOptions } from "../../utils/Common";
import { inventoryTypes } from "../../enums/AM/Inventory";

const ConsumableForm = ({ open, onClose }) => {
    const dispatch = useDispatch();
    const axiosInstance = createInstance(true);
    const consumableId = useSelector((state) => state.consumableForm.consumableId);
    const consumableCategoryOptions = useSelector((state) => state.optionsForAsyncSelect.consumableCategoryOptions);
    const consumableCategoryOptionsLoaded = useSelector((state) => state.optionsForAsyncSelect.consumableCategoryOptionsLoaded);
    const vendorAccountOptions = useSelector((state) => state.optionsForAsyncSelect.vendorAccountOptions);
    const vendorAccountOptionsLoaded = useSelector((state) => state.optionsForAsyncSelect.vendorAccountOptionsLoaded);

    const initialConsumableData = {
        category: null,
        consumable_name: null,
        make: "",
        model: "",
        date_of_purchase: null,
        purchased_quantity: "",
        vendor_id: null,
        description: "",
    };

    // used for not calling the apis of autocomplete option when initialising the data in form while edit
    const initialInitialize = {
        initialize_category: true,
        initialize_consumable_name: true,
        initialize_vendor_id: true,
    };

    const initialAutocompleteFieldsStatus = {
        category: false,
        consumable_name: false,
        vendor_id: false,
        ...(consumableId && { ...initialInitialize }),
    };

    const fieldNameMappings = {
        category: "category",
        consumable_name: "consumable_name",
        vendor_id: "vendor_id",
    };

    const [consumableData, setConsumableData] = useState(initialConsumableData);
    const [consumableFormErrors, setConsumableFormErrors] = useState({});

    const [fetchingConsumableData, setFetchingConsumableData] = useState(false);
    const [autocompleteFieldsStatus, setAutocompleteFieldsStatus] = useState(initialAutocompleteFieldsStatus);
    const [autocompleteloading, setAutocompleteLoading] = useState(false);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [itemNameOptions, setItemNameOptions] = useState([]);
    const [vendorOptions, setVendorOptions] = useState([]);

    const handleConsumableForm = (e) => {
        setConsumableFormErrors({ ...consumableFormErrors, [e.target.name]: "" });
        setConsumableData({ ...consumableData, [e.target.name]: e.target.value });
    };

    const handleDates = (date, fieldName) => {
        setConsumableFormErrors({ ...consumableFormErrors, [fieldName]: "" });
        setConsumableData({ ...consumableData, [fieldName]: date });
    };

    const formatDataToCreateValidationPayload = () => {
        const consumableValidationPayload = JSON.parse(JSON.stringify(consumableData));
        const fieldsToProcess = ["category", "consumable_name", "vendor_id"];

        fieldsToProcess.forEach((field) => {
            consumableValidationPayload[field] = consumableValidationPayload?.[field]?.value || null;
        });

        const datesToProcess = ["date_of_purchase"];
        datesToProcess.forEach((field) => {
            consumableValidationPayload[field] = consumableData[field]?.format("YYYY-MM-DD") || null;
        });

        const fieldsToDelete = ["inventory_id", "available_quantity", "vendor_name"];

        fieldsToDelete.forEach((field) => delete consumableValidationPayload?.[field]);

        return consumableValidationPayload;
    };

    const formatDataToCreatePayload = (data) => {
        data.inventory_id = consumableData?.consumable_name?.inventory_id;
        const fieldsToDelete = ["category", "consumable_name"];

        fieldsToDelete.forEach((field) => delete data?.[field]);
        return data;
    };

    const handleConsumableSubmit = async (e) => {
        e.preventDefault();
        const consumableValidationPayload = formatDataToCreateValidationPayload();
        const validation = consumableSchema.validate(consumableValidationPayload, { abortEarly: false });
        if (validation.error) {
            setConsumableFormErrors({});
            const errorDetails = validation.error.details;
            let currentErrors = {};
            errorDetails.forEach((error) => {
                currentErrors[error.path[0]] = error.message;
            });
            setConsumableFormErrors(currentErrors);
        } else {
            try {
                const payload = formatDataToCreatePayload(consumableValidationPayload);
                const axiosInstance = createInstance(true);
                let response = await axiosInstance.post(consumableApi, payload);
                if (response?.status === 200) {
                    toast.success(ToastMessages.addConsumableSuccess);
                    handleConsumableFormClose();
                    dispatch(setConsumableAdded(true));
                } else {
                    toast.error(ToastMessages.failedToAddConsumable, { autoClose: autoCloseToastError });
                }
            } catch (err) {
                errorHandler(err, ToastMessages.failedToAddConsumable);
            }
        }
    };

    const handleConsumableEdit = async (id) => {
        const consumableValidationPayload = formatDataToCreateValidationPayload();
        const validation = consumableSchema.validate(consumableValidationPayload, { abortEarly: false });
        if (validation.error) {
            setConsumableFormErrors({});
            const errorDetails = validation.error.details;
            let currentErrors = {};
            errorDetails.forEach((error) => {
                currentErrors[error.path[0]] = error.message;
            });
            setConsumableFormErrors(currentErrors);
        } else {
            try {
                const paylaod = formatDataToCreatePayload(consumableValidationPayload);
                const axiosInstance = createInstance(true);
                let response = await axiosInstance.put(consumableApi.concat(id), paylaod);
                if (response?.status === 200) {
                    toast.success(ToastMessages.consumableEditSuccess);
                    handleConsumableFormClose();
                    dispatch(setConsumableEdited(true));
                } else {
                    toast.error(ToastMessages.failedToEditConsumable, { autoClose: autoCloseToastError });
                }
            } catch (err) {
                errorHandler(err, ToastMessages.failedToEditConsumable);
            }
        }
    };

    const handleConsumableFormClose = () => {
        onClose();
        setConsumableFormErrors({});
        setConsumableData(initialConsumableData);
        dispatch(setConsumableId(null));
    };

    // debounced functions to fetch data
    const debouncedFetchConsumableCategories = debounce(async (newInputValue) => {
        setAutocompleteLoading(true);

        try {
            const params = createSearchParams({
                search: newInputValue || "",
                source: inventoryTypes.Consumable.toLowerCase(),
            });
            const fetchedOptions = await fetchInventoryCategories(params);
            if (!consumableCategoryOptionsLoaded && fetchedOptions) {
                dispatch(setConsumableCategoryOptionsLoaded(true));
                dispatch(setConsumableCategoryOptions(fetchedOptions));
            }

            // fetched option might not contain the selected options so adding that to avoid warnings of option value not exists
            const mergedOptions = [
                ...(fetchedOptions || []),
                ...categoryOptions.filter((selectedOption) => !fetchedOptions.some((option) => option?.value === selectedOption?.value)),
            ];

            setCategoryOptions(mergedOptions);
        } catch (error) {}
        setAutocompleteLoading(false);
    }, searchOptionDebounceTime);

    const debouncedFetchItemNames = debounce(async (newInputValue) => {
        setAutocompleteLoading(true);

        try {
            const params = createSearchParams({
                category: consumableData?.category?.value,
                search: newInputValue || "",
                source: inventoryTypes.Consumable.toLowerCase(),
            });
            const fetchedOptions = await fetchItemNames(params);

            // fetched option might not contain the selected options so adding that to avoid warnings of option value not exists
            const mergedOptions = [
                ...(fetchedOptions || []),
                ...itemNameOptions.filter((selectedOption) => !fetchedOptions.some((option) => option?.value === selectedOption?.value)),
            ];

            setItemNameOptions(mergedOptions);
        } catch (error) {}
        setAutocompleteLoading(false);
    }, searchOptionDebounceTime);

    const debouncedFetchVendors = debounce(async (newInputValue) => {
        setAutocompleteLoading(true);

        try {
            const params = createSearchParams({
                search: newInputValue || "",
                vendorsRequired: true,
                customersRequired: false,
                selfRequired: false,
            });
            const fetchedOptions = await fetchCustomerAndVendorAccountOptions(params);
            if (!vendorAccountOptionsLoaded && fetchedOptions) {
                dispatch(setVendorAccountOptionsLoaded(true));
                dispatch(setVendorAccountOptions(fetchedOptions));
            }

            // fetched option might not contain the selected options so adding that to avoid warnings of option value not exists
            const mergedOptions = [
                ...(fetchedOptions || []),
                ...vendorOptions.filter((selectedOption) => !fetchedOptions.some((option) => option?.value === selectedOption?.value)),
            ];

            setVendorOptions(mergedOptions);
        } catch (error) {}
        setAutocompleteLoading(false);
    }, searchOptionDebounceTime);

    // on autocomplete option change
    const handleSelectedOptionsChange = (e, option, fieldName) => {
        if (option) {
            if (fieldName === fieldNameMappings?.category) {
                setConsumableData({ ...consumableData, [fieldName]: option || null, consumable_name: null });
                setItemNameOptions([]);
            } else setConsumableData({ ...consumableData, [fieldName]: option || null });
            setConsumableFormErrors({ ...consumableFormErrors, [fieldName]: "" });
        } else {
            if (fieldName === fieldNameMappings?.category) {
                setConsumableData({ ...consumableData, category: null, consumable_name: null });
                setItemNameOptions([]);
            } else if (fieldName === fieldNameMappings?.consumable_name) {
                setConsumableData({ ...consumableData, consumable_name: null });
            } else if (fieldName === fieldNameMappings?.vendor_id) {
                setConsumableData({ ...consumableData, vendor_id: null });
            }
        }
    };

    // functions for autocomplete field open to fetch data
    const handleCategoryFieldOpen = async () => {
        setAutocompleteFieldsStatus({ ...autocompleteFieldsStatus, category: true, initialize_category: false });
        if (!consumableCategoryOptionsLoaded) {
            debouncedFetchConsumableCategories("");
        } else {
            // options might not contain the selected options so adding that to avoid warnings of option value not exists
            const mergedOptions = [
                ...(consumableCategoryOptions || []),
                ...categoryOptions.filter((selectedOption) => !consumableCategoryOptions.some((option) => option?.value === selectedOption?.value)),
            ];
            setCategoryOptions(mergedOptions);
        }
    };

    const handleConsumableItemNameFieldOpen = async () => {
        setAutocompleteFieldsStatus({ ...autocompleteFieldsStatus, consumable_name: true, initialize_consumable_name: false });
        if (consumableData.category && !itemNameOptions.length) {
            debouncedFetchItemNames("");
        }
    };

    const handleVendorFieldOpen = async () => {
        setAutocompleteFieldsStatus({ ...autocompleteFieldsStatus, vendor_id: true, initialize_vendor_id: false });
        if (!vendorAccountOptionsLoaded) {
            debouncedFetchVendors("");
        } else {
            // options might not contain the selected options so adding that to avoid warnings of option value not exists

            const mergedOptions = [
                ...(vendorAccountOptions || []),
                ...vendorOptions.filter((selectedOption) => !vendorAccountOptions.some((option) => option?.value === selectedOption?.value)),
            ];
            setVendorOptions(mergedOptions);
        }
    };

    // functions to handle the search in autocomplete
    const handleCategoryInputChange = async (event, value) => {
        const inputExistsInOptions = categoryOptions.some((option) => option?.label.toLowerCase() === value.toLowerCase());
        if (!inputExistsInOptions && value && !autocompleteFieldsStatus.initialize_category) {
            debouncedFetchConsumableCategories(value);
        }
    };

    const handleConsumableItemNameInputChange = async (event, value) => {
        const inputExistsInOptions = itemNameOptions.some((option) => option?.label.toLowerCase() === value.toLowerCase());
        if (!inputExistsInOptions && consumableData.category && value && !autocompleteFieldsStatus.initialize_consumable_name) {
            debouncedFetchItemNames(value);
        }
    };

    const handleVendorInputChange = async (event, value) => {
        const inputExistsInOptions = vendorOptions.some((option) => option?.label.toLowerCase() === value.toLowerCase());
        if (!inputExistsInOptions && value && !autocompleteFieldsStatus.initialize_vendor_id) {
            debouncedFetchVendors(value);
        }
    };

    // close autocomplete
    const closeAutocomplete = (fieldName) => {
        setAutocompleteFieldsStatus({ ...autocompleteFieldsStatus, [fieldName]: false });
    };

    const fetchConsumableData = async (id) => {
        setFetchingConsumableData(true);
        try {
            const result = await axiosInstance.get(consumableApi + "/" + id);
            if (result.status === 200) {
                const fetchedConsumableData = result?.data?.data[0];
                for (let key in initialConsumableData) {
                    fetchedConsumableData[key] = fetchedConsumableData[key] || "";
                    if (["date_of_purchase", "warranty_start_date", "warranty_end_date"].includes(key)) {
                        if (fetchedConsumableData[key]) {
                            const formattedDate = dayjs(fetchedConsumableData[key]);
                            fetchedConsumableData[key] = formattedDate;
                        } else {
                            fetchedConsumableData[key] = null;
                        }
                    } else if (key === "category") {
                        fetchedConsumableData[key] = { label: fetchedConsumableData[key], value: fetchedConsumableData[key] };
                    } else if (key === "consumable_name") {
                        fetchedConsumableData[key] = {
                            label: fetchedConsumableData[key],
                            value: fetchedConsumableData[key],
                            inventory_id: fetchedConsumableData["inventory_id"],
                        };
                    } else if (key === "vendor_id") {
                        fetchedConsumableData[key] = {
                            value: fetchedConsumableData?.vendor_id,
                            label: fetchedConsumableData?.vendor_name,
                            additionalData: {},
                        };
                    }
                }
                setConsumableData(fetchedConsumableData);
                setAutocompleteFieldsStatus(initialAutocompleteFieldsStatus);
            } else {
                toast.error(ToastMessages.failedToFetchConsumables);
            }
        } catch (err) {
            errorHandler(err, ToastMessages.failedToFetchConsumables);
        }
        setFetchingConsumableData(false);
    };

    useEffect(() => {
        if (consumableId) {
            fetchConsumableData(consumableId);
        }
    }, []);

    return (
        <div>
            <Drawer
                sx={{
                    "& .MuiDrawer-paper": {
                        width: {
                            xs: "90%",
                            lg: 1000,
                        },
                    },
                }}
                anchor={"right"}
                open={open}
                onClose={handleConsumableFormClose}
            >
                <div className="add-form-base">
                    <div className="add-form-header">
                        <h5 className="add-form-title">
                            {consumableId ? "Edit " : "New "}
                            Consumable
                        </h5>
                        <button type="button" className="add-form-close" onClick={handleConsumableFormClose}></button>
                    </div>
                    <div className="content-separator"></div>
                    {fetchingConsumableData ? (
                        <LoadingSpinner />
                    ) : (
                        <div className="add-form-scroll-container">
                            <div className="add-form-content">
                                <div className="row-type-2">
                                    <div className="group-1" style={{ alignItems: "normal" }}>
                                        <Box className="row-type-2-label" sx={formAutoCompleteLabelBoxStyles}>
                                            <InputLabel>
                                                Category <span className="required-field-asterisk"> *</span>
                                            </InputLabel>
                                        </Box>
                                        <FormControl className="form-control-for-field">
                                            <Autocomplete
                                                size="small"
                                                value={consumableData.category}
                                                open={autocompleteFieldsStatus?.category}
                                                onOpen={handleCategoryFieldOpen}
                                                onClose={() => {
                                                    closeAutocomplete(fieldNameMappings?.category);
                                                }}
                                                onChange={(e, option) => {
                                                    handleSelectedOptionsChange(e, option, fieldNameMappings?.category);
                                                }}
                                                onInputChange={handleCategoryInputChange}
                                                loading={autocompleteloading}
                                                options={categoryOptions}
                                                isOptionEqualToValue={(option) => option.label === consumableData?.category?.label}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        placeholder="Select Category"
                                                        size="small"
                                                        className="input-value"
                                                        error={Boolean(consumableFormErrors?.category)}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {autocompleteloading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                            <p className="form-error">{consumableFormErrors?.category || ""}</p>
                                        </FormControl>
                                    </div>
                                    <div className="group-2" style={{ alignItems: "normal" }}>
                                        <Box className="row-type-2-label" sx={formAutoCompleteLabelBoxStyles}>
                                            <InputLabel>
                                                Consumable Name<span className="required-field-asterisk"> *</span>
                                            </InputLabel>
                                        </Box>
                                        <FormControl className="form-control-for-field">
                                            <Autocomplete
                                                size="small"
                                                value={consumableData.consumable_name}
                                                open={autocompleteFieldsStatus?.consumable_name}
                                                onOpen={handleConsumableItemNameFieldOpen}
                                                onClose={() => {
                                                    closeAutocomplete(fieldNameMappings?.consumable_name);
                                                }}
                                                onChange={(e, option) => {
                                                    handleSelectedOptionsChange(e, option, fieldNameMappings?.consumable_name);
                                                }}
                                                onInputChange={handleConsumableItemNameInputChange}
                                                loading={autocompleteloading}
                                                options={itemNameOptions}
                                                isOptionEqualToValue={(option) => option.label === consumableData?.consumable_name?.label}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        placeholder="Select Consumable Name"
                                                        size="small"
                                                        className="input-value"
                                                        error={Boolean(consumableFormErrors?.consumable_name)}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {autocompleteloading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                            <p className="form-error">{consumableFormErrors?.consumable_name || ""}</p>
                                        </FormControl>
                                    </div>
                                </div>

                                <div className="row-type-2">
                                    <div className="group-1">
                                        <InputLabel className="row-type-2-label">
                                            Make <span className="required-field-asterisk"> *</span>
                                        </InputLabel>
                                        <FormControl className="form-control-for-field">
                                            <TextField
                                                type="text"
                                                name="make"
                                                placeholder="Make"
                                                value={consumableData?.make}
                                                onChange={handleConsumableForm}
                                                className="input-value"
                                                size="small"
                                                error={Boolean(consumableFormErrors?.make)}
                                            />
                                            <p className="form-error">{consumableFormErrors?.make || ""}</p>
                                        </FormControl>
                                    </div>
                                    <div className="group-1">
                                        <InputLabel className="row-type-2-label">
                                            Model <span className="required-field-asterisk"> *</span>
                                        </InputLabel>
                                        <FormControl className="form-control-for-field">
                                            <TextField
                                                type="text"
                                                name="model"
                                                placeholder="Model"
                                                value={consumableData?.model}
                                                onChange={handleConsumableForm}
                                                className="input-value"
                                                size="small"
                                                error={Boolean(consumableFormErrors?.model)}
                                            />
                                            <p className="form-error">{consumableFormErrors?.model || ""}</p>
                                        </FormControl>
                                    </div>
                                </div>

                                <div className="row-type-2">
                                    <div className="group-1">
                                        <InputLabel className="row-type-2-label">
                                            Date of Purchase
                                            <span className="required-field-asterisk"> *</span>
                                        </InputLabel>
                                        <FormControl className="form-control-for-field">
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    name="date_of_purchase"
                                                    format="DD/MM/YYYY"
                                                    disableFuture={true}
                                                    value={consumableData?.date_of_purchase || null}
                                                    onChange={(date) => handleDates(date, "date_of_purchase")}
                                                    className={`input-value ${consumableData?.date_of_purchase === null ? "not-selected" : ""}`}
                                                    size="small"
                                                    emptyLabel="DD/MM/YYYY"
                                                    slotProps={{ textField: { error: Boolean(consumableFormErrors?.date_of_purchase) } }}
                                                />
                                            </LocalizationProvider>
                                            <p className="form-error">{consumableFormErrors?.date_of_purchase || ""}</p>
                                        </FormControl>
                                    </div>
                                    <div className="group-2">
                                        <InputLabel className="row-type-2-label">
                                            Purchased Quantity <span className="required-field-asterisk"> *</span>
                                        </InputLabel>
                                        <FormControl className="form-control-for-field">
                                            <TextField
                                                type="number"
                                                name="purchased_quantity"
                                                placeholder="Purchased Quantity"
                                                value={consumableData?.purchased_quantity}
                                                onChange={handleConsumableForm}
                                                className="input-value"
                                                size="small"
                                                error={Boolean(consumableFormErrors?.purchased_quantity)}
                                            />
                                            <p className="form-error">{consumableFormErrors?.purchased_quantity || ""}</p>
                                        </FormControl>
                                    </div>
                                </div>

                                <div className="row-type-2">
                                    <div className="group-1" style={{ alignItems: "normal" }}>
                                        <Box className="row-type-2-label" sx={formAutoCompleteLabelBoxStyles}>
                                            <InputLabel>
                                                Vendor <span className="required-field-asterisk"> *</span>
                                            </InputLabel>
                                        </Box>

                                        <FormControl className="form-control-for-field">
                                            <Autocomplete
                                                size="small"
                                                value={consumableData.vendor_id}
                                                open={autocompleteFieldsStatus?.vendor_id}
                                                onOpen={handleVendorFieldOpen}
                                                onClose={() => {
                                                    closeAutocomplete(fieldNameMappings?.vendor_id);
                                                }}
                                                onChange={(e, option) => {
                                                    handleSelectedOptionsChange(e, option, fieldNameMappings?.vendor_id);
                                                }}
                                                onInputChange={handleVendorInputChange}
                                                loading={autocompleteloading}
                                                options={vendorOptions}
                                                isOptionEqualToValue={(option) => option.label === consumableData?.vendor_id?.label}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        placeholder="Select Vendor"
                                                        size="small"
                                                        className="input-value"
                                                        error={Boolean(consumableFormErrors?.vendor_id)}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {autocompleteloading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                            <p className="form-error">{consumableFormErrors?.vendor_id || ""}</p>
                                        </FormControl>
                                    </div>
                                    <div className="group-2">
                                        <InputLabel className="row-type-2-label">
                                            Available Quantity <span className="required-field-asterisk"> *</span>
                                        </InputLabel>
                                        <FormControl className="form-control-for-field">
                                            <TextField
                                                type="number"
                                                name="available_quantity"
                                                placeholder="Available Quantity"
                                                value={consumableId ? consumableData?.available_quantity : consumableData?.purchased_quantity}
                                                onChange={handleConsumableForm}
                                                className="input-value"
                                                size="small"
                                                disabled={true}
                                            />
                                            <p className="form-error"></p>
                                        </FormControl>
                                    </div>
                                </div>

                                <div className="row-type-1">
                                    <InputLabel className="row-type-1-label">Description</InputLabel>
                                    <FormControl className="form-control-for-field">
                                        <TextField
                                            name="description"
                                            onChange={handleConsumableForm}
                                            placeholder="Description"
                                            value={consumableData?.description}
                                            error={Boolean(consumableFormErrors?.description)}
                                            className="input-value"
                                            size="small"
                                            multiline
                                        />
                                        <p className="form-error">{consumableFormErrors?.description || ""}</p>
                                    </FormControl>
                                </div>

                                <div className="row-type-1">
                                    <InputLabel className="row-type-1-label"></InputLabel>
                                    <div className="form-control-for-field mandatory-field-row">
                                        <span className="required-field-asterisk">* </span>
                                        <span className="mandatory-field-text">Mandatory field</span>
                                    </div>
                                </div>

                                <div className="bottom-container">
                                    <div className="content-separator"></div>
                                    <div className="action-buttons">
                                        <button
                                            className="save-button"
                                            onClick={(e) => {
                                                if (consumableId) {
                                                    handleConsumableEdit(consumableId);
                                                } else handleConsumableSubmit(e);
                                            }}
                                        >
                                            Save
                                        </button>

                                        <button className="cancel-button" onClick={handleConsumableFormClose}>
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </Drawer>
        </div>
    );
};

export default React.memo(ConsumableForm);

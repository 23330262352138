const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    complyAreaFormOpen: false,
    complyAreaId: null,
    complyAreaAdded: false,
    complyAreaEdited: false,
};

export const formSlice = createSlice({
    name: "complyAreaForm",
    initialState,
    reducers: {
        setComplyAreaFormOpen: (state, action) => {
            state.complyAreaFormOpen = action.payload;
        },

        setComplyAreaId: (state, action) => {
            state.complyAreaId = action.payload;
        },

        setComplyAreaAdded: (state, action) => {
            state.complyAreaAdded = action.payload;
        },
        setComplyAreaEdited: (state, action) => {
            state.complyAreaEdited = action.payload;
        },
    },
});
export const { setComplyAreaFormOpen, setComplyAreaId, setComplyAreaAdded, setComplyAreaEdited } = formSlice.actions;
export default formSlice.reducer;

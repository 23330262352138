const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    stationaryFormOpen: false,
    stationaryId: null,
    stationaryAdded: false,
    stationaryEdited: false,
};

export const formSlice = createSlice({
    name: "stationaryForm",
    initialState,
    reducers: {
        setStationaryFormOpen: (state, action) => {
            state.stationaryFormOpen = action.payload;
        },

        setStationaryAdded: (state, action) => {
            state.stationaryAdded = action.payload;
        },

        setStationaryEdited: (state, action) => {
            state.stationaryEdited = action.payload;
        },

        setStationaryId: (state, action) => {
            state.stationaryId = action.payload;
        },
    },
});

export const { setStationaryFormOpen, setStationaryAdded, setStationaryEdited, setStationaryId } = formSlice.actions;

export default formSlice.reducer;

import { customerAndVendorAccountOptionsApi } from "../../constants/apis/BD/Account";
import { accountTypes } from "../../enums/Account";
import { accountLocationOptionsApi } from "../../constants/apis/BD/AccountLocation";
import { getCategoriesApi, getGroupCodesApi, getItemNamesApi, getItemsCodesApi } from "../../constants/apis/AM/Inventory";

const { toast } = require("react-toastify");
const { errorHandler, createInstance } = require("../Request/ReqUtils");
const { autoCloseToastError, minCharsToLoadOptions } = require("../../constants/Common");
const { userOptionsForDocumentShareApi, userOptionsApi } = require("../../constants/apis/User");
const ToastMessages = require("../../constants/ToastMessages");
/* global BigInt */

export const checkAllSelection = (inputObject) => {
    const allTrue = Object.values(inputObject).every((value) => value === true);
    const allFalse = Object.values(inputObject).every((value) => value === false);
    return allTrue || allFalse;
};

export const titleCase = async (str) => {
    const wordsWithSeparators = str.split(/([ ,.-]+)/);
    const words = [];
    let currentWord = "";

    for (const wordOrSeparator of wordsWithSeparators) {
        if (!/[ ,.-]+/.test(wordOrSeparator)) {
            currentWord = wordOrSeparator.charAt(0).toUpperCase() + wordOrSeparator.slice(1).toLowerCase();
        } else {
            if (currentWord) {
                words.push(currentWord);
            }
            currentWord = "";
            words.push(wordOrSeparator);
        }
    }
    if (currentWord) {
        words.push(currentWord);
    }
    return words.join("");
};

export const customTimeRoundUp = (seconds) => {
    seconds = parseFloat(seconds);
    let hours = seconds / parseFloat(3600);
    let decimalPart = hours - Math.floor(hours);

    // If the decimal part is greater than 0.5, round up, otherwise round down
    // Also if decimal part is exact half, add it
    if (decimalPart > 0.5) {
        return Math.ceil(hours);
    } else if (decimalPart < 0.5) {
        return Math.floor(hours);
    } else {
        return Math.floor(hours) + 0.5;
    }
};

export const getOriginalFileName = (serverFileName) => {
    let originalFileileName = "";

    let fileNameParts = serverFileName.split("_");

    if (fileNameParts.length >= 3) fileNameParts = fileNameParts.slice(2);
    originalFileileName = fileNameParts.join("_");

    return originalFileileName;
};

export const formatFileSize = (sizeInBytes) => {
    sizeInBytes = BigInt(sizeInBytes) || 0n;
    let size;
    const kbSize = BigInt(1024);
    const mbSize = BigInt(1024 * 1024);
    const gbSize = BigInt(1024 * 1024 * 1024);

    if (sizeInBytes < kbSize) {
        size = String(sizeInBytes);
        return size + " B";
    } else if (sizeInBytes < mbSize) {
        size = String(sizeInBytes / kbSize);
        return size + " KB";
    } else if (sizeInBytes < gbSize) {
        size = String(sizeInBytes / mbSize);
        return size + " MB";
    } else {
        size = String(sizeInBytes / gbSize);
        return size + " GB";
    }
};

export const fetchUserOptionsForDocumentShare = async (inputValue) => {
    try {
        const axiosInstance = createInstance(true);
        const response = await axiosInstance.get(userOptionsForDocumentShareApi + "?search=" + inputValue);
        if (response?.data && response?.data?.success) {
            const formattedOptions = response?.data?.data.map((item) => {
                let additionalData = { location: item?.location, mobile: item?.mobile };

                return {
                    value: item?.user_id,
                    label: item?.full_name,
                    additionalData: additionalData, // Include additional data in the result
                };
            });
            return formattedOptions;
        } else {
            toast.error(ToastMessages.failedToFetchUserOptions, { autoClose: autoCloseToastError });
        }
    } catch (err) {
        errorHandler(err, ToastMessages.failedToFetchUserOptions);
    }
    return [];
};

export const fetchCustomerAndVendorAccountOptions = async (searchParams) => {
    try {
        const axiosInstance = createInstance(true);
        const response = await axiosInstance.get(`${customerAndVendorAccountOptionsApi}?${searchParams}`);
        if (response?.data && response?.data?.success) {
            const formattedOptions = response?.data?.data.map((item) => {
                let additionalData = {};
                // Add additional data based on a condition
                if (item.account_type === accountTypes.Organization) {
                    additionalData = { account_type: item?.account_type, gstin: item?.gstin };
                } else if (item.account_type === accountTypes.Individual) {
                    additionalData = { account_type: item?.account_type, mobile: item?.mobile, city: item?.city };
                }

                return {
                    value: item?.account_id,
                    label: item?.account_name,
                    additionalData: additionalData, // Include additional data in the result
                };
            });
            return formattedOptions;
        } else {
            toast.error(ToastMessages.failedToFetchAccountOptions, { autoClose: autoCloseToastError });
        }
    } catch (err) {
        errorHandler(err, ToastMessages.failedToFetchAccountOptions);
    }
    return [];
};

export const fetchLocationOptions = async (accountId, params) => {
    const axiosInstance = createInstance(true);
    try {
        const response = await axiosInstance.get(`${accountLocationOptionsApi}/${accountId}?${params}`);

        if (response?.data && response?.data?.success) {
            const formattedOptions = response?.data?.data.map((item) => {
                return {
                    id: item?.location_id,
                    value: item?.location_code,
                    label: item?.location_name.concat(` (${item?.location_code})`),
                };
            });

            return formattedOptions;
        } else {
            toast.error(ToastMessages.failedToFetchLocationOptions, { autoClose: autoCloseToastError });
        }
    } catch (err) {
        errorHandler(err, ToastMessages.failedToFetchLocationOptions);
    }
};

export const fetchUserOptions = async (inputValue, params) => {
    try {
        const axiosInstance = createInstance(true);
        const response = await axiosInstance.get(`${userOptionsApi}?${params}`);
        if (response?.data && response?.data?.success) {
            const formattedOptions = response?.data?.data.map((item) => {
                let additionalData = { location: item?.location, mobile: item?.mobile };

                return {
                    value: item?.user_id,
                    label: item?.assign_to,
                    additionalData: additionalData, // Include additional data in the result
                };
            });
            return formattedOptions;
        } else {
            toast.error(ToastMessages.failedToFetchUserOptions, { autoClose: autoCloseToastError });
        }
    } catch (err) {
        errorHandler(err, ToastMessages.failedToFetchUserOptions);
    }
    return [];
};

export const fetchStationaryGroupCodes = async (params) => {
    try {
        const axiosInstance = createInstance(true);
        const response = await axiosInstance.get(`${getGroupCodesApi}?${params}`);
        if (response?.status === 200) {
            const formattedOptions = response?.data?.data.map((item) => ({ value: item?.group_code, label: item?.group_code }));
            return formattedOptions;
        } else {
            toast.error(ToastMessages.failedToFetchGroupCodes);
        }
    } catch (err) {
        errorHandler(err, ToastMessages.failedToFetchGroupCodes);
    }
};

export const fetchStationaryItemCodes = async (params) => {
    try {
        const axiosInstance = createInstance(true);
        const response = await axiosInstance.get(`${getItemsCodesApi}?${params}`);
        if (response?.status === 200) {
            const formattedOptions = response?.data?.data.map((item) => ({ value: item?.item_code, label: item?.item_code }));
            return formattedOptions;
        } else {
            toast.error(ToastMessages.failedToFetchItemCodes);
        }
    } catch (err) {
        errorHandler(err, ToastMessages.failedToFetchItemCodes);
    }
};

export const fetchItemNames = async (params) => {
    try {
        const axiosInstance = createInstance(true);
        const response = await axiosInstance.get(`${getItemNamesApi}?${params}`);
        if (response?.status === 200) {
            const formattedOptions = response?.data?.data.map((item) => ({ value: item?.name, label: item?.name, inventory_id: item?.id }));
            return formattedOptions;
        } else {
            toast.error(ToastMessages.failedToFetchItemNames);
        }
    } catch (err) {
        errorHandler(err, ToastMessages.failedToFetchItemNames);
    }
};

export const loadUserOptions = (inputValue, callback) => {
    if (inputValue.length < minCharsToLoadOptions) {
        return callback([]);
    }
    fetchUserOptionsForDocumentShare(inputValue)
        .then((options) => callback(options))
        .catch((error) => {
            callback([]);
        });
};

export const fetchInventoryCategories = async (params) => {
    try {
        const axiosInstance = createInstance(true);
        const response = await axiosInstance.get(`${getCategoriesApi}?${params}`);
        if (response?.status === 200) {
            const formattedOptions = response?.data?.data.map((item) => ({ value: item?.category, label: item?.category }));
            return formattedOptions;
        } else {
            toast.error(ToastMessages.failedToFetchCategories);
        }
    } catch (err) {
        errorHandler(err, ToastMessages.failedToFetchCategories);
    }
};

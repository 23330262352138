const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    contactEdit: false,
    contactAddAccountSpecific: false,
    contactAddedForAccountId: null,
    contactId: null,
    contactAccountId: null,
    contactEditOpenToggle: false, 
    contactAddOpenToggle: false,
    contactAccountName: "",
    contactAdded: false,
    contactEdited: false,
}

export const formSlice = createSlice({
    name: "contactForm",
    initialState,
    reducers: {
        setContactEdit: (state, action) => {
            state.contactEdit = action.payload;
        },

        setContactAddAccountSpecific: (state, action) => {
            state.contactAddAccountSpecific = action.payload;
        },

        setContactAddedForAccountId: (state, action) => {
            state.contactAddedForAccountId = action.payload;
        },

        setContactId: (state, action) => {
            state.contactId = action.payload;
        },

        setContactAccountId: (state, action) => {
            state.contactAccountId = action.payload;
        },

        setContactEditOpenToggle: (state, action) => {
            state.contactEditOpenToggle = !state.contactEditOpenToggle;
        },

        setContactAddOpenToggle: (state, action) => {
            state.contactAddOpenToggle = !state.contactAddOpenToggle;
        },

        setContactAccountName: (state, action) => {
            state.contactAccountName = action.payload;
        },

        setContactAdded: (state, action) => {
            state.contactAdded = action.payload;
        },

        setContactEdited: (state, action) => {
            state.contactEdited = action.payload;
        },
    }
});

export const {setContactEdit, setContactAddAccountSpecific, setContactAddedForAccountId, setContactId, setContactAccountId, setContactEditOpenToggle, setContactAccountName,  setContactAddOpenToggle, setContactAdded, setContactEdited} = formSlice.actions;

export default formSlice.reducer;
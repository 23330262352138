import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";

import { useSelector, useDispatch } from "react-redux";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { setMasterId } from "../../features/CM/masterFormSlice";
import { Autocomplete, Box, CircularProgress, FormControl, InputLabel, MenuItem, Paper, Popper, Select, TextField, Typography } from "@mui/material";
import { formAutoCompleteLabelBoxStyles } from "../../constants/Common";
import { masterFrequencies } from "../../enums/CM/Master";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const MasterForm = ({ open, onClose }) => {
    const dispatch = useDispatch();
    const initialData = {
        type: "",
        title: "",
        department: "",
        frequency: "",
        due_date: null,
    };

    const [data, setData] = useState(initialData);
    const [formErrors, setFormErrors] = useState({});
    const [fetchingData, setFetchingData] = useState(false);
    const [autocompleteLoading, setAutocompleteLoading] = useState({
        type: false,
        title: false,
        department: false,
    });

    const masterId = useSelector((state) => state.masterForm.masterId);

    const handleFormClose = () => {
        onClose();
        setFormErrors({});
        setData(initialData);
        dispatch(setMasterId(null));
    };

    const typeOptions = [
        { label: "IT Audit" },
        { label: "Regulatory" },
        { label: "Financial" },
        { label: "Risk Management" },
        { label: "Internal Audit" },
    ];

    const titleOptions = [{ label: "Title1" }, { label: "Title2" }, { label: "Title3" }, { label: "Title4" }];

    const departmentOptions = [
        { label: "Department1" },
        { label: "Department2" },
        { label: "Department3" },
        { label: "Department4" },
        { label: "Department5" },
    ];

    const handleInputChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
        setFormErrors({ ...formErrors, [e.target.name]: "" });
    };

    const handleSelectedOption = (e, option, fieldName) => {
        setData({ ...data, [fieldName]: option || "" });
        setFormErrors({ ...formErrors, [fieldName]: "" });
    };

    const handleDates = (date, fieldName) => {
        setFormErrors({ ...formErrors, [fieldName]: "" });
        setData({ ...data, [fieldName]: date });
    };

    return (
        <div>
            <Drawer
                sx={{
                    "& .MuiDrawer-paper": {
                        width: {
                            xs: "90%",
                            lg: 1000,
                        },
                    },
                }}
                anchor={"right"}
                open={open}
                onClose={handleFormClose}
            >
                <div className="add-form-base">
                    <div className="add-form-header">
                        <h5 className="add-form-title">{masterId ? "Edit" : "New"} Master</h5>
                        <button type="button" className="add-form-close" onClick={onClose}></button>
                    </div>
                    <div className="content-separator"></div>
                    {fetchingData ? (
                        <LoadingSpinner />
                    ) : (
                        <div className="add-form-scroll-container">
                            <div className="add-form-content">
                                <div className="row-type-1" style={{ alignItems: "normal" }}>
                                    <Box className="row-type-1-label" sx={formAutoCompleteLabelBoxStyles}>
                                        <InputLabel>
                                            Audit Type <span className="required-field-asterisk"> *</span>
                                        </InputLabel>
                                    </Box>

                                    <FormControl className="form-control-for-field">
                                        <Autocomplete
                                            size="small"
                                            freeSolo
                                            options={typeOptions.map((option) => option.label)}
                                            onInputChange={handleInputChange}
                                            onChange={(e, option) => {
                                                handleSelectedOption(e, option, "type");
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    name="type"
                                                    value={FormData.type}
                                                    placeholder="Select or add new type"
                                                    size="small"
                                                    className="input-value"
                                                    error={Boolean(formErrors?.type)}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        endAdornment: (
                                                            <React.Fragment>
                                                                {autocompleteLoading.type ? <CircularProgress color="inherit" size={20} /> : null}
                                                                {params.InputProps.endAdornment}
                                                            </React.Fragment>
                                                        ),
                                                    }}
                                                />
                                            )}
                                        />
                                        <p className="form-error">{formErrors?.category || ""}</p>
                                    </FormControl>
                                </div>
                                <div className="row-type-2">
                                    <div className="group-1" style={{ alignItems: "normal" }}>
                                        <Box className="row-type-2-label" sx={formAutoCompleteLabelBoxStyles}>
                                            <InputLabel>
                                                Audit Title <span className="required-field-asterisk"> *</span>
                                            </InputLabel>
                                        </Box>

                                        <FormControl className="form-control-for-field">
                                            <Autocomplete
                                                size="small"
                                                freeSolo
                                                options={titleOptions.map((option) => option.label)}
                                                onInputChange={handleInputChange}
                                                onChange={(e, option) => {
                                                    handleSelectedOption(e, option, "title");
                                                }}
                                                getOptionDisabled={() => true}
                                                PopperComponent={(props) => (
                                                    <Popper {...props} placement="bottom-start">
                                                        <Paper {...props.PopperProps}>
                                                            <Box
                                                                sx={{
                                                                    padding: "8px",
                                                                    backgroundColor: "#f5f5f5",
                                                                }}
                                                            >
                                                                <Typography variant="body2" color="textSecondary">
                                                                    Already existing audit titles with selected audit type
                                                                </Typography>
                                                            </Box>
                                                            {props.children}
                                                        </Paper>
                                                    </Popper>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        name="title"
                                                        value={FormData.title}
                                                        placeholder="Title"
                                                        size="small"
                                                        className="input-value"
                                                        error={Boolean(formErrors?.title)}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {autocompleteLoading.type ? <CircularProgress color="inherit" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                            <p className="form-error">{formErrors?.title || ""}</p>
                                        </FormControl>
                                    </div>
                                    <div className="group-2" style={{ alignItems: "normal" }}>
                                        <Box className="row-type-2-label" sx={formAutoCompleteLabelBoxStyles}>
                                            <InputLabel>
                                                Department <span className="required-field-asterisk"> *</span>
                                            </InputLabel>
                                        </Box>

                                        <FormControl className="form-control-for-field">
                                            <Autocomplete
                                                size="small"
                                                freeSolo
                                                options={departmentOptions.map((option) => option.label)}
                                                onInputChange={handleInputChange}
                                                onChange={(e, option) => {
                                                    handleSelectedOption(e, option, "department");
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        name="department"
                                                        value={FormData.type}
                                                        placeholder="Select or add new department"
                                                        size="small"
                                                        className="input-value"
                                                        error={Boolean(formErrors?.department)}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {autocompleteLoading.department ? (
                                                                        <CircularProgress color="inherit" size={20} />
                                                                    ) : null}
                                                                    {params.InputProps.endAdornment}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                            <p className="form-error">{formErrors?.category || ""}</p>
                                        </FormControl>
                                    </div>
                                </div>

                                <div className="row-type-2">
                                    <div className="group-1">
                                        <InputLabel className="row-type-2-label">
                                            Frequency
                                            <span className="required-field-asterisk"> *</span>
                                        </InputLabel>
                                        <FormControl className="form-control-for-field" error={Boolean(formErrors?.frequency)}>
                                            <Select
                                                name="frequency"
                                                onChange={handleInputChange}
                                                value={data?.frequency}
                                                className={`select-value ${data?.frequency === "" ? "not-selected" : ""}`}
                                                size="small"
                                                displayEmpty
                                                inputProps={{ "aria-label": "Without label" }}
                                                MenuProps={{
                                                    disablePortal: true,
                                                }}
                                            >
                                                <MenuItem className="select-menu-item" value="">
                                                    Select Frequency
                                                </MenuItem>
                                                {Object.keys(masterFrequencies).map((key) => (
                                                    <MenuItem className="select-menu-item" key={key} value={masterFrequencies[key]}>
                                                        {masterFrequencies[key]}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <p className="form-error">{formErrors?.frequency || ""}</p>
                                        </FormControl>
                                    </div>

                                    <div className="group-2">
                                        <InputLabel className="row-type-2-label">
                                            Due Date
                                            <span className="required-field-asterisk"> *</span>
                                        </InputLabel>
                                        <FormControl className="form-control-for-field" error={Boolean(formErrors?.due_date)}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    name="due_date"
                                                    format="DD/MM/YYYY"
                                                    value={data?.due_date || null}
                                                    onChange={(date) => handleDates(date, "due_date")}
                                                    className={`input-value ${data?.due_date === null ? "not-selected" : ""}`}
                                                    size="small"
                                                    emptyLabel="DD/MM/YYYY"
                                                    slotProps={{ textField: { error: Boolean(formErrors?.due_date) } }}
                                                />
                                            </LocalizationProvider>
                                            <p className="form-error">{formErrors?.due_date || ""}</p>
                                        </FormControl>
                                    </div>
                                </div>

                                <div className="row-type-1">
                                    <InputLabel className="row-type-1-label"></InputLabel>
                                    <div className="form-control-for-field mandatory-field-row">
                                        <span className="required-field-asterisk">* </span>
                                        <span className="mandatory-field-text">Mandatory field</span>
                                    </div>
                                </div>

                                <div className="bottom-container">
                                    <div className="content-separator"></div>
                                    <div className="action-buttons">
                                        <button className="save-button">Save</button>

                                        <button className="cancel-button" onClick={handleFormClose}>
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </Drawer>
        </div>
    );
};

export default React.memo(MasterForm);

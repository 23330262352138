const baseURL = process.env.REACT_APP_BACKEND_BASEURL;
const assetApi = baseURL + "/asset-management/asset/";
const assetsPagePath = "/am/assets";
const assignedAssetsApi = baseURL + "/asset-management/asset/assigned_assets";
const assetTagApi = baseURL + "/asset-management/asset/asset_tag";
const getInventoryNamesApi = baseURL + "/asset-management/inventory/get-names";
const getAssetMakesApi = assetApi + "makes";
const getAssetModelsApi = assetApi + "models";
const getAssetDopAndQuantityApi = assetApi + "/dop-and-quantity";
const getAssetsOnMaintenanceApi = assetApi + "/assets-on-maintenance";

export {
    assetApi,
    assetsPagePath,
    assignedAssetsApi,
    getInventoryNamesApi,
    assetTagApi,
    getAssetMakesApi,
    getAssetModelsApi,
    getAssetDopAndQuantityApi,
    getAssetsOnMaintenanceApi,
};

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    courseQuestionsEdit: false,
    courseQuestionsEditId: null,
    courseQuestionsEdited: false,
    courseQuestionsEditOpenToggle: false,
}

export const formSlice = createSlice({
    name: "courseQuestionsForm",
    initialState,
    reducers: {
        setCourseQuestionsEdit: (state, action) => {
            state.courseQuestionsEdit = action.payload;
        },

        setCourseQuestionsEditId: (state, action) => {
            state.courseQuestionsEditId = action.payload;
        },

        setCourseQuestionsEdited: (state, action) => {
            state.courseQuestionsEdited = action.payload;
        },

        setCourseQuestionsEditOpenToggle: (state, action) => {
            state.courseQuestionsEditOpenToggle = !state.courseQuestionsEditOpenToggle;
        }
    }
});

export const {setCourseQuestionsEdit, setCourseQuestionsEditId, setCourseQuestionsEdited, setCourseQuestionsEditOpenToggle} = formSlice.actions;

export default formSlice.reducer;
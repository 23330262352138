const baseURL = process.env.REACT_APP_BACKEND_BASEURL;
const userOptionsApi = baseURL + "/user/useroptions";
const userApi = baseURL + "/user";
const userOptionsForDocumentShareApi = userApi + "/get-document-share-user-options";
const usersOfAccountApi = userApi + "/get-all-account-users";
const addUserApi = userApi + "/add-user";
const editOwnProfileApi = userApi + "/own-profile";
const userPermanentDeleteApi = userApi + "/permanent-delete";

export { userOptionsApi, userApi, userOptionsForDocumentShareApi, usersOfAccountApi, addUserApi, editOwnProfileApi, userPermanentDeleteApi };

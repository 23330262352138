const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    dealEdit: false,
    dealAddAccountSpecific: false,
    dealAddedForAccountId: null,
    dealId: null,
    dealAccountId: null,
    dealEditOpenToggle: false, 
    dealAddOpenToggle: false, 
    dealAccountName: "",
    dealAdded: false,
    dealEdited: false,
}

export const formSlice = createSlice({
    name: "dealForm",
    initialState,
    reducers: {
        setDealEdit: (state, action) => {
            state.dealEdit = action.payload;
        },

        setDealAddAccountSpecific: (state, action) => {
            state.dealAddAccountSpecific = action.payload;
        },

        setDealAddedForAccountId: (state, action) => {
            state.dealAddedForAccountId = action.payload;
        },

        setDealAccountId: (state, action) => {
            state.dealAccountId = action.payload;
        }, 
        
        setDealAccountName: (state, action) => {
            state.dealAccountName = action.payload;
        },

        setDealAdded: (state, action) => {
            state.dealAdded = action.payload;
        },

        setDealEdited: (state, action) => {
            state.dealEdited = action.payload;
        },

        setDealId: (state, action) => {
            state.dealId = action.payload;
        },

        setDealEditOpenToggle: (state, action) => {
            state.dealEditOpenToggle = !state.dealEditOpenToggle;
        },

        setDealAddOpenToggle: (state, action) => {
            state.dealAddOpenToggle = !state.dealAddOpenToggle;
        }
    }
});

export const {setDealEdit, setDealAddAccountSpecific, setDealAddedForAccountId, setDealAccountId, setDealAccountName, setDealAdded, setDealEdited, setDealId, setDealEditOpenToggle, setDealAddOpenToggle} = formSlice.actions;

export default formSlice.reducer;
import { validationMessages } from "../../constants/validationMessages/ValidationMessages";
const Joi = require("joi");

const stationarySchema = Joi.object().keys({
    category: Joi.string().max(255).messages({
        "string.max": validationMessages.maxDefault,
        "string.empty": validationMessages.requiredDefault,
        "any.required": validationMessages.requiredDefault,
        "string.base": validationMessages.requiredDefault,
    }),

    group_code: Joi.string().max(255).required().messages({
        "string.max": validationMessages.maxDefault,
        "string.empty": validationMessages.requiredDefault,
        "any.required": validationMessages.requiredDefault,
        "string.base": validationMessages.requiredDefault,
    }),

    item_code: Joi.string().max(255).required().messages({
        "string.max": validationMessages.maxDefault,
        "string.empty": validationMessages.requiredDefault,
        "any.required": validationMessages.requiredDefault,
        "string.base": validationMessages.requiredDefault,
    }),

    name: Joi.string().max(255).required().messages({
        "string.max": validationMessages.maxDefault,
        "string.empty": validationMessages.requiredDefault,
        "any.required": validationMessages.requiredDefault,
        "string.base": validationMessages.requiredDefault,
    }),

    challan_number: Joi.string().required().max(4).messages({
        "string.empty": validationMessages.requiredDefault,
    }),
    date_of_purchase: Joi.date().required().messages({
        "date.base": validationMessages.dateDefault,
        "any.only": validationMessages.dateDefault,
    }),

    purchased_quantity: Joi.number().integer().min(1).required().messages({
        "number.min": validationMessages.quantityDefault,
        "number.base": validationMessages.quantityDefault,
    }),

    vendor: Joi.number().integer().min(1).required().messages({
        "number.min": validationMessages.vendorDefault,
        "number.base": validationMessages.vendorDefault,
        "any.only": validationMessages.vendorDefault,
    }),

    description: Joi.string().allow("").required().messages({
        "any.required": validationMessages.descriptionDefault,
    }),
});

export { stationarySchema };

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    assignStationaryFormOpen: false,
    assignStationaryId: null,
    assignStationaryAdded: false,
    assignStationaryEdited: false,
    assignStationaryDeleted: false,
};

export const formSlice = createSlice({
    name: "assignStationaryForm",
    initialState,
    reducers: {
        setAssignStationaryFormOpen: (state, action) => {
            state.assignStationaryFormOpen = action.payload;
        },

        setAssignStationaryAdded: (state, action) => {
            state.assignStationaryAdded = action.payload;
        },

        setAssignStationaryEdited: (state, action) => {
            state.assignStationaryEdited = action.payload;
        },

        setAssignStationaryDeleted: (state, action) => {
            state.assignStationaryDeleted = action.payload;
        },

        setAssignStationaryId: (state, action) => {
            state.assignStationaryId = action.payload;
        },
    },
});

export const { setAssignStationaryFormOpen, setAssignStationaryAdded, setAssignStationaryEdited, setAssignStationaryDeleted, setAssignStationaryId } =
    formSlice.actions;

export default formSlice.reducer;

import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";

import { useSelector, useDispatch } from "react-redux";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { FormControl, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { allowedAuditFileTypes, auditFileMaxSize, auditFileMaxSizeText } from "../../constants/Common";
import { riskRatings } from "../../enums/CM/AuditArea";
import { DropzoneArea } from "mui-file-dropzone";
import { CloudUploadOutlined } from "@mui/icons-material";
import { setComplyAreaId } from "../../features/CM/complyAreaFormSlice";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

const ComplyAreaForm = ({ open, onClose }) => {
    const dispatch = useDispatch();
    const initialData = {
        comply_date: "",
        comply_status: "",
        remarks: "",
        original_file_name: "",
        file_name: "",
        size: "",
    };

    const [data, setData] = useState(initialData);
    const [formErrors, setFormErrors] = useState({});
    const [fetchingData, setFetchingData] = useState(false);

    const complyAreaId = useSelector((state) => state.complyAreaForm.complyAreaId);

    const handleFormClose = () => {
        onClose();
        setFormErrors({});
        setData(initialData);
        dispatch(setComplyAreaId(null));
    };

    const handleInputChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
        setFormErrors({ ...formErrors, [e.target.name]: "" });
    };

    return (
        <Drawer
            sx={{
                "& .MuiDrawer-paper": {
                    width: {
                        xs: "90%",
                        lg: 1000,
                    },
                },
            }}
            anchor={"right"}
            open={open}
            onClose={handleFormClose}
        >
            <div className="add-form-base">
                <div className="add-form-header">
                    <h5 className="add-form-title">{data === initialData ? "Comply" : "Update Compliance"}</h5>
                    <button type="button" className="add-form-close" onClick={onClose}></button>
                </div>
                <div className="content-separator"></div>
                {fetchingData ? (
                    <LoadingSpinner />
                ) : (
                    <div className="add-form-scroll-container">
                        <div className="add-form-content">
                            <div className="row-type-2">
                                <div className="group-1">
                                    <InputLabel className="row-type-2-label">
                                        Comply Date<span className="required-field-asterisk"> *</span>
                                    </InputLabel>

                                    <FormControl className="form-control-for-field" error={Boolean(formErrors?.comply_date)}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                name="comply_date"
                                                format="DD/MM/YYYY"
                                                value={data?.comply_date || null}
                                                // onChange={(date) => handleDates(date, "comply_date")}
                                                className={`input-value ${data?.comply_date === null ? "not-selected" : ""}`}
                                                size="small"
                                                emptyLabel="DD/MM/YYYY"
                                                slotProps={{ textField: { error: Boolean(formErrors?.comply_date) } }}
                                            />
                                        </LocalizationProvider>
                                        <p className="form-error">{formErrors?.comply_date || ""}</p>
                                    </FormControl>
                                </div>
                                <div className="group-2">
                                    <InputLabel className="row-type-2-label">
                                        Comply Status
                                        <span className="required-field-asterisk"> *</span>
                                    </InputLabel>
                                    <FormControl className="form-control-for-field" error={Boolean(formErrors?.comply_status)}>
                                        <Select
                                            name="comply_status"
                                            onChange={handleInputChange}
                                            value={data?.comply_status}
                                            className={`select-value ${data?.comply_status === "" ? "not-selected" : ""}`}
                                            size="small"
                                            displayEmpty
                                            inputProps={{ "aria-label": "Without label" }}
                                            MenuProps={{
                                                disablePortal: true,
                                            }}
                                        >
                                            <MenuItem className="select-menu-item" value="">
                                                Select Status
                                            </MenuItem>
                                            {Object.keys(riskRatings).map((key) => (
                                                <MenuItem className="select-menu-item" key={key} value={riskRatings[key]}>
                                                    {riskRatings[key]}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                        <p className="form-error">{formErrors?.comply_status || ""}</p>
                                    </FormControl>
                                </div>
                            </div>

                            <div className="row-type-1">
                                <InputLabel className="row-type-1-label">
                                    Remarks <span className="required-field-asterisk"> *</span>
                                </InputLabel>

                                <FormControl className="form-control-for-field">
                                    <TextField
                                        name="Remarks"
                                        // onChange={(e) => handleTaskForm(e)}
                                        placeholder="Remarks"
                                        value={data.remarks}
                                        error={Boolean(formErrors?.Remarks)}
                                        className="input-value"
                                        size="small"
                                        multiline
                                    />
                                    <p className="form-error">{formErrors?.Remarks || ""}</p>
                                </FormControl>
                            </div>

                            <div className="row-type-1">
                                <InputLabel className="row-type-1-label">Evidence</InputLabel>
                                <FormControl className="form-control-for-field">
                                    <DropzoneArea
                                        classes={{
                                            root: "drop-container",
                                            text: "drop-text",
                                        }}
                                        filesLimit={1}
                                        acceptedFiles={allowedAuditFileTypes}
                                        maxFileSize={auditFileMaxSize}
                                        // onDrop={onCircularFileDrop}
                                        Icon={CloudUploadOutlined}
                                        showPreviewsInDropzone={false}
                                        showAlerts={false}
                                        previewGridProps={{
                                            container: { spacing: 1, direction: "row", minHeight: "165px" },
                                        }}
                                        previewText="Selected files"
                                        dropzoneText="Drag and drop PDF file or excel sheet here, or click to browse"
                                        dropzoneClass="custom-drop-zone-container"
                                        dropzoneParagraphClass="custom-drop-zone-container-text"
                                        // onDropRejected={handleDropRejected}
                                    />

                                    <Typography variant="caption" mt={1}>
                                        {data?.original_file_name && (
                                            <p>
                                                {data?.original_file_name} - {data?.size}
                                            </p>
                                        )}
                                        PDF file or excel sheet upto {auditFileMaxSizeText} is allowed
                                    </Typography>
                                    <p className="form-error">{formErrors?.file_name || ""}</p>
                                </FormControl>
                            </div>

                            <div className="row-type-1">
                                <InputLabel className="row-type-1-label">Certificate</InputLabel>
                                <FormControl className="form-control-for-field">
                                    <DropzoneArea
                                        classes={{
                                            root: "drop-container",
                                            text: "drop-text",
                                        }}
                                        filesLimit={1}
                                        acceptedFiles={allowedAuditFileTypes}
                                        maxFileSize={auditFileMaxSize}
                                        // onDrop={onCircularFileDrop}
                                        Icon={CloudUploadOutlined}
                                        showPreviewsInDropzone={false}
                                        showAlerts={false}
                                        previewGridProps={{
                                            container: { spacing: 1, direction: "row", minHeight: "165px" },
                                        }}
                                        previewText="Selected files"
                                        dropzoneText="Drag and drop PDF file or image here, or click to browse"
                                        dropzoneClass="custom-drop-zone-container"
                                        dropzoneParagraphClass="custom-drop-zone-container-text"
                                        // onDropRejected={handleDropRejected}
                                    />

                                    <Typography variant="caption" mt={1}>
                                        {data?.original_file_name && (
                                            <p>
                                                {data?.original_file_name} - {data?.size}
                                            </p>
                                        )}
                                        PDF file or image upto {auditFileMaxSizeText} is allowed
                                    </Typography>
                                    <p className="form-error">{formErrors?.file_name || ""}</p>
                                </FormControl>
                            </div>

                            <div className="row-type-1">
                                <InputLabel className="row-type-1-label"></InputLabel>
                                <div className="form-control-for-field mandatory-field-row">
                                    <span className="required-field-asterisk">* </span>
                                    <span className="mandatory-field-text">Mandatory field</span>
                                </div>
                            </div>

                            <div className="bottom-container">
                                <div className="content-separator"></div>
                                <div className="action-buttons">
                                    <button className="save-button">Save</button>

                                    <button className="cancel-button" onClick={handleFormClose}>
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </Drawer>
    );
};

export default React.memo(ComplyAreaForm);

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    moduleEdit: false,
    moduleId: null,
    moduleEditOpenToggle: false, 
    moduleAddOpenToggle: false, 
    moduleAdded: false,
    moduleEdited: false,
}

export const formSlice = createSlice({
    name: "moduleForm",
    initialState,
    reducers: {
        setModuleEdit: (state, action) => {
            state.moduleEdit = action.payload;
        },

        setModuleAdded: (state, action) => {
            state.moduleAdded = action.payload;
        },

        setModuleEdited: (state, action) => {
            state.moduleEdited = action.payload;
        },

        setModuleId: (state, action) => {
            state.moduleId = action.payload;
        },

        setModuleEditOpenToggle: (state, action) => {
            state.moduleEditOpenToggle = !state.moduleEditOpenToggle;
        },

        setModuleAddOpenToggle: (state, action) => {
            state.moduleAddOpenToggle = !state.moduleAddOpenToggle;
        }
    }
});

export const {setModuleEdit, setModuleAdded, setModuleEdited, setModuleId, setModuleEditOpenToggle, setModuleAddOpenToggle} = formSlice.actions;

export default formSlice.reducer;
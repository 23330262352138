const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    parentFolderId: "",
};

export const formSlice = createSlice({
    name: "sharedWithMe",
    initialState,
    reducers: {
        setParentFolderId: (state, action) => {
            state.parentFolderId = action.payload;
        },
    },
});

export const { setParentFolderId } = formSlice.actions;

export default formSlice.reducer;

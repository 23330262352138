import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { formAutoCompleteLabelBoxStyles } from "../../constants/Common";

import { Autocomplete, Box, CircularProgress, Drawer, FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { masterFrequencies } from "../../enums/CM/Master";
import { setAuditId } from "../../features/CM/auditFormSlice";

const AuditForm = ({ open, onClose }) => {
    const dispatch = useDispatch();
    const auditId = useSelector((state) => state.auditForm.auditId);

    const initialData = {
        audit_type: null,
        audit_title: null,
        department: null,
        frequency: "",
        audit_scope: "",
        audit_period_from: null,
        audit_period_to: null,
        auditor_firm: null,
        auditor_location: null,
        audit_start_date: null,
        audit_end_date: null,
        description: "",
    };

    // used for not calling the apis of autocomplete option when initialising the data in form while edit
    const initialInitialize = {
        initialize_audit_type: true,
        initialize_audit_title: true,
        initialize_department: true,
        initialize_auditor_firm: true,
        initialize_auditor_location: true,
    };

    const initialAutocompleteFieldsStatus = {
        audit_type: false,
        audit_title: false,
        department: false,
        auditor_firm: false,
        auditor_location: false,
        ...(auditId && { ...initialInitialize }),
    };

    const fieldNameMappings = {
        audit_type: "audit_type",
        audit_title: "audit_title",
        department: "department",
        auditor_firm: "auditor_firm",
        auditor_location: "auditor_location",
    };

    const [data, setData] = useState(initialData);
    const [formErrors, setFormErrors] = useState({});

    const [fetchingData, setFetchingData] = useState(false);
    const [autocompleteFieldsStatus, setAutocompleteFieldsStatus] = useState(initialAutocompleteFieldsStatus);
    const [autocompleteloading, setAutocompleteLoading] = useState(false);
    const [auditTypeOptions, setAuditTypeOptions] = useState([]);
    const [auditTitleOptions, setAuditTitleOptions] = useState([]);
    const [departmentOptions, setDepartmentOptions] = useState([]);
    const [auditorFirmOptions, setAuditorFirmOptions] = useState([]);
    const [auditorLocationOptions, setAuditorLocationOptions] = useState([]);

    const handleForm = (e) => {
        setFormErrors({ ...formErrors, [e.target.name]: "" });
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handleDates = (date, fieldName) => {
        setFormErrors({ ...formErrors, [fieldName]: "" });
        setData({ ...data, [fieldName]: date });
    };

    const handleFormClose = () => {
        onClose();
        setFormErrors({});
        setData(initialData);
        dispatch(setAuditId(null));
    };

    // close autocomplete
    const closeAutocomplete = (fieldName) => {
        setAutocompleteFieldsStatus({ ...autocompleteFieldsStatus, [fieldName]: false });
    };

    const handleFieldOpen = () => {};

    const handleInputChange = () => {};
    const handleSelectedOptionsChange = () => {};

    return (
        <div>
            <Drawer
                sx={{
                    "& .MuiDrawer-paper": {
                        width: {
                            xs: "90%",
                            lg: 1000,
                        },
                    },
                }}
                anchor={"right"}
                open={open}
                onClose={handleFormClose}
            >
                <div className="add-form-base">
                    <div className="add-form-header">
                        <h5 className="add-form-title">
                            {auditId ? "Edit " : "New "}
                            Audit
                        </h5>
                        <button type="button" className="add-form-close" onClick={handleFormClose}></button>
                    </div>
                    <div className="content-separator"></div>
                    {fetchingData ? (
                        <LoadingSpinner />
                    ) : (
                        <div className="add-form-scroll-container">
                            <div className="add-form-content">
                                <div className="row-type-2">
                                    <div className="group-1" style={{ alignItems: "normal" }}>
                                        <Box className="row-type-2-label" sx={formAutoCompleteLabelBoxStyles}>
                                            <InputLabel>
                                                Audit Type <span className="required-field-asterisk"> *</span>
                                            </InputLabel>
                                        </Box>
                                        <FormControl className="form-control-for-field">
                                            <Autocomplete
                                                size="small"
                                                value={data.audit_type}
                                                open={autocompleteFieldsStatus?.audit_type}
                                                onOpen={handleFieldOpen}
                                                onClose={() => {
                                                    closeAutocomplete(fieldNameMappings?.audit_type);
                                                }}
                                                onChange={(e, option) => {
                                                    handleSelectedOptionsChange(e, option, fieldNameMappings?.audit_type);
                                                }}
                                                onInputChange={handleInputChange}
                                                loading={autocompleteloading}
                                                options={auditTypeOptions}
                                                isOptionEqualToValue={(option) => option.label === data?.audit_type?.label}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        placeholder="Select Audit Type"
                                                        size="small"
                                                        className="input-value"
                                                        error={Boolean(formErrors?.audit_type)}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {autocompleteloading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                            <p className="form-error">{formErrors?.audit_type || ""}</p>
                                        </FormControl>
                                    </div>
                                    <div className="group-2" style={{ alignItems: "normal" }}>
                                        <Box className="row-type-2-label" sx={formAutoCompleteLabelBoxStyles}>
                                            <InputLabel>
                                                Audit Title<span className="required-field-asterisk"> *</span>
                                            </InputLabel>
                                        </Box>
                                        <FormControl className="form-control-for-field">
                                            <Autocomplete
                                                size="small"
                                                value={data.audit_title}
                                                open={autocompleteFieldsStatus?.audit_title}
                                                onOpen={handleFieldOpen}
                                                onClose={() => {
                                                    closeAutocomplete(fieldNameMappings?.audit_title);
                                                }}
                                                onChange={(e, option) => {
                                                    handleSelectedOptionsChange(e, option, fieldNameMappings?.audit_title);
                                                }}
                                                onInputChange={handleInputChange}
                                                loading={autocompleteloading}
                                                options={auditTitleOptions}
                                                isOptionEqualToValue={(option) => option.label === data?.audit_title?.label}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        placeholder="Select Audit Title"
                                                        size="small"
                                                        className="input-value"
                                                        error={Boolean(formErrors?.audit_title)}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {autocompleteloading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                            <p className="form-error">{formErrors?.audit_title || ""}</p>
                                        </FormControl>
                                    </div>
                                </div>

                                <div className="row-type-2">
                                    <div className="group-1" style={{ alignItems: "normal" }}>
                                        <Box className="row-type-2-label" sx={formAutoCompleteLabelBoxStyles}>
                                            <InputLabel>
                                                Department <span className="required-field-asterisk"> *</span>
                                            </InputLabel>
                                        </Box>
                                        <FormControl className="form-control-for-field">
                                            <Autocomplete
                                                size="small"
                                                value={data.department}
                                                open={autocompleteFieldsStatus?.department}
                                                onOpen={handleFieldOpen}
                                                onClose={() => {
                                                    closeAutocomplete(fieldNameMappings?.department);
                                                }}
                                                onChange={(e, option) => {
                                                    handleSelectedOptionsChange(e, option, fieldNameMappings?.department);
                                                }}
                                                onInputChange={handleInputChange}
                                                loading={autocompleteloading}
                                                options={departmentOptions}
                                                isOptionEqualToValue={(option) => option.label === data?.department?.label}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        placeholder="Select Department"
                                                        size="small"
                                                        className="input-value"
                                                        error={Boolean(formErrors?.department)}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {autocompleteloading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                            <p className="form-error">{formErrors?.department || ""}</p>
                                        </FormControl>
                                    </div>

                                    <div className="group-2">
                                        <InputLabel className="row-type-2-label">
                                            Frequency
                                            <span className="required-field-asterisk"> *</span>
                                        </InputLabel>
                                        <FormControl className="form-control-for-field" error={Boolean(formErrors?.frequency)}>
                                            <Select
                                                name="frequency"
                                                // onChange={handleInputChange}
                                                value={data?.frequency}
                                                className={`select-value ${data?.frequency === "" ? "not-selected" : ""}`}
                                                size="small"
                                                displayEmpty
                                                inputProps={{ "aria-label": "Without label" }}
                                                MenuProps={{
                                                    disablePortal: true,
                                                }}
                                            >
                                                <MenuItem className="select-menu-item" value="">
                                                    Select Frequency
                                                </MenuItem>
                                                {Object.keys(masterFrequencies).map((key) => (
                                                    <MenuItem className="select-menu-item" key={key} value={masterFrequencies[key]}>
                                                        {masterFrequencies[key]}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                            <p className="form-error">{formErrors?.frequency || ""}</p>
                                        </FormControl>
                                    </div>
                                </div>

                                <div className="row-type-1">
                                    <InputLabel className="row-type-1-label">Audit Scope</InputLabel>
                                    <FormControl className="form-control-for-field">
                                        <TextField
                                            name="audit_scope"
                                            onChange={handleForm}
                                            placeholder="Audit Scope"
                                            value={data?.audit_scope}
                                            error={Boolean(formErrors?.audit_scope)}
                                            className="input-value"
                                            size="small"
                                            multiline
                                        />
                                        <p className="form-error">{formErrors?.audit_scope || ""}</p>
                                    </FormControl>
                                </div>

                                <div className="row-type-2">
                                    <div className="group-1">
                                        <InputLabel className="row-type-2-label">
                                            Audit Period (From)
                                            <span className="required-field-asterisk"> *</span>
                                        </InputLabel>
                                        <FormControl className="form-control-for-field">
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    name="audit_period_from"
                                                    format="DD/MM/YYYY"
                                                    disableFuture={true}
                                                    value={data?.audit_period_from || null}
                                                    onChange={(date) => handleDates(date, "audit_period_from")}
                                                    className={`input-value ${data?.audit_period_from === null ? "not-selected" : ""}`}
                                                    size="small"
                                                    emptyLabel="DD/MM/YYYY"
                                                    slotProps={{ textField: { error: Boolean(formErrors?.audit_period_from) } }}
                                                />
                                            </LocalizationProvider>
                                            <p className="form-error">{formErrors?.audit_period_from || ""}</p>
                                        </FormControl>
                                    </div>
                                    <div className="group-2">
                                        <InputLabel className="row-type-2-label">
                                            Audit Period (To) <span className="required-field-asterisk"> *</span>
                                        </InputLabel>
                                        <FormControl className="form-control-for-field">
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    name="audit_period_to"
                                                    format="DD/MM/YYYY"
                                                    value={data?.audit_period_to || null}
                                                    onChange={(date) => handleDates(date, "audit_period_to")}
                                                    className={`input-value ${data?.audit_period_to === null ? "not-selected" : ""}`}
                                                    size="small"
                                                    emptyLabel="DD/MM/YYYY"
                                                    slotProps={{ textField: { error: Boolean(formErrors?.audit_period_to) } }}
                                                />
                                            </LocalizationProvider>
                                            <p className="form-error">{formErrors?.audit_period_to || ""}</p>
                                        </FormControl>
                                    </div>
                                </div>

                                <div className="row-type-2">
                                    <div className="group-1" style={{ alignItems: "normal" }}>
                                        <Box className="row-type-2-label" sx={formAutoCompleteLabelBoxStyles}>
                                            <InputLabel>
                                                Auditor Firm <span className="required-field-asterisk"> *</span>
                                            </InputLabel>
                                        </Box>

                                        <FormControl className="form-control-for-field">
                                            <Autocomplete
                                                size="small"
                                                value={data.auditor_firm}
                                                open={autocompleteFieldsStatus?.auditor_firm}
                                                onOpen={handleFieldOpen}
                                                onClose={() => {
                                                    closeAutocomplete(fieldNameMappings?.auditor_firm);
                                                }}
                                                onChange={(e, option) => {
                                                    handleSelectedOptionsChange(e, option, fieldNameMappings?.auditor_firm);
                                                }}
                                                onInputChange={handleInputChange}
                                                loading={autocompleteloading}
                                                options={auditorFirmOptions}
                                                isOptionEqualToValue={(option) => option.label === data?.auditor_firm?.label}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        placeholder="Select Auditor Firm"
                                                        size="small"
                                                        className="input-value"
                                                        error={Boolean(formErrors?.auditor_firm)}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {autocompleteloading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                            <p className="form-error">{formErrors?.auditor_firm || ""}</p>
                                        </FormControl>
                                    </div>
                                    <div className="group-2" style={{ alignItems: "normal" }}>
                                        <Box className="row-type-2-label" sx={formAutoCompleteLabelBoxStyles}>
                                            <InputLabel>
                                                Auditor Location <span className="required-field-asterisk"> *</span>
                                            </InputLabel>
                                        </Box>

                                        <FormControl className="form-control-for-field">
                                            <Autocomplete
                                                size="small"
                                                value={data.auditor_location}
                                                open={autocompleteFieldsStatus?.auditor_location}
                                                onOpen={handleFieldOpen}
                                                onClose={() => {
                                                    closeAutocomplete(fieldNameMappings?.auditor_location);
                                                }}
                                                onChange={(e, option) => {
                                                    handleSelectedOptionsChange(e, option, fieldNameMappings?.auditor_location);
                                                }}
                                                onInputChange={handleInputChange}
                                                loading={autocompleteloading}
                                                options={auditorLocationOptions}
                                                isOptionEqualToValue={(option) => option.label === data?.auditor_location?.label}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        placeholder="Select Auditor Location"
                                                        size="small"
                                                        className="input-value"
                                                        error={Boolean(formErrors?.auditor_location)}
                                                        InputProps={{
                                                            ...params.InputProps,
                                                            endAdornment: (
                                                                <React.Fragment>
                                                                    {autocompleteloading ? <CircularProgress color="inherit" size={20} /> : null}
                                                                    {params.InputProps.endAdornment}
                                                                </React.Fragment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                            <p className="form-error">{formErrors?.auditor_location || ""}</p>
                                        </FormControl>
                                    </div>
                                </div>

                                <div className="row-type-2">
                                    <div className="group-1">
                                        <InputLabel className="row-type-2-label">
                                            Audit Start Date
                                            <span className="required-field-asterisk"> *</span>
                                        </InputLabel>
                                        <FormControl className="form-control-for-field">
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    name="audit_start_date"
                                                    format="DD/MM/YYYY"
                                                    disableFuture={true}
                                                    value={data?.audit_start_date || null}
                                                    onChange={(date) => handleDates(date, "audit_start_date")}
                                                    className={`input-value ${data?.audit_start_date === null ? "not-selected" : ""}`}
                                                    size="small"
                                                    emptyLabel="DD/MM/YYYY"
                                                    slotProps={{ textField: { error: Boolean(formErrors?.audit_start_date) } }}
                                                />
                                            </LocalizationProvider>
                                            <p className="form-error">{formErrors?.audit_start_date || ""}</p>
                                        </FormControl>
                                    </div>
                                    <div className="group-2">
                                        <InputLabel className="row-type-2-label">
                                            Audit End Date <span className="required-field-asterisk"> *</span>
                                        </InputLabel>
                                        <FormControl className="form-control-for-field">
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker
                                                    name="audit_end_date"
                                                    format="DD/MM/YYYY"
                                                    value={data?.audit_end_date || null}
                                                    onChange={(date) => handleDates(date, "audit_end_date")}
                                                    className={`input-value ${data?.audit_end_date === null ? "not-selected" : ""}`}
                                                    size="small"
                                                    emptyLabel="DD/MM/YYYY"
                                                    slotProps={{ textField: { error: Boolean(formErrors?.audit_end_date) } }}
                                                />
                                            </LocalizationProvider>
                                            <p className="form-error">{formErrors?.audit_end_date || ""}</p>
                                        </FormControl>
                                    </div>
                                </div>

                                <div className="row-type-1">
                                    <InputLabel className="row-type-1-label">Description</InputLabel>
                                    <FormControl className="form-control-for-field">
                                        <TextField
                                            name="description"
                                            onChange={handleForm}
                                            placeholder="Description"
                                            value={data?.description}
                                            error={Boolean(formErrors?.description)}
                                            className="input-value"
                                            size="small"
                                            multiline
                                        />
                                        <p className="form-error">{formErrors?.description || ""}</p>
                                    </FormControl>
                                </div>

                                <div className="row-type-1">
                                    <InputLabel className="row-type-1-label"></InputLabel>
                                    <div className="form-control-for-field mandatory-field-row">
                                        <span className="required-field-asterisk">* </span>
                                        <span className="mandatory-field-text">Mandatory field</span>
                                    </div>
                                </div>

                                <div className="bottom-container">
                                    <div className="content-separator"></div>
                                    <div className="action-buttons">
                                        <button className="save-button">Save</button>

                                        <button className="cancel-button" onClick={handleFormClose}>
                                            Cancel
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </Drawer>
        </div>
    );
};

export default React.memo(AuditForm);

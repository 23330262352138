const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    ticketAdded: false,
    ticketEdited: false,
    ticketEdit: false,
    ticketId: "",
    ticketEditOpenToggle: false,
    ticketAddOpenToggle: false,
};

export const ticketSlice = createSlice({
    name: "ticket",
    initialState,
    reducers: {
        setTicketAdded: (state, action) => {
            state.ticketAdded = action.payload;
        },
        setTicketEdited: (state, action) => {
            state.ticketEdited = action.payload;
        },
        setTicketEdit: (state, action) => {
            state.ticketEdit = action.payload;
        },
        setTicketId: (state, action) => {
            state.ticketId = action.payload;
        },
        setTicketEditOpenToggle: (state, action) => {
            state.ticketEditOpenToggle = !state.ticketEditOpenToggle;
        },

        setTicketAddOpenToggle: (state, action) => {
            state.ticketAddOpenToggle = !state.ticketAddOpenToggle;
        },
    },
});

export const { setTicketAdded, setTicketEdited, setTicketEdit, setTicketId, setTicketAddOpenToggle, setTicketEditOpenToggle } =
ticketSlice.actions;

export default ticketSlice.reducer;

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    targetId: null,
    targetAddOpenToggle: false,
    targetAdded: false,
};

export const formSlice = createSlice({
    name: "targetForm",
    initialState,
    reducers: {
        setTargetAdded: (state, action) => {
            state.targetAdded = action.payload;
        },

        setTargetId: (state, action) => {
            state.targetId = action.payload;
        },

        setTargetAddOpenToggle: (state, action) => {
            state.targetAddOpenToggle = !state.targetAddOpenToggle;
        },
    },
});

export const { setTargetAdded, setTargetId, setTargetAddOpenToggle } = formSlice.actions;

export default formSlice.reducer;

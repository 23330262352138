const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    courseIdForDetails: null,
    courseDetailsOpen: false
}

export const formSlice = createSlice({
    name: "courseDetails",
    initialState,
    reducers: {
        setCourseIdForDetails: (state, action) => {
            state.courseIdForDetails = action.payload;
        },

        setCourseDetailsOpen: (state, action) => {
            state.courseDetailsOpen = action.payload;
        }
    }
});

export const { setCourseIdForDetails, setCourseDetailsOpen } = formSlice.actions;

export default formSlice.reducer;
const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    circularDeleted: false,
    circularId: null,
    circularAddOpenToggle: false,
    circularAdded: false,
    circularEdit: false,
    circularEditOpenToggle: false,
    circularEdited: false,
};

export const formSlice = createSlice({
    name: "circular",
    initialState,
    reducers: {
        setCircularDeleted: (state, action) => {
            state.circularDeleted = action.payload;
        },
        setCircularAdded: (state, action) => {
            state.circularAdded = action.payload;
        },
        setCircularId: (state, action) => {
            state.circularId = action.payload;
        },
        setCircularAddOpenToggle: (state, action) => {
            state.circularAddOpenToggle = !state.circularAddOpenToggle;
        },
        setCircularEdit: (state, action) => {
            state.circularEdit = action.payload;
        },
        setCircularEditOpenToggle: (state, action) => {
            state.circularEditOpenToggle = !state.circularEditOpenToggle;
        },
        setCircularEdited: (state, action) => {
            state.circularEdited = action.payload;
        },
    },
});

export const {
    setCircularDeleted,
    setCircularAdded,
    setCircularId,
    setCircularAddOpenToggle,
    setCircularEdit,
    setCircularEditOpenToggle,
    setCircularEdited,
} = formSlice.actions;

export default formSlice.reducer;

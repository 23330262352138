import { validationMessages } from "../../constants/validationMessages/ValidationMessages";
import { inventoryTypes } from "../../enums/AM/Inventory";
const Joi = require("joi");

const inventorySchema = Joi.object().keys({
    type: Joi.valid(...Object.values(inventoryTypes))
        .required()
        .messages({
            "any.only": validationMessages.requiredDefault,
        }),

    category: Joi.string().required().max(255).messages({
        "string.max": validationMessages.maxDefault,
        "string.empty": validationMessages.requiredDefault,
        "any.required": validationMessages.requiredDefault,
        "string.base": validationMessages.requiredDefault,
    }),

    group_code: Joi.string()
        .max(255)
        .when("type", {
            is: inventoryTypes.Stationary,
            then: Joi.string().required(),
            otherwise: Joi.string().allow(null).allow(""),
        })
        .messages({
            "string.max": validationMessages.maxDefault,
            "string.empty": validationMessages.requiredDefault,
            "any.required": validationMessages.requiredDefault,
            "string.base": validationMessages.requiredDefault,
        }),

    item_code: Joi.string()
        .max(255)
        .when("type", {
            is: inventoryTypes.Stationary,
            then: Joi.string().required(),
            otherwise: Joi.string().allow(null).allow(""),
        })
        .messages({
            "string.max": validationMessages.maxDefault,
            "string.empty": validationMessages.requiredDefault,
            "any.required": validationMessages.requiredDefault,
            "string.base": validationMessages.requiredDefault,
        }),

    name: Joi.string().max(255).required().messages({
        "string.max": validationMessages.maxDefault,
        "string.empty": validationMessages.requiredDefault,
        "any.required": validationMessages.requiredDefault,
        "string.base": validationMessages.requiredDefault,
    }),
});

export { inventorySchema };

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    inventoryFormOpen: false,
    inventoryId: null,
    inventoryAdded: false,
    inventoryEdited: false,
};

export const formSlice = createSlice({
    name: "inventoryForm",
    initialState,
    reducers: {
        setInventoryFormOpen: (state, action) => {
            state.inventoryFormOpen = action.payload;
        },

        setInventoryAdded: (state, action) => {
            state.inventoryAdded = action.payload;
        },

        setInventoryEdited: (state, action) => {
            state.inventoryEdited = action.payload;
        },

        setInventoryId: (state, action) => {
            state.inventoryId = action.payload;
        },
    },
});

export const { setInventoryFormOpen, setInventoryAdded, setInventoryEdited, setInventoryId } = formSlice.actions;

export default formSlice.reducer;

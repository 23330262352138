const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    taskEdit: false,
    taskAddAccountSpecific: false,
    taskAddedForAccountId: null,
    taskId: null,
    taskAccountId: null,
    taskEditOpenToggle: false,
    taskAddOpenToggle: false,
    taskAccountName: "",
    taskAdded: false,
    taskEdited: false,
};

export const formSlice = createSlice({
    name: "taskForm",
    initialState,
    reducers: {
        setTaskEdit: (state, action) => {
            state.taskEdit = action.payload;
        },

        setTaskAddAccountSpecific: (state, action) => {
            state.taskAddAccountSpecific = action.payload;
        },

        setTaskAddedForAccountId: (state, action) => {
            state.taskAddedForAccountId = action.payload;
        },

        setTaskAccountId: (state, action) => {
            state.taskAccountId = action.payload;
        },

        setTaskAccountName: (state, action) => {
            state.taskAccountName = action.payload;
        },

        setTaskAdded: (state, action) => {
            state.taskAdded = action.payload;
        },

        setTaskEdited: (state, action) => {
            state.taskEdited = action.payload;
        },

        setTaskId: (state, action) => {
            state.taskId = action.payload;
        },

        setTaskEditOpenToggle: (state, action) => {
            state.taskEditOpenToggle = !state.taskEditOpenToggle;
        },

        setTaskAddOpenToggle: (state, action) => {
            state.taskAddOpenToggle = !state.taskAddOpenToggle;
        },
    },
});

export const {
    setTaskEdit,
    setTaskAddAccountSpecific,
    setTaskAddedForAccountId,
    setTaskAccountId,
    setTaskAccountName,
    setTaskAdded,
    setTaskEdited,
    setTaskId,
    setTaskEditOpenToggle,
    setTaskAddOpenToggle,
} = formSlice.actions;

export default formSlice.reducer;

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    user_id: null,
    first_name: "",
    last_name: "",
    mobile: "",
    email: "",
    pf_number: "",
    dob: null,
    retirement_date: null,
    location_id: null,
    account_id: null,
    role_id: null,
    first_time_logged_in: false,
    account_name: "",
    account_type: "",
    account_gstin: "",
    account_mobile: "",
    account_location: "",
    location_name: "",
    location_code: "",
};

export const formSlice = createSlice({
    name: "userDetails",
    initialState,
    reducers: {
        setUserDetails: (state, action) => {
            return { ...state, ...action.payload };
        },
        setUserDetailsField: (state, action) => {
            const { fieldName, value } = action.payload;
            state[fieldName] = value;
        },
    },
});

export const { setUserDetails, setUserDetailsField } = formSlice.actions;

export default formSlice.reducer;
